<template>
  <StyleDialog
    v-model="openModal"
    :max-width="type.includes('udio') ? 478 : 1100"
    class="my-dialog"
    title=""
    :loading="isLoading"
  >
    <template slot="content">
      <v-row style="position: relative" v-if="!isLoading" class="center-all">
        <v-col
          :cols="type.includes('udio') ? 12 : 3"
          v-for="(item, index) in items"
          :key="item.url"
          :class="type.includes('udio') ? 'center-all-col' : 'center-all'"
        >
          <div v-if="format(item).includes('udio')">
            {{ getName(item) }}
          </div>
          <v-hover v-slot="{ hover }">
            <div
              v-if="format(item).includes('udio')"
              @click="setCurrentItem(index)"
            >
              <v-btn
                v-if="hover || currentItem === index"
                :color="currentItem === index ? 'yellow' : 'white'"
                fab
                x-small
                @click="setCurrentItem(index)"
                class="my-btn-audio"
              >
                <MyIconImage name="approved" />
              </v-btn>
              <play-audio-cloudinary
                @load="setLoading"
                :publicId="item.url"
                v-show="!isLoading"
                maxWidth="500px"
              />
            </div>
            <div v-else-if="format(item).includes('mage')">
              <v-img
                :src="item.url"
                width="200px"
                height="200px"
                aspect-ratio="1"
                class="mouse-pointer"
                @click="setCurrentItem(index)"
              >
                <!-- @load="setLoading" -->
                <v-btn
                  v-if="hover || currentItem === index"
                  :color="currentItem === index ? 'yellow' : 'white'"
                  fab
                  x-small
                  class="my-btn-image"
                  @click="setCurrentItem(index)"
                >
                  <MyIconImage name="approved" :maxWidth="15" />
                </v-btn>
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <MyIconImage maxWidth="100" name="logo-anim" type="gif" />
                  </v-row>
                </template>
              </v-img>
            </div>
            <div
              v-else-if="format(item).includes('ideo')"
              style="position: relative"
            >
              <ViewVideo :src="item.url" width="200px" height="200px" />
              <v-btn
                v-if="hover || currentItem === index"
                :color="currentItem === index ? 'yellow' : 'white'"
                fab
                @click="setCurrentItem(index)"
                x-small
                class="my-btn-video"
              >
                <MyIconImage name="approved" :maxWidth="15" />
              </v-btn>
            </div>
            <div v-else>
              {{ $t('errors.format') }}
            </div>
          </v-hover>
        </v-col>
      </v-row>
    </template>
    <template slot="actions">
      <BtnStyle
        @click="openMediaDialog = true"
        :btnText="
          type.includes('udio')
            ? 'generics.uploadSound'
            : 'generics.uploadBackground'
        "
        :borderColor="'pink'"
        :disabled="openMediaDialog"
      />
      <UploadFileCloud
        v-if="openMediaDialog"
        v-model="openMediaDialog"
        @save="v => saveMedia(v, 'openMedia')"
        :onlySound="type.includes('udio')"
        :onlyImage="type.includes('mage')"
        :onlyVideo="type.includes('ideo')"
      />
    </template>
  </StyleDialog>
</template>
<script>
import BtnStyle from './buttons/BtnStyle.vue'
import MyIconImage from './MyIconImage.vue'
import PlayAudioCloudinary from './PlayAudioCloudinary.vue'
import StyleDialog from './StyleDialog.vue'
import UploadFileCloud from './UploadFileCloud.vue'
import ViewVideo from './ViewVideo.vue'

export default {
  name: 'AssetsRepositories',
  props: {
    value: { type: Boolean, default: false },
    type: { type: String, default: 'images' },
    currentItem: { type: Number, default: 0 },
  },
  components: {
    PlayAudioCloudinary,
    MyIconImage,
    StyleDialog,
    ViewVideo,
    BtnStyle,
    UploadFileCloud,
  },
  data() {
    return {
      isLoading: false,
      openMediaDialog: false,
    }
  },
  methods: {
    getName(item) {
      return item.name
        ? item.name
        : item.url?.split('assets/')[1]?.split('.')[0].toString() ?? ''
    },
    saveMedia(value) {
      this.$store.dispatch('user/update-assets', value)
    },
    setLoading(v = false) {
      this.isLoading = v
    },
    setCurrentItem(index) {
      this.$emit('setCurrentItem', index)
    },
    format(item) {
      let format = 'image'
      if (item.format.toLowerCase() === 'mp4') {
        format = 'video'
      }
      if (item.format.toLowerCase() === 'mp3') {
        format = 'audio'
      }
      return format
    },
    // saveInRepository(v) {
    //   const type = this.format(v)
    //   this.$store.commit(type + 's/store', v)
    // },
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    items() {
      return this.$store.getters[this.type] || []
    },
  },
}
</script>
<style lang="scss" scoped>
:is(.my-btn-audio, .my-btn-image) {
  position: absolute;
  z-index: 10;
}
.my-btn-audio {
  margin-top: 10px;
  right: 14%;
}
.my-btn-image {
  margin-top: 5px;
  right: 5px;
}
.my-btn-video {
  position: absolute;
  margin-top: 5px;
  right: 5px;
}
</style>
