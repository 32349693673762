<template>
  <v-container
    fill-height
    grid-list-md
    bg
    text-xs-center
    class="d-flex align-center flex-column center-all"
  >
    <span style="position: absolute; top: 8vh; right: 3vw">
      <div class="d-flex justify-center align-center">
        <v-img style="max-width: 150px" :src="`/assets/images/logo.svg`" />
      </div>
    </span>
    <div class="d-flex align-center flex-column center-all">
      <v-row>
        <v-col md="6" sm="6">
          <v-btn
            class="my-game-btn my-btn"
            outlined
            rounded
            :to="{ name: 'MyGame' }"
            min-width="400"
            min-height="100"
          >
            <MyIconImage type="svg" name="game" maxWidth="150" />
            <span class="me-2 font-weight-3">
              {{ $t('route.MyGame') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-btn
            class="new-game-btn my-btn"
            @click="goGamePage"
            min-width="400"
            min-height="100"
          >
            <span style="position: relative">
              <MyIconImage type="png" name="Ellipse" maxWidth="150" />
              <MyIconImage
                type="svg"
                style="position: absolute; top: 18px; left: 19px"
                name="red_plus"
                maxWidth="150"
              />
            </span>
            <span class="me-2 font-weight-3">
              {{ $t('ליצירת משחק חדש') }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <user-handler
      v-if="userDetailsDialog"
      v-model="userDetailsDialog"
      :user="user"
    />
  </v-container>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import UserHandler from '@/components/handler/UserHandler.vue'
import MyIconImage from '@/components/MyIconImage.vue'

export default {
  components: { UserHandler, MyIconImage },
  name: 'UserDashboard',
  mixins: [SwalMixins],

  methods: {
    async goGamePage() {
      try {
        const { isConfirmed, value } = await this.createInputSwaldata({
          title: this.$t('games.name'),
        })
        if (!isConfirmed) return

        this.$store.commit('loading/active', true)
        await this.$store.dispatch('game/store', { name: value })
        await this.$router.push({
          name: 'NewStyle',
          params: { isNew: true, id: this.$store.getters.game._id },
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.userDetails
    },
    userDetailsDialog: {
      get() {
        return this.$store.getters.userDetailsDialog
      },
      set(v) {
        return this.$store.commit('auth/userDetailsDialog', v)
      },
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border-radius: 50px;
  justify-content: space-evenly !important;
  font-size: larger;
}
.my-game-btn {
  border: 3px solid var(--gray);
  background-color: var(--white) !important;
}
.new-game-btn {
  background: var(--blueLogo) !important;
  color: white;
}
</style>
