const ans_imageQuestion = {
  type: 'ans_images',
  setting: {
    type: 'ans_images',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    showInLoop: false,
    exitGame: false,
    correctlyAnsweredBefore: false,
    firstClicker: false,
    answerIsSequenceClicks: false,
    fullscreen: false,
    scoringReduction: {
      active: false,
      seconds: '',
      score: '',
    },
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
  question: {
    que: '',
    scoreForQue: 3,
    timeForQue: 15,
    answers: [
      {
        ansMedia: {
          src: '',
          format: '',
        },
        ans: '',
        correct: true,
      },
      {
        ansMedia: {
          src: '',
          format: '',
        },
        ans: '',
        correct: false,
      },
    ],
  },
  openMedia: {
    src: '',
    format: '',
  },
  endMedia: {
    src: '',
    format: '',
  },
  backgroundMedia: {
    src: '',
    format: '',
  },
}
export default ans_imageQuestion
