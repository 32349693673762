<template>
  <v-hover v-slot="{ hover }">
    <v-col
      lg="4"
      cols="6"
      :class="answerIndex % 2 === 0 ? 'me-' : 'ms-'"
      style="position: relative; display: grid; justify-items: center"
      class="justify-center"
    >
      <ApprovedAnsBtn
        :hover="hover"
        :correct="form.correct"
        :moreStyle="moreStyle"
        :answers="answers"
        :canChooseCorrectAns="canChooseCorrectAns"
        @click="setCorrectAns(!form.correct)"
        class="approved-ans-btn"
      />
      <v-card class="my-card center-all-col" :elevation="hover ? 16 : 2">
        <v-card :elevation="0" class="my-image-card">
          <v-img height="17vh" width="9vw" :src="src" />
        </v-card>
        <v-btn
          fab
          x-small
          :elevation="form.ansMedia.src ? 0 : 1"
          v-if="answersLength > 2"
          class="trash-btn d-flex justify-center align-center mouse-pointer"
          @click="removeAns"
        >
          <v-img style="max-width: 13px" :src="`/assets/icons/trash.png`" />
        </v-btn>
      </v-card>
      <div>
        <validation-provider rules="required">
          <input v-show="false" type="text" v-model="form.ansMedia.src" />
          <BtnStyle
            class="mt-5"
            :borderColor="form.ansMedia.src ? 'yellow' : 'pink'"
            :btnText="
              form.ansMedia.src
                ? 'generics.mediaUploadAgain'
                : 'generics.mediaUpload'
            "
            @click="openMediaDialog = true"
            :disabled="openMediaDialog"
          />
          <UploadFileCloud
            v-if="openMediaDialog"
            v-model="openMediaDialog"
            @save="v => saveMedia(v, 'ansMedia')"
            onlyImage
          />
        </validation-provider>
      </div>
    </v-col>
  </v-hover>
</template>

<script>
import _ from 'lodash'
import ApprovedAnsBtn from '@/components/answers/ApprovedAnsBtn.vue'
import UploadFileCloud from '../UploadFileCloud.vue'
import BtnStyle from '../buttons/BtnStyle.vue'

export default {
  name: 'AnswerMedia',
  components: {
    ApprovedAnsBtn,
    UploadFileCloud,
    BtnStyle,
  },
  props: {
    answerIndex: Number,
    answersLength: Number,
    canChooseCorrectAns: { type: Boolean, default: true },
    answer: { type: Object, default: () => ({}) },
    answers: { type: Array, default: () => [] },
  },
  data() {
    return {
      openMediaDialog: false,
      moreStyle: {
        top: '7px',
        'z-index': 2,
      },
    }
  },
  computed: {
    form: {
      get() {
        return this.answer
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    src() {
      return `https://res.cloudinary.com/${this.cloudName}/${this.format(
        this.form.ansMedia.format
      )}/upload/${this.form.ansMedia.src}`
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
  },
  methods: {
    format(format) {
      return format.includes('mp3') || format.includes('mp4')
        ? 'video'
        : 'image'
    },
    saveMedia(value, name) {
      this.form[name] = { ...this.form[name], ...value }
      this.form.ans = value.src
    },
    // saveMedia(value, name) {
    //   this.form.ans = value?.ans
    //   this.form[name] = { ...this.form[name], ...value }
    // },
    removeAns() {
      this.$emit('save', { public_id: '', format: '' })
      this.$emit('remove', this.answerIndex)
    },
    setCorrectAns(isCorrect) {
      this.form.correct = isCorrect
    },
  },
  mounted() {
    this.form = { ...this.form, ..._.cloneDeep(this.answer) }
  },
}
</script>

<style scoped>
.zoom-in {
  cursor: zoom-in;
}
::v-deep .v-sheet.v-card.my-card {
  border-radius: 30% !important;
  position: relative;
  height: 18vh;
  width: 10vw;
}
.my-image-card {
  border: 2px solid white;
  border-radius: 30% !important;
}
.trash-btn {
  position: absolute;
  top: 15px;
  left: 15px;
  background-color: white !important;
}
.upload-btn {
  margin-top: 10px;
}
.approved-ans-btn {
  background: red;
}
</style>
