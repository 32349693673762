<template>
  <div class="d-flex align-center">
    <CustomCheckbox
      v-model="form"
      @click="form = true"
      :width="11"
      :height="11"
      backgroundColor="var(--green)"
    />
    <span
      class="px-2 my-font-size font-weight-1 mouse-pointer"
      @click="form = true"
      v-text="$t(text)"
    />
    <slot />
  </div>
</template>
<script>
import CustomCheckbox from './CustomCheckbox.vue'

export default {
  name: 'MyRadio',
  components: { CustomCheckbox },
  props: {
    value: Boolean,
    text: String,
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .my-font-size {
  font-size: 0.7rem;
}
</style>
