<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="font-weight-3">
        <MyCheckbox
          v-model="form['allowChangeVote']"
          text="questions.setting.allowChangeVote"
        />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-1">
        <MyRadio
          v-model="form['slideStartVoting']"
          text="questions.setting.slideStartVoting"
        />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-1">
        <MyRadio
          v-model="form['playAfterClicking']"
          text="questions.setting.playAfterClicking"
        />
      </v-col>
      <v-col cols="12">
        <v-divider />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox
          v-model="form['correctlyAnsweredBefore']"
          text="questions.setting.correctlyAnsweredBefore"
        />
      </v-col>
      <v-col cols="12" class="py-0">
        <MyCheckbox
          v-model="form.scoringReduction.active"
          text="questions.setting.scoringReduction"
          class="font-weight-3 d-flex align-center"
        >
          <span class="my-font-size mx-1 font-weight-1">{{
            $t('generics.after')
          }}</span>
          <validation-provider
            v-if="form.scoringReduction.active"
            rules="required"
          >
            <v-text-field
              v-model="form.scoringReduction.seconds"
              type="number"
              dense
              required
              autofocus
              class="my-field"
              :rules="[v => !!v || $t('')]"
            />
          </validation-provider>
          <v-text-field
            v-else
            class="my-field"
            disabled
            dense
            hide-spin-buttons
          />
          <span class="my-font-size mx-1 font-weight-1">{{
            $tc('generics.reduced', 2)
          }}</span>
          <validation-provider
            v-if="form.scoringReduction.active"
            rules="required"
          >
            <v-text-field
              v-model="form.scoringReduction.score"
              type="number"
              dense
              required
              class="my-field"
              :rules="[v => !!v || $t('')]"
            />
          </validation-provider>
          <v-text-field
            v-else
            class="my-field"
            disabled
            dense
            hide-spin-buttons
          />
          <span class="my-font-size mx-1 font-weight-1">
            {{ $tc('generics.score', 2) }}</span
          >
        </MyCheckbox>
      </v-col>
      <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox
          v-model="form['exitGame']"
          text="questions.setting.exitGame"
        />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox
          v-model="form['firstClicker']"
          text="questions.setting.firstClicker"
        />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox
          v-model="form['answerIsSequenceClicks']"
          text="questions.setting.answerIsSequenceClicks"
        />
      </v-col>
      <!-- <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox v-model="form['test']" text="questions.setting.test">
          <span class="my-font-size font-weight-1">
            (סמנו רק לאחר קריאת ההוראות)
          </span>
        </MyCheckbox>
      </v-col> -->
      <!-- <v-col cols="12">
        <MyCheckbox
          v-model="form['answerIsSequenceClicks']"
          text="questions.setting.answerIsSequenceClicks"
        />
      </v-col> -->
      <v-col cols="12"><v-divider /></v-col>
      <v-col class="py-0" cols="12">
        <MyRadio
          v-model="form['skipAfterClick']"
          text="questions.setting.skipAfterClick"
        />
      </v-col>
      <v-col class="py-0" cols="12">
        <MyRadio
          v-model="form.automaticSkip.active"
          text="questions.setting.automaticSkip"
        >
          <validation-provider
            v-if="form.automaticSkip.active"
            rules="required"
          >
            <v-text-field
              v-model="form.automaticSkip.seconds"
              type="number"
              dense
              hide-spin-buttons
              autofocus
              :rules="[v => !!v || $t('errors.notEmpty')]"
            />
          </validation-provider>
          <v-text-field v-else disabled dense hide-spin-buttons />
        </MyRadio>
      </v-col>
      <v-col cols="12"><v-divider /></v-col>
      <v-col cols="12">
        <MyCarousel
          v-model="form.slidBackgroundMedia"
          title="setting.slidBackgroundMedia"
          defaultFor="slidBackgroundMedia"
          type="videosAndImages"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MyCheckbox from '@/components/MyCheckbox.vue'
import MyRadio from '@/components/MyRadio.vue'
import { ans_imageStructure } from '@/data/data structure'
import MyCarousel from '../MyCarousel.vue'
export default {
  name: 'AnsImageSetting',
  components: {
    MyCheckbox,
    MyRadio,
    MyCarousel,
  },
  props: {
    value: Object,
    invalid: Boolean,
  },
  data() {
    return {
      oldSeconds: '',
      scoringReduction: {
        oldSeconds: '',
        oldScore: '',
      },

      // showInLoop: false,
      // fullscreen: false,
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  mounted() {
    this.form = {
      ...ans_imageStructure.setting,
      ...this.form,
      skipAfterClick: !this.form?.automaticSkip?.active ? true : false,
    }
  },
  watch: {
    'form.scoringReduction'() {
      if (!this.form.scoringReduction.active) {
        this.scoringReduction.oldSeconds = this.form.scoringReduction.seconds
        this.scoringReduction.oldScore = this.form.scoringReduction.seconds
      }
    },
    'form.skipAfterClick'() {
      if (this.form.skipAfterClick) {
        this.form.automaticSkip.active = false
        this.oldSeconds = this.form.automaticSkip.seconds
        this.form.automaticSkip.seconds = ''
      }
    },
    'form.automaticSkip.active'() {
      if (this.form.automaticSkip.active) {
        this.form.skipAfterClick = false
        this.form.automaticSkip.seconds = this.oldSeconds
      }
    },
    'form.slideStartVoting'() {
      if (this.form.slideStartVoting) {
        this.form.playAfterClicking = false
      }
    },
    'form.playAfterClicking'() {
      if (this.form.playAfterClicking) {
        this.form.slideStartVoting = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.my-font-size {
  font-size: 0.65rem;
}
.my-field {
  text-align: center;
  max-width: 35px;
}
</style>
