<template>
  <v-container class="container">
    <v-card v-if="isLoading">
      <v-progress-linear indeterminate />
    </v-card>
    <v-card v-else class="pa-10">
      <v-card-title> איפוס סיסמה </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              v-model="email"
              :rules="[rules.emailRequired, rules.validEmail]"
              :label="$t('auth.email')"
              id="email"
              required
              :disabled="isRestartPasswordMode"
            />
          </v-col>
        </v-row>
        <v-row v-if="isRestartPasswordMode">
          <v-col cols="12">
            <v-text-field
              v-model="password"
              id="password"
              :label="$t('auth.password')"
              :rules="[rules.passwordRequired, rules.min]"
              minLength="6"
              required
            />
          </v-col>
          <v-col cols="12">
            <v-text-field
              v-model="verifyPassword"
              :label="$t('auth.verifyPassword')"
              :rules="[rules.passwordValidate]"
              minLength="6"
              required
            />
          </v-col>
        </v-row>
        <v-row>
          <v-col class="center-all-col">
            <v-btn @click="restore" :disabled="disable">איפוס סיסמה</v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import SwalMixins from '@/mixins/swal'
export default {
  name: 'RestorePassword',
  mixins: [SwalMixins],

  data() {
    return {
      password: '',
      verifyPassword: '',
      email: '',
      showPassword: false,
      isLoading: true,
      isRestartPasswordMode: false,
    }
  },
  watch: {
    email(newVal) {
      if (newVal !== this.$route.query.email) {
        this.$router.replace({
          ...this.$route,
          query: { ...this.$route.query, email: this.email },
        })
      }
    },
  },
  computed: {
    rules() {
      return {
        emailRequired: v => !!v || 'כתובת מייל חובה',
        validEmail: v => /.+@.+\..+/.test(v) || 'כתובת מייל לא חוקית',
        passwordRequired: v => !!v || 'צריך לכתוב סיסמה',
        passwordValidate: v => v === this.password || 'הסיסמאות לא זהות',
        min: v => v.length >= 6 || 'צריך לכתוב 6 תווים לפחות',
      }
    },
    passwordValidate() {
      return this.password === this.verifyPassword && this.password.length >= 6
    },
    disable() {
      return this.isRestartPasswordMode
        ? !(this.email && this.passwordValidate && this.verifyPassword)
        : !this.email
    },
  },
  methods: {
    async restore() {
      try {
        if (this.isRestartPasswordMode) {
          await this.$store.dispatch('auth/restorePassword', {
            token: this.$route.query.token,
            password: this.password,
          })
          await this.timeoutSwal({
            title: 'הסיסמה עודכנה בהצלחה',
            icon: 'success',
          })
        } else {
          await this.$store.dispatch('auth/restoreByEmail', {
            email: this.email,
          })
          await this.timeoutSwal({
            title: 'קישור נשלח למייל',
            icon: 'success',
          })
        }
        this.$router.push({ name: 'Login' })
      } catch (e) {
        await this.timeoutSwal({
          title: 'פג תוקפ הקישור, אנא נסה שוב',
          icon: 'error',
          text: e.message,
        })
      }
    },
  },
  async mounted() {
    debugger
    if (this.$route.query.email) {
      this.email = this.$route.query.email
    }
    if (this.$route.query.token) {
      try {
        await this.$store.dispatch('auth/restoreByEmail', {
          email: this.$route.query.email,
          token: this.$route.query.token,
        })
        this.isRestartPasswordMode = true
      } catch (e) {
        this.timeoutSwal({
          title: 'פג תוקפ הקישור, אנא נסה שוב',
          icon: 'error',
        })
      }
    }
    this.isLoading = false
  },
}
</script>
<style lang="scss" scoped>
.container {
  @media (max-width: 599px) {
    width: 100%;
    margin: 50% auto;
  }
  @media (min-width: 600px) {
    margin: 10% auto;
    width: 500px;
  }
}
</style>
