<template>
  <v-container>
    <v-row>
      <v-col cols="12" class="font-weight-3">
        <MyCheckbox
          v-model="form['allowChangeVote']"
          text="questions.setting.allowChangeVote"
        />
      </v-col>
      <v-col cols="12" class="font-weight-3">
        <MyRadio
          v-model="form['slideStartVoting']"
          text="questions.setting.slideStartVoting"
        />
      </v-col>
      <v-col cols="12" class="font-weight-3">
        <MyRadio
          v-model="form['playAfterClicking']"
          text="questions.setting.playAfterClicking"
        />
      </v-col>
      <v-col cols="12"><v-divider /></v-col>
      <v-col cols="12">
        <MyCheckbox
          v-model="form['correctlyAnsweredBefore']"
          text="questions.setting.correctlyAnsweredBefore"
        />
      </v-col>
      <v-col cols="12">
        <MyCheckbox
          v-model="form['answerIsSequenceClicks']"
          text="questions.setting.answerIsSequenceClicks"
        />
      </v-col>
      <v-col cols="12" class="py-2 font-weight-3">
        <MyCheckbox v-model="form['test']" text="questions.setting.test">
          <span class="my-font-size font-weight-1">
            (סמנו רק לאחר קריאת ההוראות)
          </span>
        </MyCheckbox>
      </v-col>
      <!-- <v-col cols="12">
        <MyCheckbox
          v-model="form['answerIsSequenceClicks']"
          text="questions.setting.answerIsSequenceClicks"
        />
      </v-col> -->
      <v-col cols="12"><v-divider /></v-col>
      <v-col cols="12">
        <MyRadio
          v-model="form['skipAfterClick']"
          text="questions.setting.skipAfterClick"
        />
      </v-col>
      <v-col cols="12">
        <MyRadio
          v-model="form.automaticSkip.active"
          text="questions.setting.automaticSkip"
        >
          <validation-provider
            v-if="form.automaticSkip.active"
            rules="required"
            v-slot="{ errors }"
          >
            <v-text-field
              v-model="form.automaticSkip.seconds"
              type="number"
              dense
              :error-messages="errors"
              required
              hide-details
              hide-spin-buttons
              autofocus
            />
            <span v-if="errors[0]" class="pink--text text-caption">
              {{ $t('errors.notEmpty') }}
            </span>
          </validation-provider>
          <v-text-field v-else disabled dense hide-details hide-spin-buttons />
        </MyRadio>
      </v-col>
      <v-col cols="12"><v-divider /></v-col>
      <v-col cols="12">
        <MyCarousel
          v-model="form.slidBackgroundMedia"
          title="setting.slidBackgroundMedia"
          defaultFor="slidBackgroundMedia"
          type="videosAndImages"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import MyCheckbox from '@/components/MyCheckbox.vue'
import MyRadio from '@/components/MyRadio.vue'
import { surveyStructure } from '@/data/data structure'
import MyCarousel from '../MyCarousel.vue'
export default {
  name: 'SurveySetting',
  components: {
    MyCheckbox,
    MyRadio,
    MyCarousel,
  },
  props: {
    value: Object,
    invalid: Boolean,
  },
  data() {
    return {
      oldSeconds: '',
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  mounted() {
    this.form = {
      ...surveyStructure.setting,
      ...this.form,
      skipAfterClick: !this.form?.automaticSkip?.active ? true : false,
    }
  },
  watch: {
    'form.skipAfterClick'() {
      if (this.form.skipAfterClick) {
        this.form.automaticSkip.active = false
        this.oldSeconds = this.form.automaticSkip.seconds
        this.form.automaticSkip.seconds = ''
      }
    },
    'form.automaticSkip.active'() {
      if (this.form.automaticSkip.active) {
        this.form.skipAfterClick = false
        this.form.automaticSkip.seconds = this.oldSeconds
      }
    },
    'form.slideStartVoting'() {
      if (this.form.slideStartVoting) {
        this.form.playAfterClicking = false
      }
    },
    'form.playAfterClicking'() {
      if (this.form.playAfterClicking) {
        this.form.slideStartVoting = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.my-font-size {
  font-size: 0.65rem;
}
</style>
