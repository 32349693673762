<template>
  <v-container class="progress">
    <div class="d-flex justify-center align-center">
      <v-progress-circular
        class="center-all"
        v-if="value"
        :rotate="360"
        :size="150"
        :width="15"
        :value="value"
        :color="progressColor"
      >
        {{ Math.ceil(value) }}%
      </v-progress-circular>
      <div v-else max-height="50vh" max-width="50vw">
        <MyIconImage maxWidth="1000" name="logo-anim" type="gif" />
      </div>
    </div>
    <h1 class="d-flex justify-center align-center mt-10">
      {{ messages }}
    </h1>
  </v-container>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import MyIconImage from './MyIconImage.vue'
export default {
  name: 'LoadingPage',
  components: { MyIconImage },
  mixins: [SwalMixins],
  computed: {
    messages() {
      return this.$store.getters.loading.messages
    },
    value() {
      return this.$store.getters.loading.value
    },
    progressColor() {
      if (this.value < 30) {
        return 'red'
      } else if (this.value < 60) {
        return 'pink'
      } else if (this.value < 90) {
        return 'yellow'
      } else {
        return 'light-green accent-3'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
// .progress {
//   margin: auto 20%;
// }
</style>
