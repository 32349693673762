import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './plugins/vee-validate'
import './styles/global.scss'
import i18n from './i18n'
import Cloudinary from 'cloudinary-vue'
import VueNumericInput from 'vue-numeric-input'
import VueNumeric from 'vue-numeric'
import { GlobalFunction } from './plugins/globalFunction'
import CloudinaryPlugin from './plugins/cloudinary'
import 'izitoast/dist/css/iziToast.min.css'

Vue.use(GlobalFunction)
Vue.use(VueNumeric)
Vue.use(VueNumericInput)
Vue.config.productionTip = false
;(async () => {
  //checks user auth status
  try {
    await store.dispatch('auth/checkLogin')
  } catch {
    console.log('No Auth')
  }
  try {
    const setting = await store.dispatch('setting/index')
    Vue.use(CloudinaryPlugin, setting)

    Vue.use(Cloudinary, {
      configuration: {
        cloudName: setting.cloudName,
        secure: true,
      },
    })
  } catch (e) {
    console.log(e)
  }
  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: h => h(App),
  }).$mount('#app')
})()
