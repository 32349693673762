<template>
  <div>
    <my-dialog
      :title="$t('generics.uploadFiles')"
      v-model="openModal"
      :minHeight="200"
    >
      <template v-slot:content>
        <v-card-text
          fluid
          class="center-all"
          @drop.prevent="addFile"
          @dragover.prevent="isDrag = true"
          @dragleave.prevent="isDrag = false"
          @dragend.prevent="isDrag = false"
        >
          <v-icon
            class="text-center"
            size="150px"
            v-text="file ? 'mdi-check-all' : 'mdi-upload'"
            :color="file ? 'primary' : 'grey'"
          />
        </v-card-text>
      </template>
      <template v-slot:actions>
        <div class="d-flex flex-column">
          <div v-if="file">
            <p class="primary--text text-center">
              {{ file.name }}
              <v-btn
                :color="'error'"
                class="text-none"
                depressed
                text
                @click="removeFile"
              >
                {{ buttonRemoveText }}
                <v-icon
                  v-if="buttonRemoveIcon"
                  :right="buttonRemoveText ? true : false"
                  aria-hidden="true"
                >
                  {{ buttonRemoveIcon }}
                </v-icon>
              </v-btn>
            </p>
            <p class="text-center">
              <b>{{ (file.size / 1000).toFixed(1) }}</b> mbs
            </p>
          </div>
          <div v-else>
            <p class="text-center">{{ $t('generics.dragHere') }}</p>
            <!-- <br /> -->
            <p class="text-center">{{ $t('generics.or') }}</p>
            <p>{{ $t('generics.click') }}</p>
          </div>
          <input
            :accept="fileType"
            ref="uploader"
            class="d-none"
            type="file"
            @change="file = $event.target.files[0]"
          />
          <v-btn
            v-if="!file"
            :color="!file ? 'secondary' : 'primary'"
            class="text-none"
            depressed
            @click="$refs.uploader.click()"
          >
            {{ buttonText }}
            <v-icon
              v-if="buttonIcon"
              :right="buttonText ? true : false"
              aria-hidden="true"
            >
              {{ buttonIcon }}
            </v-icon>
          </v-btn>
          <v-btn
            v-else
            :disabled="!file"
            @click="uploadFile"
            text
            color="primary"
            v-text="$t('generics.pressToUpload')"
          />
        </div>
      </template>
    </my-dialog>
  </div>
</template>
<script>
import MyDialog from './MyDialog.vue'
export default {
  components: { MyDialog },
  name: 'UploadFile',
  props: {
    title: { type: String, default: 'Upload file' },
    value: Boolean,
    buttonIcon: { type: String, default: 'mdi-cloud-upload' },
    fileType: { type: String, default: 'xlsx' },
    buttonText: { type: String, default: '' },
    buttonRemoveIcon: { type: String, default: 'mdi-close-thick' },
    buttonRemoveText: { type: String, default: '' },
  },
  data() {
    return {
      isDrag: false,
      files: [],
      file: null,
      newMenus: null,
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
  methods: {
    addFile(e) {
      let files = e.dataTransfer.files
      if (!files) return
      this.file = files[0]
    },
    removeFile() {
      this.files = []
      this.file = null
    },
    uploadFile() {
      this.$emit('handleUpload', this.file)
      this.openModal = false
    },
  },
}
</script>
<style lang="scss" scoped>
.dragBox {
  width: 150px;
}
</style>
