import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    user: null, //chosen user
    users: [],
    filtered: [],
  },
  getters: {
    users: state => state.users,
    user: state => state.user,
    userById: state => id => state.users.find(u => u._id === id),
    userFiltered: state => state.filtered,
  },
  mutations: {
    //sets all users
    'users/set': (state, payload) => {
      state.users = payload
      state.filtered = [...state.users]
    },
    //sets one user
    'user/set': (state, payload) => (state.user = payload),
    //filters the user's array by user's key and user's val
    'users/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.users]
        : state.users.filter(f => f[key] === val)
    },
    //store one user
    'user/store': (state, payload) => state.users.push(payload),
    //destroys one user
    'user/destroy': (state, id) =>
      (state.users = state.users.filter(item => {
        return item._id !== id
      })),
    //updates one user
    'user/update': (state, payload) => {
      state.users = state.users.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all users
    'user/index': async context => {
      try {
        const { data } = await axios.get('/user')
        context.commit('users/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch one user by id
    'user/show': async (context, id) => {
      try {
        let { data } = await axios.get('/user/' + id)
        context.commit('user/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //stores one user
    'user/store': async (context, payload) => {
      try {
        let { data } = await axios.post('/auth/register', payload)
        context.commit('user/store', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //destroys one user
    'user/destroy': async (context, id) => {
      try {
        await axios.delete('/user/' + id)
        context.commit('user/destroy', id)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one user by its id
    'user/update': async (context, payload) => {
      try {
        await axios.put('/user/' + payload._id, payload)
        context.commit('user/update', payload)
        if (!context.getters.isAdmin)
          context.commit('auth/setUserDetails', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one user by its id
    'user/update-assets': async (context, payload) => {
      try {
        const { data } = await axios.put('/user/media', {
          assets: [...context.getters.userAssets, payload],
        })
        context.commit('auth/userAssets/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
