<template>
  <audio
    @canplaythrough="$emit('load')"
    controls
    :style="{ maxWidth }"
    controlsList="nodownload"
  >
    <source
      preload="auto"
      :src="`https://res.cloudinary.com/${cloudName}/video/upload/${publicId}.mp3`"
      type="audio/mpeg"
    />
    <!-- type="audio/ogg"
      type="audio/mpeg"
      type="audio/wav" -->
    <!-- Your browser does not support the audio element. -->
    הדפדפן שלך אינו תומך ברכיב האודיו
  </audio>
</template>
<script>
export default {
  name: 'play-audio-cloudinary',
  props: {
    publicId: String,
    maxWidth: { type: String, default: '236px' },
  },
  computed: {
    cloudName() {
      return this.$store.getters.cloudName
    },
  },
}
</script>
