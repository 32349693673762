<template>
  <v-btn
    @click="googleLogin"
    tile
    outlined
    class="rounded-xl d-flex justify-space-between google-btn"
  >
    <span class="pl-3"> התחבר באמצעות GOOGLE </span>
    <MyIconImage type="svg" name="Google Logo" maxWidth="140" />
  </v-btn>
</template>

<script>
import MyIconImage from '../MyIconImage.vue'

export default {
  name: 'GoogleBtn',
  components: { MyIconImage },
  methods: {
    async googleLogin() {
      window.location.href = '/auth/google'
    },
  },
}
</script>

<style>
.google-btn {
  border: 1px solid var(--blue);
  color: var(--blue) !important;
}
</style>
