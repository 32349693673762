<template>
  <v-dialog
    v-model="openModal"
    :max-width="maxWidth"
    persistent
    v-bind="$attrs"
    v-show="!loading"
  >
    <v-card v-show="!loading">
      <v-app-bar flat color="white">
        <v-toolbar flat max-height="100">
          <v-card-title class="primary--text" v-text="title" />
          <v-spacer />
          <slot name="toolbar" />
          <v-spacer />
          <v-btn icon @click="openModal = false" color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-app-bar>
      <v-sheet
        class="overflow-y-auto ma-auto scrollbar"
        :max-height="maxHeight"
        :min-height="minHeight"
        dir="ltr"
      >
        <div dir="rtl">
          <v-container
            :fill-height="fillHeight"
            fluid
            class="scrollbar overflow-x-hidden"
          >
            <slot name="content" />
          </v-container>
        </div>
      </v-sheet>
      <v-card-actions class="d-flex justify-center">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'StyleDialog',
  // data: () => ({
  //   loading: false,
  // }),
  props: {
    value: Boolean,
    loading: Boolean,
    title: String,
    fillHeight: { type: Boolean, default: false },
    maxWidth: { type: [Number, String], default: 478 },
    maxHeight: { type: [Number, String], default: 600 },
    minHeight: { type: [Number, String], default: 300 },
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-dialog {
  border-radius: 40px;
}
:is(.scrollbar, .v-dialog)::-webkit-scrollbar {
  width: 10px;
}

/* Track */
:is(.scrollbar, .v-dialog)::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px var(--gray);
  border-radius: 10px;
  background-color: var(--gray);
  margin: 20px 0;
}

/* Handle */
:is(.scrollbar, .v-dialog)::-webkit-scrollbar-thumb {
  background: var(--azure);
  border-radius: 10px;
}

/* Handle on hover */
:is(.scrollbar, .v-dialog)::-webkit-scrollbar-thumb:hover {
  background: var(--azure);
}
</style>
