import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    metaData: [],
    filtered: [],
  },
  getters: {
    metaData: state => state.metaData,
    metaDataFiltered: state => state.filtered,
  },
  mutations: {
    //sets all metaData
    'metaData/set': (state, payload) => {
      state.metaData = payload
      state.filtered = [...state.metaData]
    },
    //filters the metaData's array by metaData's key and metaData's val
    'metaData/filter': (state, { key, val }) => {
      state.filtered = !val
        ? [...state.metaData]
        : state.metaData.filter(f => f[key] === val)
    },
    //store one metaData
    'metaData/store': (state, payload) => state.metaData.push(payload),
    //destroys one metaData
    'metaData/destroy': (state, id) =>
      (state.metaData = state.metaData.filter(item => {
        return item._id !== id
      })),
    //updates one metaData
    'metaData/update': (state, payload) => {
      state.metaData = state.metaData.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    //fetch all metaData
    'metaData/index': async context => {
      try {
        const { data } = await axios.get('/metaData')
        context.commit('metaData/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch one metaData by id
    'metaData/show': async (context, id) => {
      try {
        let { data } = await axios.get('/metaData/' + id)
        context.commit('metaData/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //stores one metaData
    'metaData/store': async (context, payload) => {
      try {
        let { data } = await axios.post('/metaData', payload)
        context.commit('repositoryAssets/store', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //destroys one metaData
    'metaData/destroy': async (context, id) => {
      try {
        await axios.delete('/metaData/' + id)
        context.commit('repositoryAssets/destroy', id)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //updates one metaData by its id
    'metaData/update': async (context, payload) => {
      try {
        await axios.put('/metaData/' + payload._id, payload)
        context.commit('repositoryAssets/update', payload)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
