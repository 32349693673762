const surveyQuestion = {
  type: 'survey',
  setting: {
    type: 'survey',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    correctlyAnsweredBefore: false,
    answerIsSequenceClicks: false,
    skipAfterClick: false,
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
  question: {
    que: '',
    timeForQue: 15,
    answers: [
      {
        ans: '',
      },
      {
        ans: '',
      },
    ],
  },
  openMedia: {
    src: '',
    format: '',
  },
  endMedia: {
    src: '',
    format: '',
  },
  backgroundMedia: {
    src: '',
    format: '',
  },
}
export default surveyQuestion
