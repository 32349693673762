import Vue from 'vue'
import {
  extend,
  setInteractionMode,
  ValidationObserver,
  ValidationProvider,
} from 'vee-validate'
import {
  required,
  digits,
  max,
  regex,
  email,
  min,
  numeric,
} from 'vee-validate/dist/rules'

setInteractionMode('eager')

extend('digits', {
  ...digits,
  message: '{_field_} needs to be {length} digits. ({_value_})',
})

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})

extend('max', {
  ...max,
  message: '{_field_} may not be greater than {length} characters',
})

extend('regex', {
  ...regex,
  message: '{_field_} {_value_} does not match {regex}',
})

extend('email', {
  ...email,
  message: 'Email must be valid',
})
extend('multiCheckbox', {
  validate: (value, { isAnsTrueChosen }) => {
    return isAnsTrueChosen
  },
  params: ['isAnsTrueChosen'],
  message: ' ',
})
extend('atLeastOneAnswerApproved', {
  validate: (value, { isValid, skip }) => {
    if (skip) true
    return isValid || ''
  },
  params: ['isValid', 'skip'],
  message: ' ',
})

extend('email', email)
extend('max', max)
extend('min', min)
extend('numeric', numeric)
extend('required', required)

Vue.component('ValidationProvider', ValidationProvider)
Vue.component('ValidationObserver', ValidationObserver)

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
}
