<template>
  <div class="text-center">
    <v-speed-dial v-model="isOpen">
      <template v-slot:activator>
        <slot name="button" @click="isOpen = !isOpen" :disabled="isOpen" />
      </template>
      <BtnStyle
        @click="$emit('openModel')"
        className="cloud-btn my-btn"
        :moreStyles="btnBaseStyle"
      />
      <v-btn
        class="youtube-btn my-btn"
        @click="openModelYoutube = true"
        outlined
        rounded
        v-if="!noYoutube"
      >
        <span class="me-2 font-weight-regular">
          {{ $t('generics.youtubeUpload') }}
        </span>
        <MyIconImage name="youtube" maxWidth="23" />
      </v-btn>
    </v-speed-dial>
    <youtube-upload v-model="openModelYoutube" @save="save" />
  </div>
</template>
<script>
import MyIconImage from '../MyIconImage.vue'
import BtnStyle from './BtnStyle.vue'
import YoutubeUpload from './YoutubeUpload.vue'
export default {
  name: 'SelectUpload',
  props: {
    format: String,
    src: String,
    noYoutube: { Boolean, default: false },
  },
  components: {
    YoutubeUpload,
    MyIconImage,
    BtnStyle,
  },
  data() {
    return {
      isOpen: false,
      openModelYoutube: false,
    }
  },
  computed: {
    btnBaseStyle() {
      return {
        width: '200px',
        background: 'white',
        border: '3px solid',
        'justify-content': 'space-between !important',
      }
    },
  },
  methods: {
    save(v) {
      this.$emit('save', v)
    },
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  width: 200px;
  background: white;
  border: 3px solid;
  justify-content: space-between !important;
}
.youtube-btn {
  border-color: var(--pink);
}
.cloud-btn {
  border-color: var(--azure);
}
</style>
