<template>
  <form @submit.prevent="submit" v-if="question">
    <v-row justify="center">
      <v-col cols="12" align="center">
        <div class="pt-5">
          <span
            class="my-label dark_blue--text font-weight-bold"
            v-text="
              type !== 'media' ? $t('games.que') : $t('questions.mediaTitle')
            "
          />
          <v-textarea
            v-model="form.question.que"
            rows="2"
            class="ma-6"
            outlined
          ></v-textarea>
        </div>
        <v-row gutters align="end">
          <v-col>
            <select-update-format
              :src="form.openMedia.src"
              :btnText="
                !mediaAndMovie ? $t('games.openMedia') : $t('games.media')
              "
              @save="v => saveMedia(v, 'openMedia')"
              :format="form.openMedia.format"
              :deleteText="$t('generics.remove')"
            />
            <!-- @saveAndUpdate="saveAndUpdate" -->
          </v-col>
          <v-col v-if="!mediaAndMovie">
            <!-- @saveAndUpdate="saveAndUpdate" -->
            <select-update-format
              :src="form.endMedia.src"
              :btnText="$t('games.endMedia')"
              @save="v => saveMedia(v, 'endMedia')"
              :format="form.endMedia.format"
              :deleteText="$t('generics.remove')"
            />
          </v-col>
          <v-col v-if="!mediaAndMovie">
            <!-- @saveAndUpdate="saveAndUpdate" -->
            <select-update-format
              :src="form.backgroundMedia.src"
              :btnText="$t('games.backgroundMedia')"
              @save="v => saveMedia(v, 'backgroundMedia')"
              :format="form.backgroundMedia.format"
              :deleteText="$t('generics.remove')"
            />
          </v-col>
        </v-row>

        <div v-if="viewAnswers" class="pt-5">
          <v-row>
            <v-col class="d-flex" offset-md="1">
              <h3 class="dark_blue--text mx-8" align="start">תשובות:</h3>
            </v-col>
          </v-row>
          <div v-if="form.setting.type !== 'ans_images'">
            <answer
              v-for="(answer, i) in form.question.answers"
              :key="i"
              :answer="answer"
              :answersLength="form.question.answers.length"
              :answerIndex="i"
              :ans="answer.ans"
              :correct="answer.correct"
              :canChooseCorrectAns="viewCorrectAns"
              @add="addAns"
              @remove="removeAns(i)"
              @setAns="setAns"
            />
          </div>
          <div v-else>
            <v-row justify="start" class="mx-16">
              <v-col
                v-for="(answer, i) in form.question.answers"
                :key="i + answer.ans"
                cols="auto"
              >
                <answer-media
                  :answersLength="form.question.answers.length"
                  :answerIndex="i"
                  :answer="answer"
                  :canChooseCorrectAns="viewCorrectAns"
                  @add="addAns"
                  @remove="removeAns(i)"
                  @setAns="setAns"
                />
              </v-col>
            </v-row>
          </div>
          <v-row class="ma-10">
            <v-btn
              v-if="form.question.answers.length < 6"
              type="button"
              value="Submit"
              text
              class="font-weight-bold success"
              @click="addAns"
              v-text="$t('games.addAns')"
            />
          </v-row>
        </div>
        <v-row class="mx-2">
          <v-col v-if="viewTimeForQue">
            <v-slider
              v-model="form.question.timeForQue"
              dense
              thumb-label="always"
              :label="$t('games.timeForQue')"
              :hint="$t('games.timeForQue')"
              max="120"
              min="0"
            />
          </v-col>
          <v-col lg="6" md="12" v-if="viewScore">
            <v-slider
              v-model="form.question.scoreForQue"
              dense
              thumb-label="always"
              :label="$t('games.scoreForQue')"
              :hint="$t('games.scoreForQue')"
              max="50"
              min="0"
            ></v-slider>
          </v-col>
        </v-row>
        <v-row class="mx-2"> </v-row>
        <v-row class="center-all my-5">
          <div v-if="errors.length">
            <b>נא לתקן את השגיאות הבאות:</b>
            <ul>
              <li class="red--text" v-for="(error, i) in errors" :key="i">
                {{ error }}
              </li>
            </ul>
          </div>
        </v-row>
        <v-row class="ma-2" justify="center">
          <v-col cols="2"></v-col>
          <v-col>
            <v-btn type="submit" x-large color="blue" outlined>
              <span class="blue--text text-h6 font-weight-bold px-12"
                >שמור</span
              >
            </v-btn>
          </v-col>
          <v-col cols="2" class="d-flex justify-center"> </v-col>
        </v-row>
      </v-col>
    </v-row>
    <slide-setting
      v-if="showSettings"
      v-model="showSettings"
      :slide-setting="form.setting"
      @onClose="$emit('closeSettingModel')"
      @save="saveSetting"
    />
  </form>
</template>

<script>
import _ from 'lodash'

import Answer from '@/components/Answer'
import AnswerMedia from '@/components/AnswerMedia'
import SwalMixins from '@/mixins/swal'
import SlideSetting from './SlideSetting.vue'
import SelectUpdateFormat from './buttons/SelectUploadFormat.vue'

export default {
  name: 'SlideMain',
  components: {
    Answer,
    AnswerMedia,
    SlideSetting,
    SelectUpdateFormat,
  },
  mixins: [SwalMixins],
  props: {
    question: { type: Object },
    isNew: { type: Boolean, default: true },
    value: { type: Boolean, default: false },
    showSettings: { type: Boolean, default: false },
  },
  data() {
    return {
      errors: [],
      form: {
        question: {
          que: '',
          scoreForQue: 3,
          timeForQue: 15,
          answers: [{}],
        },
        openMedia: { src: '', format: '' },
        endMedia: { src: '', format: '' },
        backgroundMedia: { src: '', format: '' },
        setting: {
          type: 'trivia',
          allowChangeVote: false,
          slideStartVoting: false,
          playAfterClicking: false,
          showInLoop: false,
        },
      },
      type: 'trivia',
    }
  },
  computed: {
    viewCorrectAns() {
      const types = ['media', 'subject', 'trivia', 'ans_images']
      return types.includes(this.type)
    },
    viewAnswers() {
      const types = ['survey', 'trivia', 'ans_images']
      return types.includes(this.type)
    },
    viewScore() {
      const types = ['trivia', 'ans_images']
      return types.includes(this.type)
    },
    mediaAndMovie() {
      const types = ['media', 'movie']
      return types.includes(this.type)
    },
    viewTimeForQue() {
      const types = ['trivia', 'survey', 'ans_images']
      return types.includes(this.type)
    },

    game() {
      return this.$store.getters.game
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
    uploadPreset() {
      return this.$store.getters.uploadPreset
    },
    folderName() {
      return this.game
        ? `${this.$store.getters.userDetails.email}/${this.game.name}_${this.game._id}/assets`
        : ''
    },
  },
  methods: {
    saveSetting(setting) {
      this.form['setting'] = setting
      // this.saveAndUpdate();
      this.$emit('closeSettingModel')
    },
    validate() {
      this.errors = []
      // user has to fill open media OR end media OR question (media or text)
      if (
        !this.form.openMedia.src.length &&
        !this.form.endMedia.src.length &&
        !this.form.backgroundMedia.src.length &&
        !this.form.question.que.length
      ) {
        this.errors.push('חייב למלא מדיה פתיחה או מדיה סיום או שאלה')
        return false
      }
      //if this slide type enable to fill answers, validate answers is filled
      if (this.viewAnswers) {
        let answers = this.form.question.answers
        // if (this.form.question.que || this.form.src) {
        // if user fill question, he has to fill answers
        if (!answers.length) this.errors.push('חייב למלא תשובות')
        const emptyAns = answers.filter(answer => !answer.ans)
        if (emptyAns.length) this.errors.push('לא להשאיר תשובות ריקות')
        if (
          this.viewCorrectAns &&
          !answers.filter(answer => answer.correct).length
        )
          this.errors.push('יש לסמן לפחות תשובה אחת כנכונה')
      }
      return !this.errors.length
    },

    async saveAndUpdate() {
      try {
        if (!this.viewAnswers) {
          this.form.question.answers = []
        }
        const data = {
          ...this.form,
          question: {
            ...this.form.question,
            // answers: this.viewAnswers ? this.form.question.answers : [],
          },
          type: this.type,
          game: this.game._id,
        }
        const action = this.form && this.form._id ? 'update' : 'store'
        await this.$store.dispatch(`question/${action}`, data)
      } catch (e) {
        this.timeoutSwal({
          title: this.$t('questions.saveError'),
          icon: 'error',
        })
        console.log(e)
      }
    },
    async submit() {
      if (!this.validate()) return
      this.saveAndUpdate()
      this.$emit('clearCurrentQuestion')
   
    },
    saveMedia(value, name) {
      this.form[name].src = value.public_id
      this.form[name].format = value.format
    },
    addAns() {
      this.form.question.answers.push({})
    },
    removeAns(index) {
      if (index > -1) {
        this.form.question.answers.splice(index, 1)
      }
    },
    setAns(ans) {
      if (ans.index > -1) this.form.question.answers[ans.index] = ans
    },
  },
  mounted() {
    if (!this.isNew) {
      this.form = {
        type: this.question?.setting?.type,
        ...this.form,
        ..._.cloneDeep(this.question),
      }
    }
  },
  watch: {
    'form.setting.type'() {
      this.type = this.form.setting.type
      if (this.type === 'trivia') this.addAns()
    },
  },
}
</script>
