import axios from 'axios'

export default class Cloudinary {
  constructor({ uploadPreset = '', cloudName = '' }) {
    this.uploadPreset = uploadPreset
    this.url = `https://api.cloudinary.com/v1_1/${cloudName}`
  }
  async upload({ file, folder, formatsAllowed = [], _this, errorHandler }) {
    try {
      let [type, format] = file.type.split('/')
      type = type !== 'audio' ? type : 'video'
      if (!format || !formatsAllowed.includes(format.toLowerCase()))
        throw 'Format not approved'
      const formData = new FormData()
      formData.append('upload_preset', this.uploadPreset)
      formData.append('file', file)
      formData.append('folder', folder)

      const { data } = await axios.post(
        `${this.url}/${type}/upload`,
        formData,
        { onUploadProgress: e => this.onUploadProgress(e, _this) }
      )

      return data
    } catch (e) {
      console.log(e)
      errorHandler()
    }
  }
  onUploadProgress(progressEvent, _this) {
    var percentCompleted = Math.round(
      (progressEvent.loaded * 100) / progressEvent.total
    )
    // console.group('profileActions.uploadProfileVideoURL')
    // console.log('progressEvent.loaded: ', progressEvent.loaded)
    // console.log('progressEvent.total: ', progressEvent.total)
    // console.log('percentCompleted: ', percentCompleted)
    // console.groupEnd()
    _this.progress = percentCompleted
  }
}
