<template>
  <v-dialog v-model="openModal" max-width="400" height="500" persistent>
    <v-card height="150">
      <v-app-bar flat color="white">
        <v-toolbar flat max-height="100">
          <v-btn icon @click="openModal = false" color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-app-bar>
      <v-card-text fill-height fluid class="d-flex justify-space-between">
        <v-btn
          @click="goToPath('NewStyle')"
          v-text="'לעיצוב החדש'"
          color="green"
          dark
        />
        <v-btn
          @click="goToPath('CreateGame')"
          v-text="'לעיצוב הישן'"
          color="primary"
        />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'SelectStyle',
  props: {
    value: Boolean,
    gameId: String,
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    goToPath(name) {
      this.$router.push({
        name,
        params: { id: this.gameId },
      })
    },
  },
}
</script>
