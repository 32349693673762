<template>
  <v-row class="blueLogo--text" dir="ltr" v-if="true">
    <v-col>
      <v-list class="scrollbar overflow-x-hidden" max-height="50vh">
        <template>
          <div class="blueLogo--text" dir="rtl">
            <v-row class="justify-center pa-5">
              <v-col cols="11">
                <v-textarea
                  v-model="form.name"
                  @change="save"
                  auto-grow
                  color="deep-purple"
                  :label="$t('setting.gameName')"
                  rows="1"
                  autofocus
                ></v-textarea>
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="pa-5">
              <v-col cols="12" class="d-flex font-weight-2 my-font-size mr-4">
                <div :disabled="true">
                  <span>
                    {{ $t('setting.showWinnersListAfter') }}
                  </span>
                  <vue-numeric
                    :disabled="cancelWinnersList"
                    class="num-input mx-1"
                    v-model="form.setting.showWinnersListAfter"
                    :min="1"
                    @change="save"
                    @focus="$event.target.select()"
                  />
                  <span class="ml-2">
                    {{ $t('questions.questions') }}
                  </span>
                </div>
              </v-col>
              <v-col cols="12" class="font-weight-1">
                <MyCheckbox
                  v-model="cancelWinnersList"
                  text="setting.cancelWinnersList"
                  @change="save"
                />
              </v-col>
            </v-row>
            <v-divider />
            <v-row class="pa-5">
              <v-col cols="12">
                <label class="text-subtitle-2 blueLogo--text">
                  {{ $t('setting.multiWinners') }}
                </label>
                <vue-numeric
                  class="num-input"
                  v-model="form.setting.multiWinners"
                  :min="1"
                  :max="5"
                  @change="save"
                  @focus="$event.target.select()"
                />
              </v-col>
            </v-row>
          </div>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import MyCheckbox from '../MyCheckbox.vue'
import swalMixins from '@/mixins/swal'

export default {
  name: 'GameSetting',
  components: { MyCheckbox },
  props: { value: Object },
  mixins: [swalMixins],
  computed: {
    game() {
      return this.$store.getters.game
    },
  },
  data() {
    return {
      isFirstRender: true,
      form: {
        setting: {
          ansIsNumber: true,
          showWinnersListAfter: '',
          multiWinners: 5,
        },
      },
      oldShowWinnersListAfter: '',
      cancelWinnersList: false,
      isMounted: true,
      firstRender: true,
    }
  },
  methods: {
    async save() {
      if (!this.form.name || this.isMounted) return
      await this.$save(this, 'game/update', this.form)
      if (this.firstRender) {
        this.firstRender = false
        return
      }
      this.successToast({
        title: this.$t('generics.changesSaved'),
      })
    },
  },
  mounted() {
    this.form = {
      ...this.game,
      setting: { ...this.form.setting, ...this.game.setting },
    }
    if (!this.form?.setting.showWinnersListAfter) {
      this.cancelWinnersList = true
    }
    this.isMounted = false
  },
  watch: {
    form: {
      deep: true,
      async handler() {
        if (!this.isFirstRender) {
          await this.save()
        }
      },
    },
    async cancelWinnersList() {
      if (this.cancelWinnersList) {
        this.oldShowWinnersListAfter = this.form.setting.showWinnersListAfter
        this.form.setting.showWinnersListAfter = ''
        await this.save()
      } else {
        this.form.setting.showWinnersListAfter =
          this.oldShowWinnersListAfter || '5'
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .cancel-btn {
  border-color: var(--pink) !important;
}
::v-deep .save-btn {
  border-color: var(--green) !important;
}
.num-input {
  max-width: 23px;
  max-height: 23px;
  border: 1px solid red;
  border-radius: 4px;
  text-align: center;
}
::v-deep .my-font-size {
  font-size: 0.79rem;
}
</style>
