<template>
  <v-app-bar flat>
    <v-row>
      <v-col cols="1 ">
        <div class="d-flex justify-start" v-if="!isHomePage">
          <v-icon color="primary" @click="goHomePage">mdi-arrow-right</v-icon>
        </div>
        <div class="d-flex justify-start" v-else-if="!isAdmin">
          <v-icon color="primary" @click="showUserDialog"
            >mdi-account-circle</v-icon
          >
        </div>
      </v-col>
      <v-col cols="8" class="d-flex justify-center align-center">
        <v-toolbar-title
          class="text-h4 justify-self-start"
          v-if="$store.getters.game"
          v-text="$store.getters.game.name"
        />
      </v-col>
      <v-col cols="3" class="d-flex justify-end" style="gap:12px">
        <v-btn
          outlined
          rounded
          @click="goToLearn"
          elevation="8"
          style="background-color: #212b46; color: white; border: none"
        >
          <MyIconImage name="question" maxWidth="25" />
          <span class="mx-2 font-weight-2">
            {{ $t('route.learn') }}
          </span>
        </v-btn>
        <div class="d-flex justify-end mx-2">
          <v-icon color="red" @click="logout" v-text="'mdi-power'" />
        </div>
      </v-col>
    </v-row>
    <template v-if="tabs" v-slot:extension>
      <v-tabs centered>
        <v-tab>Tab 1</v-tab>
        <v-tab>Tab 2</v-tab>
        <v-tab>Tab 3</v-tab>
      </v-tabs>
    </template>
  </v-app-bar>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import MyIconImage from '@/components/MyIconImage.vue'

export default {
  name: 'Navbar',
  mixins: [SwalMixins],
  components: { MyIconImage },
  data() {
    return {
      tabs: false,
      userDialog: false,
    }
  },
  computed: {
    isHomePage() {
      return this.$route.name.includes('Dashboard')
    },
    isAdmin() {
      return this.$store.getters.adminLoggedIn
    },
  },
  methods: {
    goToLearn() {
      window.open('https://clicker.tawk.help/ ', '_blank')
    },
    goHomePage() {
      this.$router.push({
        name: this.isAdmin ? 'AdminDashboard' : 'UserDashboard',
      })
    },
    showUserDialog() {
      return this.$store.commit('auth/userDetailsDialog', !this.userDialog)
    },
    async logout() {
      let { isConfirmed } = await this.createSwal({
        title: `${this.$t('auth.logout')}?`,
        icon: 'question',
        confirmColor: 'red',
      })
      if (isConfirmed) await this.$store.dispatch('auth/logout')
    },
  },
}
</script>
