var ExcelJS = require('exceljs')
const { saveAs } = require('file-saver')
const ParseGroups = groups => {
  const groupIds = []
  const groupsArr = []
  if (typeof groups === 'object') {
    for (const [key, group] of Object.entries(groups)) {
      console.log(key)
      groupIds.push(group.id)
      group.groups.forEach(g => {
        g['total_score'] = 0
        g['total_users'] = 0
      })
      groupsArr.push(group)
    }
  }
  return { groupsArr, groupIds }
}
class ExportExcel {
  constructor(data, xlsName) {
    this.xlsName = xlsName
    this.workbook = new ExcelJS.Workbook()
    const groups = data.groups ? JSON.parse(data.groups) : {}
    const { groupsArr, groupIds } = ParseGroups(groups)
    this.questionArr = []
    this.questionObj = data.questions ? JSON.parse(data.questions) : {}
    this.questionsLength = Object.keys(this.questionObj).length
    this.questionIds = []

    this.groupsArr = groupsArr
    this.groupIds = groupIds

    this.usersObj = data.users ? JSON.parse(data.users) : []
    this.usersLength = Object.keys(this.usersObj).length
  }

  async createWorksheet({ name, headers, rows }) {
    let worksheet = this.workbook.addWorksheet(name)

    worksheet.columns = headers
    for await (const row of rows) {
      worksheet.addRow(row)
    }

    console.log(worksheet.getColumn(1))
    // iterate over all current cells in this column including empty cells
    headers.forEach((h, i) => {
      const dobCol = worksheet.getColumn(i + 1)
      dobCol.width = 20
      // dobCol.eachCell({ includeEmpty: true }, function (cell /*rowNumber*/) {
      //   cell.font = {
      //     name: 'Arial Black',
      //     color: { argb: '123FFF' },
      //     family: 2,
      //     size: 14,
      //     italic: true,
      //   }
      // })
    })
  }
  writeXls() {
    this.workbook.xlsx
      .writeBuffer()
      .then(buffer => saveAs(new Blob([buffer]), `${this.xlsName}.xlsx`))
      .catch(err => console.log('Error writing excel export', err))
  }
  async createXlsGameResult() {
    if (this.questionsLength) {
      await this.createQuestionsWorksheet()
    }
    if (this.usersLength) {
      await this.createUsersWorksheet()
    }
    if (this.groupsArr.length) {
      await this.createGroupsWorksheet()
    }
    this.writeXls()
  }

  async createGroupsWorksheet() {
    for await (const group of this.groupsArr) {
      const headers = [
        {
          header: 'קבוצות',
          key: '--',
        },
        ...group.groups.map(g => ({ header: g.name, key: `groupId_${g.id}` })),
      ]
      const rows = []
      const scores = {
        '--': 'ניקוד:',
      }
      const numPlayers = {
        '--': 'מספר משתתפים:',
      }
      this.groupsArr.forEach(group => {
        group.groups.forEach(g => {
          scores['groupId_' + g.id] = g['total_score']
          numPlayers['groupId_' + g.id] = g['total_users']
        })
      })
      rows.push(scores)
      rows.push(numPlayers)
      await this.createWorksheet({
        name: 'קבוצות-' + group.name,
        rows,
        headers,
      })
    }
    return
  }
  async createUsersWorksheet() {
    const headers = [
      { header: 'userId', key: 'id' },
      { header: 'שם שחקן', key: 'name' },
    ]
    this.questionIds.forEach(q => {
      headers.push({ header: 'שאלה ' + q, key: 'queId_' + q })
    })
    const rows = []
    const ques = {}
    const groups = {}
    var temp = Object.values(this.usersObj)
    temp.sort((a, b) => a?.details?.score - b?.details?.score).reverse()
    for (const [key, user] of Object.entries(temp)) {
      console.log(key)
      user['score'] = user['score'] ? user['score'] : 0
      this.questionIds.forEach(q => {
        ques[`queId_${q}`] = user[`scoreFoeQueId_${q}`] || 0
      })
      this.groupIds.forEach((g, i) => {
        const groupId = user?.[`category_${g}`]?.groupId
        if (groupId) {
          const currentGroup = this.groupsArr[i]?.groups.find(
            g => g.id === groupId
          )
          currentGroup['total_score'] = user[`score`]
            ? +user[`score`] + currentGroup['total_score']
            : +currentGroup['total_score']
          currentGroup['total_users'] = currentGroup['total_users'] + 1
          groups[`groupId_${g}`] = currentGroup?.name || ''
        }
      })
      const {
        details: { name, id },
        score,
      } = user
      rows.push({
        id,
        name,
        ...ques,
        score,
        ...groups,
      })
    }
    headers.push({ header: 'ניקוד', key: 'score' })
    this.groupIds.forEach((group, i) => {
      const { name, id } = this.groupsArr[i]
      headers.push({
        header: name,
        key: `groupId_${id}`,
      })
    })
    return await this.createWorksheet({ name: 'שחקנים', rows, headers })
  }
  async createQuestionsWorksheet() {
    const headers = [
      { header: 'מספר שאלה', key: 'queId' },
      { header: 'שאלה', key: 'que' },
      { header: 'הצבעות לתשובה 1', key: 'ans_1' },
      { header: 'הצבעות לתשובה 2', key: 'ans_2' },
      { header: 'הצבעות לתשובה 3', key: 'ans_3' },
      { header: 'הצבעות לתשובה 4', key: 'ans_4' },
      { header: 'הצבעות לתשובה 5', key: 'ans_5' },
      { header: 'הצבעות לתשובה 6', key: 'ans_6' },
      { header: 'הצבעות לתשובה 7', key: 'ans_7' },
    ]
    const rows = []
    const answers = {}
    for (const [key, que] of Object.entries(this.questionObj)) {
      console.log(key)
      for (const [key, ans] of Object.entries(que.answers)) {
        answers[`ans_${key}`] = ans
      }
      this.questionIds.push(que.queId)
      rows.push({
        queId: que.queId,
        que: que.que,
        ...answers,
      })
    }
    await this.createWorksheet({ name: 'שאלות', rows, headers })
  }
}
export default ExportExcel
