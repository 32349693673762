<template>
  <div class="d-flex align-start">
    <CustomCheckbox v-model="form" @click="form = !form" />
    <span
      class="px-2 my-font-size font-weight-1 mouse-pointer"
      @click="form = !form"
      v-text="$t(text)"
    />
    <slot />
  </div>
</template>
<script>
import CustomCheckbox from './CustomCheckbox.vue'

export default {
  name: 'MyCheckbox',
  components: { CustomCheckbox },
  props: { value: Boolean, text: String },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.my-font-size {
  font-size: small;
}
</style>
