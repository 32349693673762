const he = {
  appTitle: 'מחולל השאלות',
  generics: {
    changesSaved: 'השינויים נשמרו בהצלחה',
    inDevelopment: 'הגדרות אלו הינם בפיתוח ואינם זמינות כרגע',
    error: 'אירעה שגיאה',
    login: 'כניסה',
    register: 'הרשמה',
    room: 'חדר',
    uploadFiles: 'העלאת קבצים',
    pressToUpload: 'לחצו כאן להעלאת הקובץ',
    youtubeUpload: 'העלאה מיוטיוב',
    mediaUpload: 'העלאת מדיה',
    mediaUploadAgain: 'העלאה מחדש',
    uploadBackground: 'העלאת רקע משלכם',
    uploadSound: 'העלאת סאונד משלכם',
    uploadFile: 'להעלאת הקובץ ',
    downloadTemplate: 'להורדת התבנית ',
    import: 'ייבא',
    administration: 'מנהל',
    action: 'פעולות',
    dragHere: 'גרור את הקבצים לפה',
    click: 'תלחץ על הכפתור',
    or: 'או',
    reduced: 'ירד|ירדו',
    after: 'אחרי',
    score: 'ניקוד|נקודות',
    the: 'את',
    home: 'בית',
    mainMenu: 'תפריט ראשי',
    change: 'שנה',
    actions: 'פעולות',
    delete: 'למחוק',
    remove: 'מחק',
    name: 'שם',
    add: 'הוסף',
    save: 'שמור',
    edit: 'ערוך',
    search: 'חיפוש',
    searchByCategories: 'חיפוש על פי קטגוריה',
    new: 'חדש',
    ok: 'אישור',
    cancel: 'ביטול',
    confirm: 'אישור',
    users: 'משתמשים',
    selectColors: 'בחר צבע',
    color: 'צבע',
    send: 'שלח',
    title: 'כותרת',
    message: 'הודעה',
    urgent: 'דחוף',
    regular: 'רגיל',
    complex: 'מורכב',
    createdAt: 'נוצר בתאריך',
    showLink: 'קישור למשחק',
    toPay: 'לתשלום',
    numbers: 'מספרים',
    words: 'אותיות',
    slide: 'מסך|מסכים',
    setting: 'הגדרות {a}{var}',
  },
  route: {
    MyGame: 'המשחקים שלי',
    AllGames: 'כל השעשועונים שהונפקו עד היום',
    AllQuestions: 'כל השקופיות',
    QuestionRepository: 'מאגר השקופיות',
    Management: 'מחולל הסיסמאות וניהול משתמשים',
    CreateGame: 'יצירת משחק',
    AllUsers: 'מחולל הסיסמאות וניהול משתמשים',
    UserDashboard: 'מחולל השאלות',
    AdminDashboard: 'מחולל השאלות - ניהול',
    Login: 'כניסה',
    NewStyle: 'יצירת משחק העיצוב החדש',
    learn: 'למרכז ההדרכה',
  },
  days: {
    sunday: 'יום ראשון',
    monday: 'יום שני',
    tuesday: 'יום שלישי',
    wednesday: 'יום רביעי',
    thursday: 'יום חמישי',
    friday: 'יום שישי',
    saturday: 'שבת',
    all: 'כל הימים',
  },
  daysKeys: {
    sunday: 'א',
    monday: 'ב',
    tuesday: 'ג',
    wednesday: 'ד',
    thursday: 'ה',
    friday: 'ו',
    saturday: 'ז',
  },
  date: {
    month: 'חודש',
    week: 'שבוע',
    day: 'יום',
    today: 'היום',
    dateStart: 'תאריך התחלה',
    dateEnd: 'תאריך סיום',
    creationDate: 'תאריך יצירה',
    deletionDate: 'תאריך מחיקה',
    timeStart: 'זמן התחלה',
    timeEnd: 'זמן סיום',
    name: {
      January: 'ינואר',
      February: 'פברואר',
      March: 'מרץ',
      April: 'אפריל',
      May: 'מאי',
      June: 'יוני',
      July: 'יולי',
      August: 'אוגוסט',
      September: 'ספטמבר',
      October: 'אוקטובר',
      November: 'נובמבר',
      December: 'דצמבר',
    },
  },

  auth: {
    phone: 'מספר טלפון',
    fullname: 'שם מלא',
    email: 'אימייל',
    password: 'סיסמה',
    login: 'התחברות',
    logout: 'להתנתק',
    disconnection: 'התנתקות',
    change: 'לשנות פרטי משתמש',
    registerSuccess: 'נרשמת בהצלחה!',
    updateSuccess: 'עודכן בהצלחה!',
    loginByGoogle: 'כניסה עם גוגל',
  },

  userDetails: {
    userDetails: 'פרטים אישיים',
    name: 'שם',
    phone: 'טלפון',
    email: 'אימייל',
    address: 'כתובת',
    addNewUser: 'הוסף משתמש חדש',
    editUserDetails: 'ערוך פרטי משתמש',
  },
  errors: {
    validate: {
      required: 'שדה חובה',
      minlen6: 'מינימום 6 תוים',
      minlen2: 'מינימום 2 תוים',
      maxlen40: 'מקסימום 40 תוים',
      validNumber: 'אנא הכנס מספר תקין',
    },
    auth: {
      email: 'יש להזין אמייל',
      emailInvalid: 'אימייל שגוי',
      pass: 'יש להזין סיסמה',
      minlen6: 'מינימום 6 תוים',
    },
    notEmpty: 'לא יכול להיות ריק',
    global: 'אירעה שגיאה בלתי צפויה',
    globalShort: 'שגיאה',
    missingItems: 'פרטים חסרים',
    format: 'הפורמטים שאפשר לעלות הם:',
    tildeUpload: 'אין לעלות קובץ עם הסימון ~',
    logo: 'יש להעלות גם לוגו וגם כיתוב',
    colors: 'שהצבעים יתאימו לרקע שבחרתם',
    attention: 'שימו לב',
    formatMessage: 'שימו לב! ניתן להעלות רק קבצים בפורמט: {formats}',
    userNotFound: 'משתמש לא נמצא',
  },
  setting: {
    gameResults: 'תוצאות המשחקים',
    gameName: 'שם המשחק',
    setting: 'הגדרות',
    clickHereTo: 'לחץ כאן ל{prop}',
    saveGame: 'לחץ כאן לשמירת המשחק',
    clickHereToExpotFromXl: 'לחץ כאן לייבא שאלות מקובץ אקסל',
    importFromXl: 'ייבוא מאקסל',
    importFromXlHelper: 'הוראות יבוא קובץ אקסל',
    export: 'ייצוא משחק',
    textAllGame: 'כיתוב שיופיעו לכל אורך המשחק',
    maxParticipants: 'מקסימום משתתפים',
    image: 'תמונת רקע',
    gameMedia: 'תמונת רקע',
    gameBackgroundImage: 'בחירת רקע למשחק',
    slidBackgroundMedia: 'בחירת רקע לשקופית זו',
    logo: 'לוגו',
    addYourLogo: 'הוספת לוגו משלכם',
    limitNumberParticipants: 'הגבל את מספר המשתתפים',
    specificSettings: 'הגדרות השקופית',
    showWinnersListAfter: 'הצג טבלת תוצאות אחרי',
    cancelWinnersList: 'בטל הצגת המובילים באופן אוטומטי',
    triviaMedia: 'מדיה לטריוויה',
    winnersListMedia: 'מדיה לרשימת המובילים',
    winnersMedia: 'מדיה למנצח/ים',
    demoGame: 'משחק לדוגמה',
    payPage: 'עמוד תשלום',
    ansIsNumber: 'התשובות שיופיעו על המסך הם לפי',
    multiWinners: 'מספר המנצחים במשחק',
    winnersMediaSound: 'סאונד של המנצחים',
    showQuestionMediaSound: 'סאונד בזמן הצגת השאלה',
    playersConnectingMediaSound: 'סאונד בזמן הצטרפות משתתפים',
    winnersListMediaSound: 'סאונד של רשימת המובילים',
    genericMediaSound: 'כפים במהלך המשחק',
    timerMediaSound: 'סאונד טיימרים',
    inShowAnsMediaSound: 'סאונד כשמראים את התשובה',
    yourColors: 'התאמת הצבעים שלכם למשחק',
    trivia: '@:setting.setting השאלה',
    survey: '@:setting.setting הסקר',
    discussion: '@:setting.setting דיון',
    subject: '@:setting.setting מסך מעבר',
    big_image: '@:setting.setting תמונה גדולה',
    ans_images: '@:setting.setting תשובות כתמונה',
    media: 'מדיה',
  },
  images: {
    save: 'התמונה נשמרה בהצלחה',
    saveError: 'הייתה שגיאה בהעלעת התמונה',
    delete: 'התמונה נמחקה בהצלחה',
    deleteError: 'הייתה שגיאה במחיקת התמונה',
    errorSize: 'תמונה גדולה מ',
  },
  games: {
    gamesResults: 'תוצאות משחקים',
    transfer: 'העברת בעלות',
    save: 'המשחק נשמר בהצלחה',
    delete: 'למחוק את המשחק',
    name: 'שם המשחק',
    addAns: 'הוסף תשובה',
    answers: 'תשובות',
    correctAns: 'תשובה נכונה',
    score: 'ניקוד|נקודות',
    scoreForQue: 'נקודות לשאלה',
    timeForQue: 'זמן מענה',
    que: 'שאלה',
    media: 'מדיה',
    openMedia: 'מדיה פתיחה',
    endMedia: 'מדיה סיום',
    backgroundMedia: 'תמונה ברקע',
    editingQuestion: 'עריכת שאלה',
    questionEditor: 'עורך השאלות',
    thereMedia: 'יש מדיה',
    duplicateGameTooltip: 'לחץ לשכפול המשחק',
    editGameTooltip: 'לחץ עריכת המשחק',
    deleteGameTooltip: 'לחץ למחיקת המשחק',
    gamePreview: 'תצוגה מקדימה',
    closeGame: 'סגירת משחק',
    setting: {
      mainColor: 'צבע ראשי',
      secondaryColor: 'צבע משני',
    },
  },
  questions: {
    questions: 'שאלות',
    sum: 'סך כל השאלות',
    save: 'שמירת מסך בוצעה בהצלחה',
    saveError: 'שגיאה בשמירת המסך',
    delete: 'האם אתה בטוח שברצונך למחוק את השאלה',
    addQuestionTooltip: 'לחץ כאן כדי להוסיף שאלה / מדיה',
    addQuestionBtn: ' שאלה חדשה',
    importQuestionTooltip: 'לחץ כאן כדי לייבא שאלה מהמאגר',
    importQuestionBtn: 'ייבוא שאלה',
    addingContentRepository: 'הוספת תוכן מהמאגר',
    importQuestions: 'ייבוא שאלות',
    duplicateQuestionTooltip: 'לחץ לשכפול השאלה',
    editQuestionTooltip: 'לחץ עריכת השאלה',
    deleteQuestionTooltip: 'לחץ למחיקת השאלה',
    showAndDoNotShowToRegularUserTooltip: 'תציג/אל תציג את השקופית למשתמש רגיל',
    selectType: 'בחר סוג שאלה',
    settingText: 'הגדרות מסך',
    createByUser: 'נוצר על ידי',
    questionLabeling: 'תייג את השאלה',
    labels: 'קטגוריות',
    slidBackgroundMedia: 'מדיה רקע לשקופית',
    categories: 'קטגוריות',
    isNotInRepository: 'לא במאגר',
    isInRepository: 'בתוך המאגר',
    mediaTitle: 'שם שקופית המדיה',
    setting: {
      showInLoop: 'הצג בלופ',
      fullscreen: 'מדיה על כל המסך',
      exitGame: 'מי שלא עונה נכון יצא מהמשחק',
      correctlyAnsweredBefore: 'מי שענה נכון על השאלה שלפניה יכול לענות',
      firstClicker: 'רק הלוחץ ראשון יוכל לענות',
      answerIsSequenceClicks: 'שאלה שהתשובה תהיה רצף לחיצות לפי סדר מסוים',
      allowChangeVote: 'אפשר לשנות את ההצבעה אחרי שכבר הצבעת',
      scoringReduction: 'הפחתת ניקוד לאחר זמן',
      seconds: 'שניות',
      score: 'ניקוד',
      slideStartVoting: 'הצבעה מתחילת עליית השאלה',
      playAfterClicking: 'הפעלה אחרי לחיצה',
      skipAfterClick: 'מעבר שקופיות אחרי לחיצה',
      automaticSkip: 'מעבר שקופיות אטומטי',
      test: 'הסרת אובייקטים מהמסך',
      automaticSkipError: 'שניות למעבר לשקופית הבאה',
    },
  },
  answers: {
    answers: 'תשובות',
    answer: 'תשובה | תשובות',
  },
  categories: {
    editLabelsTooltip: 'לחץ לעריכת הקטגוריות',
    delete: 'האם אתה בטוח שברצונך למחוק את הקטגוריה',
    add: 'הוסף קטגוריה',
  },
  drawer: {
    setting: 'הגדרות משחק',
    style: 'הגדרות עיצוב',
    advancedSettings: 'הגדרות מתקדמות',
  },
  types: {
    trivia: 'שאלה',
    survey: 'סקר',
    discussion: 'דיון',
    subject: 'מסך מעבר',
    big_image: 'תמונה גדולה',
    ans_images: 'תשובות כתמונה',
    media: 'מדיה',
  },
  audios: {
    selectFromRepository: 'בחירה מהמאגר',
  },
}
export default he
