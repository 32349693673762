<template>
  <v-list-item :disabled="disabled" class="list-item pl-0">
    <v-hover v-slot="{ hover }">
      <v-card
        class="card-item"
        :class="isChosen ? 'card-item-active' : ''"
        elevation="0"
      >
        <v-list-item
          class="list-group-ite"
          @click="$emit('selectQuestion', que)"
        >
          <v-list-item-action class="mx-0">
            <div v-if="hover">
              <img
                style="max-width: 20px"
                :src="`/assets/icons/draggable.png`"
              />
            </div>
          </v-list-item-action>
          <v-list-item-avatar class="px-0">
            <v-avatar>
              <div>
                <img
                  style="max-width: 20px"
                  :src="`/assets/icons/${que.setting.type}.png`"
                />
              </div>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-avatar class="px-0">
            <span class="blue--text text-h5 font-weight-1">{{
              index + 1
            }}</span>
          </v-list-item-avatar>
          <!-- <v-divider
            style="max-height: 50px"
            class="px-2 py-4"
            vertical
            inset
          /> -->
          <v-list-item-content :style="`width: ${breakpoint}px`">
            <v-list-item-title
              class="font-weight-3"
              v-text="que.question.que"
            />
            <v-list-item-subtitle
              class="font-weight-1"
              v-text="
                correctAns(que.question.answers)
                  ? correctAns(que.question.answers)
                  : chosenType(que).text
              "
            />
          </v-list-item-content>

          <v-list-item-action>
            <v-row>
              <v-col class="pa-0">
                <tooltip-icon
                  :tooltipText="$t('questions.duplicateQuestionTooltip')"
                  :icon="'mdi-content-copy'"
                  :fab="true"
                  @action="duplicate(que)"
                />
              </v-col>
              <v-col class="pa-0">
                <tooltip-icon
                  :tooltipText="$t('questions.deleteQuestionTooltip')"
                  :icon="'mdi-delete-outline'"
                  src="trash"
                  :fab="true"
                  color="error"
                  @action="deleteQuestion(que._id)"
                />
              </v-col>
            </v-row>
          </v-list-item-action>
        </v-list-item>
        <v-divider inset v-if="!isLastOne" />
      </v-card>
    </v-hover>

    <!-- <v-divider inset v-if="!isLastOne" /> -->
  </v-list-item>
</template>
<script>
import { typesArray, findType } from '@/components/types/helper'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import SwalMixins from '@/mixins/swal'

export default {
  name: 'QuestionLine',
  components: { TooltipIcon },
  props: {
    value: Boolean,
    que: { type: Object, default: () => ({}) },
    index: Number,
    isChosen: Boolean,
    isLastOne: Boolean,
    disabled: Boolean,
    clear: Function,
  },
  mixins: [SwalMixins],
  data: () => ({
    icons: typesArray,
  }),
  computed: {
    breakpoint() {
      let point = 400
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return (point = 220)
        case 'sm':
          return (point = 400)
        case 'md':
          return (point = 500)
        case 'lg':
          return (point = 600)
        case 'xl':
          return (point = 800)
      }
      return point
    },
  },
  methods: {
    chosenType(current) {
      if (current?.setting?.type) {
        return findType(current?.setting?.type)
      }
    },
    correctAns(answers) {
      const ans = answers?.length
        ? answers.filter((a, i) => {
            const index = i + 1
            if (this.que?.setting?.type === 'ans_images') {
              a['ans_view'] = ' תמונה מספר ' + index
            } else {
              a['ans_view'] = ` תשובה מספר ${index}: ${a.ans}`
            }
            return a.correct
          })
        : []
      return ans.map(a => a.ans_view).toString()
    },
    async duplicate(question) {
      await this.$store.dispatch('question/replicate', {
        id: question._id,
        index: this.index,
      })
    },
    async deleteQuestion(id) {
      const { isConfirmed } = await this.createSwal({
        title: `${this.$t('questions.delete')}?`,
        confirmButtonText: this.$t('generics.remove'),
        denyButtonText: this.$t('generics.cancel'),
        confirmColor: 'red',
        cancelColor: '#2196F3',
      })
      if (isConfirmed) {
        try {
          await this.$store.dispatch('question/destroy', id)
          this.clear()
        } catch (e) {
          console.log(e)
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-divider--inset:not(.v-divider--vertical) {
  max-width: 83%;
}
:is(.card-item, .list-item) {
  border-radius: 40px;
}
.card-item {
  direction: rtl;
}
.card-item:hover {
  background: var(--lightGray);
}
.card-item-active,
.card-item-active:hover {
  background: var(--gray);
}
::v-deep .theme--light.v-list-item:hover::before {
  opacity: 0;
}
</style>
