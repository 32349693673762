<template>
  <v-dialog v-model="transferGameDialog" maxWidth="500" persistent>
    <v-form ref="form" @submit.prevent style="position: relative">
      <v-card class="py-10 d-flex flex-column align-center">
        <h1>{{ $t('games.transfer') }}</h1>
        <v-btn
          icon
          color="primary"
          style="position: absolute; top: 10px; left: 10px"
          @click="transferGameDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-text-field
          :rules="rules"
          v-model="email"
          outlined
          class="mt-8"
          ref="emailInput"
        ></v-text-field>

        <div class="d-flex mt-5" style="gap: 20px">
          <v-btn @click="transferGame" color="primary">
            {{ $t('generics.ok') }}
          </v-btn>
          <v-btn @click="transferGameDialog = false" color="secondary">
            {{ $t('generics.cancel') }}
          </v-btn>
        </div>
      </v-card></v-form
    >
  </v-dialog>
</template>

<script>
export default {
  name: 'TransferGame',
  props: { value: [Boolean, String] },
  data: () => ({
    email: '',
    badEmail: [],
  }),
  computed: {
    transferGameDialog: {
      get() {
        return this.value
      },
      set(newValue) {
        this.$emit('input', newValue)
      },
    },
    rules() {
      return [
        v => !!v || this.$t('errors.validate.required'),
        v => /.+@.+\..+/.test(v) || this.$t('errors.auth.email'),
        v => !this.badEmail.includes(v) || this.$t('errors.userNotFound'),
      ]
    },
  },
  methods: {
    async transferGame() {
      if (this.$refs.form.validate()) {
        // const res =
        const res = await this.$store.dispatch('game/transferGame', {
          gameId: this.value,
          email: this.email,
        })
        const noUserMessage = 'User not found'
        if (!res.success && res.message === noUserMessage) {
          this.badEmail.push(this.email)
          this.$refs.form.validate()
        }
        if (res.success) {
          this.transferGameDialog = false
        }
      }
    },

    onEnter(event) {
      if (event.key === 'Enter') {
        this.transferGame()
      }
    },
    onEsc(e) {
      if (e.keyCode !== 27) return
      this.transferGameDialog = false
    },
  },
  mounted() {
    this.$refs.emailInput.focus()
    window.addEventListener('keyup', this.onEnter)
    window.addEventListener('keyup', this.onEsc)
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.onEnter)
    window.removeEventListener('keyup', this.onEsc)
  },
}
</script>

<style scoped></style>
