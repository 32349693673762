import { render, staticRenderFns } from "./GameNavbar.vue?vue&type=template&id=c9f2d4aa&scoped=true"
import script from "./GameNavbar.vue?vue&type=script&lang=js"
export * from "./GameNavbar.vue?vue&type=script&lang=js"
import style0 from "./GameNavbar.vue?vue&type=style&index=0&id=c9f2d4aa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c9f2d4aa",
  null
  
)

export default component.exports