<template>
  <div>
    <v-row v-if="btnText && format" dense>
      <v-col>
        <div>{{ btnText }}:</div>
      </v-col>
    </v-row>
    <v-row v-if="format" dense>
      <v-col>
        <iframe
          v-if="format === 'youtube'"
          width="100"
          height="100"
          :src="src"
          frameborder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
        <cld-video
          v-else-if="format === 'mp4'"
          class="center-all"
          :publicId="src"
          :width="400"
          crop="scale"
        />
        <play-audio-cloudinary :publicId="src" v-else-if="format === 'mp3'" />
        <cld-image
          v-else
          class="center-all"
          :publicId="src"
          :width="100"
          crop="scale"
        />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <v-speed-dial v-model="isOpen" v-if="!src">
          <template v-slot:activator>
            <v-btn v-model="isOpen" color="green" dark>
              <v-icon v-if="isOpen"> mdi-close </v-icon>
              <span v-else>
                {{ btnText }}
                <v-icon> mdi-cloud-upload </v-icon>
              </span>
            </v-btn>
          </template>
          <v-btn @click="$refs['cloud-btn'].uploadFiles()" color="green" dark>
            {{ $t('generics.mediaUpload') }}
            <v-icon class="pr-2"> mdi-cloud-upload </v-icon>
          </v-btn>
          <v-btn @click="openModelYoutube = true" color="green" dark>
            {{ $t('generics.youtubeUpload') }}
            <v-icon class="pr-2"> mdi-youtube </v-icon>
          </v-btn>
        </v-speed-dial>
        <div v-else>
          <!-- class="ma-0" -->
          <v-btn dark small color="error" @click="deleteImageFromCloud">
            {{ $t('generics.remove') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <cloudinary
      :format="format"
      :publicId="src"
      :deleteText="$t('generics.remove')"
      :buttonText="$t('generics.mediaUpload')"
      @saveAndUpdate="saveAndUpdate"
      @save="save"
      ref="cloud-btn"
      @loading="loading"
      v-show="false"
    />
    <youtube-upload
      v-model="openModelYoutube"
      @saveAndUpdate="saveAndUpdate"
      @save="save"
    />
  </div>
</template>

<script>
import Cloudinary from './Cloudinary.vue'
import YoutubeUpload from './YoutubeUpload.vue'
import SwalMixins from '@/mixins/swal'
import PlayAudioCloudinary from '../PlayAudioCloudinary.vue'

export default {
  name: 'select-upload-format',
  props: {
    cloudinaryBtnText: String,
    btnText: String,
    format: String,
    src: String,
  },
  components: {
    Cloudinary,
    YoutubeUpload,
    PlayAudioCloudinary,
  },
  mixins: [SwalMixins],
  data() {
    return {
      isOpen: false,
      openModelYoutube: false,
    }
  },
  computed: {
    isEmptyMedia() {
      return this.format
    },
    folderName() {
      return `${this.$store.getters.game.cloudinaryFolder}/assets`
    },
  },
  methods: {
    loading(v) {
      this.$emit('loading', v)
    },
    openCloud() {
      this.$refs['cloud-btn'].uploadFiles()
    },
    save(v) {
      this.$emit('save', v)
    },
    saveAndUpdate() {
      this.$emit('saveAndUpdate')
    },
    async deleteImageFromCloud() {
      try {
        this.$emit('save', { public_id: '', format: '' })
        this.$emit('saveAndUpdate')
        this.timeoutSwal({ icon: 'success', title: this.$t('images.delete') })
      } catch (e) {
        console.log(e)
        this.timeoutSwal({
          icon: 'error',
          title: this.$t('images.deleteError'),
        })
      }
    },
  },
}
</script>

<style></style>
