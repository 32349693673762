<template>
  <div>
    <StyleDialog
      :title="''"
      v-model="openModal"
      :minHeight="''"
      :maxWidth="600"
    >
      <template v-slot:content>
        <v-card-text
          v-if="!isProgress"
          fluid
          class="center-all-col"
          :class="
            isDrag && !fileMetaData.url ? 'border-solid' : 'border-dashed'
          "
          @drop.prevent="addFile"
          @dragover.prevent="isDrag = true"
          @dragleave.prevent="isDrag = false"
          @dragend.prevent="isDrag = false"
        >
          <div
            v-show="!fileMetaData.url"
            class="text-center"
            style="width: 100%"
          >
            <MyIconImage
              class="test"
              name="uploadGreen"
              type="svg"
              :moreStyle="{ maxWidth: '130px', width: '130px', color: 'green' }"
            />
            <div class="text-center my-8">
              <div class="text-center">
                {{ $t('גרור לפה את הקובץ') }}
              </div>
            </div>
            <BtnStyle depressed @click="$refs.uploader.click()" />
          </div>
          <div v-if="fileMetaData.url">
            <v-img
              v-if="fileMetaData.type === 'image'"
              :src="fileMetaData.url"
              max-width="200"
              max-height="200"
            />
            <PlayVideo
              v-if="fileMetaData.type === 'video'"
              :src="fileMetaData.url"
            />
            <PlayAudio
              v-if="fileMetaData.type === 'audio'"
              :src="fileMetaData.url"
            />
          </div>
        </v-card-text>
        <v-card-text v-else class="center-all">
          <MyIconImage maxWidth="300" name="logo-anim" type="gif" />
        </v-card-text>
      </template>
      <template v-slot:actions>
        <div class="center-all-col">
          <div v-if="file && !isProgress">
            <p class="primary--text text-center">
              {{ file.name }}
              <v-btn
                :color="'error'"
                class="text-none"
                depressed
                text
                @click="removeFile"
              >
                {{ buttonRemoveText }}
                <v-icon
                  v-if="buttonRemoveIcon"
                  :right="buttonRemoveText ? true : false"
                  aria-hidden="true"
                >
                  {{ buttonRemoveIcon }}
                </v-icon>
              </v-btn>
            </p>
            <p class="text-center">
              <b>{{ fileMetaData.size }}</b> מ"ב
            </p>
          </div>
          <input
            :accept="acceptFileTypes"
            ref="uploader"
            class="d-none"
            type="file"
            @change="addFile"
          />
          <BtnStyle
            v-if="file && !isProgress"
            @click="uploadFile"
            class="my-4"
            depressed
            borderColor="green"
            btnText="generics.uploadFile"
          />
          <div v-if="isProgress">
            <v-progress-circular
              :rotate="360"
              size="70"
              :value="progress"
              color="azure"
            >
              {{ Math.ceil(progress) }}%
            </v-progress-circular>
          </div>
          <div v-else class="text-center pink--text">
            <div class="text-center">
              {{ $t('errors.formatMessage', {}) }}
              {{ formatsToString }}
            </div>
          </div>
        </div>
      </template>
    </StyleDialog>
  </div>
</template>
<script>
import swalMixins from '@/mixins/swal'
import BtnStyle from './buttons/BtnStyle.vue'
import MyIconImage from './MyIconImage.vue'
import PlayAudio from './PlayAudio.vue'
import PlayVideo from './PlayVideo.vue'
import StyleDialog from './StyleDialog.vue'
export default {
  components: {
    StyleDialog,
    MyIconImage,
    BtnStyle,
    PlayAudio,
    PlayVideo,
  },
  name: 'UploadFileCloud',
  mixins: [swalMixins],
  props: {
    value: Boolean,
    uploadLimit: { type: Number, default: 30 },
    folder: { type: String, default: '' },
    buttonRemoveIcon: { type: String, default: 'mdi-close-thick' },
    buttonRemoveText: { type: String, default: '' },
    onlyImage: { type: Boolean, default: false },
    onlySound: { type: Boolean, default: false },
    onlyVideo: { type: Boolean, default: false },
  },
  data() {
    return {
      isProgress: false,
      progress: 0,
      isDrag: false,
      files: [],
      fileName: null,
      file: null,
      type: null,
      format: null,
      fileTypes: [],
      formatApproved: [],
    }
  },
  computed: {
    acceptFileTypes() {
      return this.fileTypes.length
        ? this.fileTypes
        : ['audio/mp3', 'video/mp4', 'image/jpeg', 'image/jpeg', 'image/png']
    },
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
    uploadPreset() {
      return this.$store.getters.uploadPreset
    },
    folderName() {
      return `${
        this.folder ? this.folder : this.$store.getters.userDetails.email
      }/assets`
    },
    formatsToString() {
      return this.formatApproved.join('/')
    },
    fileMetaData() {
      if (!this.file) return {}
      const [type, format] = this.file.type.split('/')
      return {
        size: (this.file.size / 1000000).toFixed(2),
        type,
        format,
        url: URL.createObjectURL(this.file),
      }
    },
  },

  methods: {
    formatter() {
      let formats = []
      const imageFormats = ['jpg', 'jpeg', 'png']
      const soundFormat = ['mp3', 'mpeg']
      const videoFormat = ['mp4']
      if (this.onlyVideo) {
        formats = formats.concat(videoFormat)
        this.fileTypes.push('video/mp4')
      }
      if (this.onlySound) {
        formats = formats.concat(soundFormat)
        this.fileTypes.push('audio/mp3')
      }
      if (this.onlyImage) {
        formats = formats.concat(imageFormats)
        this.fileTypes.push(...imageFormats.map(f => `image/${f}`))
      }
      if (!this.onlyVideo && !this.onlySound && !this.onlyImage) {
        formats = formats.concat(videoFormat, soundFormat, imageFormats)
      }
      this.formatApproved = formats
    },
    validator() {
      if (!this.formatApproved.includes(this.fileMetaData.format)) {
        this.formatErrorHandler()
        return false
      }
      if (+this.fileMetaData.size > 30) {
        this.limitSizeErrorHandler()
        return false
      }
      return true
    },
    addFile(e) {
      let files = e.dataTransfer ? e.dataTransfer.files : e.target.files
      if (!files || !files[0]) return
      this.fileName = this.findName(files[0].name)
      if (this.fileName.includes('~')) {
        this.timeoutSwal({
          title: this.$t('errors.tildeUpload'),
          icon: 'error',
        })
        return
      }
      this.file = files[0]
      this.uploadFile()
    },
    removeFile() {
      this.files = []
      this.file = null
      this.fileName = null
    },
    limitSizeErrorHandler() {
      this.timeoutSwal({
        title: `העלת קובץ גדול מדי בגודל של: ${this.fileMetaData.size} מ"ב `,
        icon: 'error',
        timer: 2500,
      })
    },
    findName(filename) {
      const regExpLastDot = /\.(?=[^\\.]+$)/
      const a = filename.split(regExpLastDot)
      return a[0] || filename
    },
    formatErrorHandler() {
      this.timeoutSwal({
        title:
          this.$t('errors.format') +
          ' ' +
          this.formatsToString +
          ' את/ה העלת פורמט ' +
          this.fileMetaData.format,
        icon: 'error',
        timer: 2500,
      })
    },
    async uploadFile() {
      if (!this.validator()) return
      try {
        this.isProgress = true
        const result = await this.$cloudUpload({
          file: this.file,
          type: this.fileMetaData.type,
          formatsAllowed: this.formatApproved,
          folder: this.folderName,
          _this: this,
          errorHandler: this.formatErrorHandler,
        })
        if (!result) return
        const { public_id, secure_url, resource_type, format } = result
        const media = {
          ans: `${public_id}.${format}`,
          name: this.fileName,
          url: secure_url,
          src: public_id,
          public_id,
          format: format,
          type: resource_type,
        }
        this.$emit('save', media)
        this.$emit('saveAndUpdate')
        this.$emit('change', media)
        this.openModal = false
      } catch (e) {
        console.log(e)
      }
      this.isProgress = false
    },
  },
  mounted() {
    this.formatter()
  },
}
</script>
<style lang="scss" scoped>
.border-dashed {
  border: 1px dashed var(--green);
  border-radius: 20px;
}
.border-solid {
  border: 1px solid var(--green);
  border-radius: 20px;
}
.dragBox {
  width: 150px;
}
.cloud-btn {
  background: white;
  border: 2px solid;
  justify-content: space-between !important;
}
</style>
