<template>
  <v-row class="text-center" dir="ltr" v-if="!isFirstRender">
    <v-col>
      <v-list class="scrollbar text-center overflow-x-hidden" max-height="50vh">
        <template>
          <v-list-item dir="rtl">
            <v-list-item-content>
              <v-list-item-subtitle>
                <v-textarea
                  v-model="form.titleThroughoutGame"
                  auto-grow
                  color="deep-purple"
                  :label="$t('setting.textAllGame')"
                  rows="1"
                  @change="save"
                ></v-textarea>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>

        <template>
          <v-list-item dir="rtl">
            <v-list-item-content>
              <v-list-item-title v-text="$t('setting.addYourLogo')" />
              <v-list-item-subtitle class="mt-6 pb-4">
                <div>
                  <BtnStyle
                    v-if="!form.logo.src"
                    @click="openMediaDialog = true"
                    btnText="setting.logo"
                    :borderColor="'azure'"
                    :disabled="openMediaDialog"
                  />
                  <div
                    v-else
                    style="width: 100%; position: relative"
                    class="center-all-col"
                  >
                    <v-card class="mx-6 border-radius">
                      <v-img
                        class="border-radius"
                        :src="`https://res.cloudinary.com/${cloudName}/image/upload/${form.logo.src}`"
                        width="140px"
                        height="79px"
                        aspect-ratio="1"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <div>
                              <MyIconImage
                                maxWidth="100"
                                name="logo-anim"
                                type="gif"
                                :moreStyle="{ maxHeight: '50px' }"
                              />
                            </div>
                          </v-row>
                        </template>
                      </v-img>
                    </v-card>
                    <div class="trash d-flex justify-center align-center">
                      <v-btn
                        fab
                        style="padding: 13px !important"
                        class="trash-btn"
                        @click="removeMedia"
                      >
                        <MyIconImage
                          name="trash"
                          :maxWidth="11"
                          :moreStyle="{ zIndex: 10 }"
                        />
                      </v-btn>
                    </div>
                  </div>
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <p class="pink--text pt-3 my-font-size auto-new-line">
                  {{ $t('errors.attention') }}!
                  {{ $t('errors.logo') }}
                </p>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>
        <template v-for="(name, index) in mediaList">
          <v-list-item :key="name + index">
            <v-list-item-content>
              <v-list-item-title
                v-text="$t(`setting.${name}`)"
              ></v-list-item-title>
              <v-list-item-subtitle>
                <MyCarousel
                  type="videosAndImages"
                  v-model="form[name]"
                  :defaultFor="name"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="'divider' + name" />
        </template>

        <template>
          <v-list-item dir="rtl">
            <v-list-item-content>
              <v-list-item-title v-text="$t('setting.yourColors')" />
              <v-list-item-subtitle
                class="center-all justify-space-around mt-3"
              >
                <div class="pink--text pt-3 my-font-size">
                  {{ $t('errors.attention') }}!
                </div>
              </v-list-item-subtitle>
              <v-list-item-subtitle class="center-all justify-space-around">
                <p class="pink--text pt-3 my-font-size">
                  {{ $t('errors.colors') }}
                </p>
              </v-list-item-subtitle>
              <v-list-item-subtitle
                class="center-all justify-space-around mt-3"
              >
                <my-color-picker
                  v-model="form.mainColor"
                  :label="$t('games.setting.mainColor')"
                  @input="save"
                />

                <my-color-picker
                  v-model="form.secondaryColor"
                  :label="$t('games.setting.secondaryColor')"
                  @input="save"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <!-- <v-divider /> -->
        </template>
      </v-list>
    </v-col>
    <UploadFileCloud
      v-if="openMediaDialog"
      v-model="openMediaDialog"
      @save="saveMedia"
      onlyImage
    />
  </v-row>
</template>
<script>
import MyColorPicker from '../MyColorPicker.vue'
import MyIconImage from '../MyIconImage.vue'
import MyCarousel from '../MyCarousel.vue'
import UploadFileCloud from '../UploadFileCloud.vue'
import BtnStyle from '../buttons/BtnStyle.vue'
import swalMixins from '@/mixins/swal'

export default {
  name: 'StyleSetting',
  components: {
    MyColorPicker,
    MyIconImage,
    MyCarousel,
    UploadFileCloud,
    BtnStyle,
  },
  mixins: [swalMixins],
  props: { value: Object },
  computed: {
    game() {
      return this.$store.getters.game
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
  },
  data() {
    return {
      openMediaDialog: false,
      isFirstRender: true,
      form: {
        titleThroughoutGame: '',
        mainColor: '#ffffff70',
        secondaryColor: '#00000070',
        gameMedia: {
          src: '',
          format: '',
        },
        logo: {
          src: '',
          format: '',
        },
        triviaMedia: {
          src: '',
          format: '',
        },
        winnersListMedia: {
          src: '',
          format: '',
        },
        winnersMedia: {
          src: '',
          format: '',
        },
      },
      mediaList: [
        'gameMedia',
        'triviaMedia',
        'winnersListMedia',
        'winnersMedia',
      ],
    }
  },
  methods: {
    save({ showTost = true }) {
      this.$save(this, 'game/update', {
        ...this.game,
        setting: { ...this.form },
      })
      if (!showTost) return
      this.successToast({
        title: this.$t('generics.changesSaved'),
      })
    },
    saveMedia(value) {
      this.form.logo = value
    },
    removeMedia() {
      for (const key in this.form.logo) {
        if (key !== '_id') {
          this.form.logo[key] = ''
        }
      }
    },
  },
  mounted() {
    this.form = { ...this.form, ...this.game.setting }
    this.isFirstRender = false
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (!this.isFirstRender) {
          this.save({ showTost: false })
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border: 2px solid;
  background-color: var(--blueLogo) !important;
}
::v-deep .cancel-btn {
  border-color: var(--pink) !important;
}
::v-deep .save-btn {
  border-color: var(--green) !important;
}
::v-deep .my-btn {
  width: 150px;
  justify-content: space-evenly !important;
}
.trash {
  position: absolute;
  width: 100%;
  bottom: -10px;
}
.trash-btn {
  width: 23px;
  height: 23px;
}
.border-radius {
  border-radius: 20px;
}
::v-deep .my-font-size {
  font-size: 0.79rem;
}
</style>
