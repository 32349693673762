<template>
  <div>
    <v-dialog v-model="openModal" max-width="1100" persistent>
      <v-card class="dialog-card">
        <v-btn
          class="close-icon"
          icon
          @click="openModal = false"
          color="blueLogo"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-if="page === 'menu'">
          <my-icon-image
            name="eyes"
            type="svg"
            class="icon-image"
            maxWidth="120" />
          <h1 class="blueLogo--text mt-3">איך תרצו להפעיל את המשחק?</h1>
          <div class="options-container mt-15">
            <computer-option
              v-for="option in computerOptions"
              :key="option.title"
              :title="option.title"
              :iconName="option.iconName"
              :iconSize="option.iconSize"
              :hoverColor="option.hoverColor"
              :click="option.click"
            /></div
        ></template>
        <card-payment-component
          v-if="page === 'payment'"
          :paymentCards="paymentCardsTypes"
        />
        <template v-if="page === 'iframe'">
          <iframe style="width: 100%; height: 100%" :src="iframeSrc"></iframe>
        </template>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MyIconImage from '../../MyIconImage.vue'
import ComputerOption from './ComputerOption.vue'
import CardPaymentComponent from './CardPaymentComponent.vue'
export default {
  name: 'out-to-pay-handler',
  props: {
    value: Boolean,
  },
  components: {
    MyIconImage,
    ComputerOption,
    CardPaymentComponent,
  },
  data: () => ({
    iframeSrc: '',
    page: 'menu',
    payCardsPhones: [
      {
        image: 'people10',
        title: 'עד',
        number: '10',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[0]
          this.goToPay()
        },
      },
      {
        image: 'people10',
        title: 'עד',
        number: '30',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[6]
          this.goToPay()
        },
      },
      {
        image: 'people50',
        title: 'עד',
        number: '50',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[1]
          this.goToPay()
        },
      },
      {
        image: 'people100',
        title: 'עד',
        number: '100',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[2]
          this.goToPay()
        },
      },
      {
        image: 'people200',
        title: 'עד',
        number: '200',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[3]
          this.goToPay()
        },
      },
      {
        image: 'people300',
        title: 'עד',
        number: '300',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[4]
          this.goToPay()
        },
      },
      {
        image: 'people500',
        title: 'עד',
        number: '500',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListPhones[5]
          this.goToPay()
        },
      },
      {
        image: 'questionMark',
        title: 'למספר',
        number: 'מותאם  אישית',
        text: `של משתתפים
        יש ליצור קשר`,
        click: function () {
          window.open('https://clicker.co.il/cellphones/#KESHER', '_blank')
        },
      },
    ],
    payCardsClickers: [
      {
        image: 'people10',
        title: 'עד',
        number: '50',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[0]
          this.goToPay()
        },
      },
      {
        image: 'people50',
        title: 'עד',
        number: '100',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[1]
          this.goToPay()
        },
      },
      {
        image: 'people100',
        title: 'עד',
        number: '150',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[2]
          this.goToPay()
        },
      },
      {
        image: 'people200',
        title: 'עד',
        number: '200',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[3]
          this.goToPay()
        },
      },
      {
        image: 'people300',
        title: 'עד',
        number: '250',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[4]
          this.goToPay()
        },
      },
      {
        image: 'people500',
        title: 'עד',
        number: '300',
        text: 'משתתפים',
        click: function () {
          this.radioGroupUrl = this.payListClickers[5]
          this.goToPay()
        },
      },
    ],
    computerOptions: [
      {
        title: 'הפעלה עצמית בקליקרים',
        iconName: 'clicker',
        iconSize: 100,
        hoverColor: 'green',
        click() {
          this.page = 'iframe'
          this.iframeSrc = `https://clicker.co.il/%d7%98%d7%95%d7%a4%d7%a1-%d7%94%d7%96%d7%9e%d7%a0%d7%aa-%d7%94%d7%a4%d7%a2%d7%9c%d7%94-%d7%a2%d7%a6%d7%9e%d7%99%d7%aa-%d7%91%d7%a7%d7%9c%d7%99%d7%a7%d7%a8%d7%99%d7%9d/`
        },
      },
      {
        title: 'הפעלה אונליין ובטלפונים כשרים',
        iconName: 'phone',
        iconSize: 75,
        hoverColor: 'pink',
        click: function () {
          this.paymentCardsTypes = this.payCardsPhones
          this.page = 'payment'
        },
      },
      {
        title: 'הורדת קובץ משחק לספקים מורשים',
        iconName: 'downloadIcon',
        hoverColor: 'azure',
        iconSize: 140,
        click: function () {
          this.paymentCardsTypes = this.payCardsClickers
          this.page = 'payment'
        },
      },
    ],
    hovered: false,
    baseUrl: 'https://pay.sumit.co.il/1t6qve/',
    err: '',
    radioGroupUrl: {
      limitCode: 'p10',
      label: 'עד 10',
      link: '94gf12/3866f7',
    },
    payListPhones: [
      {
        limitCode: 'p10',
        label: 'עד 10',
        link: '94gf12/3866f7',
      },
      {
        limitCode: 'p1',
        label: 'עד 50',
        link: '94gf12/1y0797',
      },
      {
        limitCode: 'p2',
        label: 'עד 100',
        link: '94gf12/1ybijy',
      },
      {
        limitCode: 'p3',
        label: 'עד 200',
        link: '94gf12/1yidjm',
      },
      {
        limitCode: 'p4',
        label: 'עד 300',
        link: '94gf12/1yifsa',
      },
      {
        limitCode: 'p5',
        label: 'עד 500',
        link: '94gf12/1yidk7',
      },
      {
        limitCode: 'p6',
        label: 'עד 30',
        link: '94gf12/5t67ys',
      },
    ],
    payListClickers: [
      {
        limitCode: 'c1',
        label: 'עד 50',
        link: '1y078z/1yidka',
      },
      {
        limitCode: 'c2',
        label: 'עד 100',
        link: '1y078z/1yidli',
      },
      {
        limitCode: 'c3',
        label: 'עד 150',
        link: '1y078z/1yidlk',
      },
      {
        limitCode: 'c4',
        label: 'עד 200',
        link: '1y078z/1yidlu',
      },
      {
        limitCode: 'c5',
        label: 'עד 250',
        link: '1y078z/1yidzo',
      },
      {
        limitCode: 'c6',
        label: 'עד 300',
        link: '1y078z/1yienb',
      },
    ],
  }),
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    gameId() {
      return this.$store.getters.game._id
    },
    uid() {
      return this.$store.getters.uid
    },
    email() {
      return this.$store.getters?.userDetails?.email
    },
    fullName() {
      return this.$store.getters?.userDetails?.fullname
    },
  },
  methods: {
    goToPay() {
      window.location.href = `${this.baseUrl}${this.radioGroupUrl.link}/payment/?externalidentifier=${this.uid}:${this.gameId}:${this.radioGroupUrl.limitCode}&EmailAddress=${this.email}&name=${this.fullName}`
    },
  },
  mounted() {
    this.computerOptions.forEach(option => {
      option.click = option.click.bind(this)
    })
    this.payCardsClickers.forEach(option => {
      option.click = option.click.bind(this)
    })
    this.payCardsPhones.forEach(option => {
      option.click = option.click.bind(this)
    })
  },
}
</script>
<style scoped>
.dialog-card {
  background-color: #d9d7d7 !important;
  height: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.close-icon {
  position: absolute;
  top: 0;
  left: 0;
  margin: 10px;
}
.icon-image {
  margin-top: 80px;
}

.title {
  color: #212b46;
  margin-top: 10px;
  font-size: 2rem !important;
}

.options-container {
  display: flex;
  gap: 80px;
  text-align: center;
}
</style>
