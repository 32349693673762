<template>
  <v-app-bar
    :style="style"
    flat
    color="white"
    id="app-bar"
    class="app-bar mx-auto white"
  >
    <v-row>
      <v-col cols="8" class="d-flex justify-center align-center">
        <v-toolbar-title
          class="text-h4 justify-self-start margin-right-responsive"
          v-if="$store.getters.game"
          v-text="$store.getters.game.name"
        />
      </v-col>
      <v-col cols="4" class="d-flex justify-end align-center" style="gap: 8px"
        ><v-btn
          outlined
          rounded
          @click="goToLearn"
          elevation="8"
          style="background-color: #212b46; color: white; border: none"
        >
          <MyIconImage name="question" maxWidth="25" />
          <span class="mx-2 font-weight-2">
            {{ $t('route.learn') }}
          </span>
        </v-btn>
        <v-btn
          class="my-game-btn my-btn"
          outlined
          rounded
          :to="{ name: 'MyGame' }"
          elevation="8"
          color="green"
        >
          <MyIconImage type="svg" name="game" maxWidth="30" />
          <span class="mx-2 font-weight-2">
            {{ $t('route.MyGame') }}
          </span>
        </v-btn>
        <div class="center-all-col mouse-pointer ms-4" @click="logout">
          <MyIconImage type="svg" name="logout" maxWidth="30" />
          <span class="logout font-weight-1">
            {{ $t('auth.disconnection') }}
          </span>
        </div>
      </v-col>
    </v-row>
    <!-- <template v-if="tabs" v-slot:extension>
      <v-tabs centered>
        <v-tab>Tab 1</v-tab>
        <v-tab>Tab 2</v-tab>
        <v-tab>Tab 3</v-tab>
      </v-tabs>
    </template> -->
  </v-app-bar>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import MyIconImage from '../MyIconImage.vue'

export default {
  name: 'GameNavbar',
  mixins: [SwalMixins],
  data() {
    return {
      tabs: false,
      userDialog: false,
      style: {
        boxShadow:
          ' 0px 0px 0px 0px rgb(0 0 0 / 0%),0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 4px 5px 0px rgb(0 0 0 / 12%) !important',
      },
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.adminLoggedIn
    },
  },
  methods: {
    goToLearn() {
      window.open('https://clicker.tawk.help/ ', '_blank')
    },
    goHomePage() {
      this.$router.push({
        name: this.isAdmin ? 'AdminDashboard' : 'UserDashboard',
      })
    },
    async logout() {
      let { isConfirmed } = await this.createSwal({
        title: `${this.$t('auth.logout')}?`,
        icon: 'question',
        confirmColor: 'red',
      })
      if (isConfirmed) await this.$store.dispatch('auth/logout')
    },
  },
  components: { MyIconImage },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border-radius: 50px;
  justify-content: space-evenly !important;
  font-size: small;
}
.my-game-btn {
  border: 3px solid var(--gray);
  background-color: var(--white) !important;
}
.logout {
  font-size: x-small;
  color: var(--pink);
}
@media (min-width: 1400px) {
  .margin-right-responsive {
    padding-right: 17vw !important;
  }
}
</style>
