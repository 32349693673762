<template>
  <video
    @canplaythrough="$emit('load')"
    controls
    controlsList="nodownload"
    v-on="$listeners"
    v-bind="$attrs"
    :style="{ maxWidth: maxWidth }"
  >
    <source preload="auto" :src="src" type="video/mp4" />
    <!-- type="audio/ogg"
      type="audio/mpeg"
      type="audio/wav" -->
    <!-- Your browser does not support the audio element. -->
    הדפדפן שלך אינו תומך ברכיב הוידאו
  </video>
</template>
<script>
export default {
  name: 'play-video',
  props: {
    src: String,
    maxWidth: { type: String, default: '236px' },
  },
}
</script>
