<template>
  <div style="position: relative">
    <div style="height: 7vh; position: relative; background: white">
      <div style="position: absolute; top: -70px; right: 20%">
        <v-img src="/assets/images/logo.svg" height="150px" width="150px" />
      </div>
    </div>
    <template>
      <v-list style="height: 68vh">
        <v-list-group
          v-for="(item, i) in drawer"
          :key="i"
          :group="item.name"
          :class="`${
            i !== 0 ? 'border-bottom' : ''
          }  border-top open-menu-list`"
          v-model="item.isActive"
        >
          <template v-slot:prependIcon>
            <div class="center-all">
              <img
                style="max-width: 20px"
                :src="`/assets/icons/${item.icon}.png`"
              />
            </div>
          </template>
          <template v-slot:activator>
            <v-list-item-title style="font-size: 0.7vw">{{
              item.name
            }}</v-list-item-title>
          </template>

          <GameSetting
            v-model="game"
            v-if="item.icon === 'setting'"
            @closeGrope="closeGrope(item.icon)"
          />
          <StyleSetting
            v-model="game"
            v-if="item.icon === 'style'"
            @closeGrope="closeGrope(item.icon)"
          />
          <AdvancedSettings
            v-model="game"
            v-if="item.icon === 'advancedSettings'"
            @closeGrope="closeGrope(item.icon)"
          />
        </v-list-group>
      </v-list>
    </template>
    <v-list class="my-btn-list">
      <v-divider class="mx-14" />
      <v-list-item class="d-flex justify-center my-2">
        <v-btn
          class="users-groups-btn my-btn"
          outlined
          rounded
          @click="showUploadUsers = true"
        >
          <MyIconImage type="svg" name="people-group" maxWidth="23" />
          <span class="my-btn-text mx-2 font-weight-3">
            {{ $t('משתתפים וקבוצות') }}
          </span>
        </v-btn>
      </v-list-item>
      <v-list-item class="d-flex justify-center my-2">
        <v-btn
          class="game-result-btn my-btn"
          outlined
          rounded
          @click="showGameResults = true"
        >
          <MyIconImage type="svg" name="statistics" maxWidth="23" />
          <span class="my-btn-text mx-2 font-weight-3">
            {{ $t('games.gamesResults') }}
          </span>
        </v-btn>
      </v-list-item>
    </v-list>
    <GameResults v-model="showGameResults" v-if="showGameResults" />
    <UploadUsers v-model="showUploadUsers" v-if="showUploadUsers" />
  </div>
</template>
<script>
import MyIconImage from '../MyIconImage.vue'
import AdvancedSettings from './AdvancedSettings.vue'
import GameSetting from './GameSetting.vue'
import StyleSetting from './StyleSetting.vue'
import GameResults from '@/components/dialogs/GameResults.vue'
import UploadUsers from '@/components/dialogs/UploadUsers.vue'

export default {
  name: 'SidBarGame',
  components: {
    StyleSetting,
    GameSetting,
    AdvancedSettings,
    MyIconImage,
    GameResults,
    UploadUsers,
  },
  props: {
    value: { type: Object, default: () => ({}) },
  },
  computed: {
    game: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  data() {
    return {
      showUploadUsers: false,
      showGameResults: false,
      panel: [],
      disabled: false,
      drawer: [
        {
          name: this.$t('drawer.setting'),
          icon: 'setting',
          isActive: false,
        },
        {
          name: this.$t('drawer.style'),
          icon: 'style',
          isActive: false,
        },
        {
          name: this.$t('drawer.advancedSettings'),
          icon: 'advancedSettings',
          isActive: false,
        },
      ],
      model: null,
    }
  },
  methods: {
    closeGrope(icon) {
      this.drawer.find(i => i.icon === icon).isActive = false
    },
    goUrl() {
      window.location.href = 'https://clicker.co.il/'
      // window.open('https://clicker.co.il/', "_blank");
    },
  },
}
</script>
<style lang="scss" scoped>
//TODO: change this if the platform needs to support RTL
.open-menu-list ::v-deep .v-list-item__icon.v-list-group__header__append-icon {
  margin-right: 0 !important;
  min-width: unset !important;
}
.open-menu-list ::v-deep .v-list-item__icon.v-list-group__header__prepend-icon {
  margin-left: 15px !important;
}
v-list-item__icon.v-list-group__header__prepend-icon ::v-deep .v-btn {
  padding: 20px !important;
}
.my-btn-list ::v-deep .v-list-item {
  padding: 0;
}

.my-btn {
  border-radius: 50px;
  justify-content: space-between !important;
  background-color: var(--white) !important;
  margin-top: 15px;
  max-width: 100%;
}
@media (max-width: 1250px) {
  .my-btn ::v-deep .my-icon-image {
    display: none !important;
  }
  .my-btn-text {
    margin-right: 0 !important;
  }
  .my-btn {
    justify-content: center !important;
  }
}
.users-groups-btn {
  border: 3px solid var(--yellow);
}
.game-result-btn {
  border: 3px solid var(--azure);
}
::v-deep .border-top {
  border-top: 1px solid var(--gray);
}
::v-deep .border-bottom {
  border-bottom: 1px solid var(--gray);
}
</style>
