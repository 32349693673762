<template>
  <p class="center-all">
    <validation-provider
      :style="{ position: 'absolute', top: '-13', ...moreStyle }"
      :rules="{
        atLeastOneAnswerApproved: {
          isValid: atLeastOneAnswerApproved,
          skip: !canChooseCorrectAns,
        },
      }"
    >
      <input v-show="false" type="text" v-model="correct" />
      <v-btn
        v-if="(canChooseCorrectAns && hover) || correct"
        v-model="correct"
        x-small
        :color="correct ? 'yellow' : ''"
        rounded
        @click="$emit('click')"
        class="my-btn"
      >
        <MyIconImage name="approved" />
        <span class="ms-1">
          {{ $t('games.correctAns') }}
        </span>
      </v-btn>
    </validation-provider>
  </p>
</template>
<script>
import MyIconImage from '@/components/MyIconImage.vue'
export default {
  name: 'ApprovedAnsBtn',
  components: { MyIconImage },
  props: {
    canChooseCorrectAns: { type: Boolean, default: true },
    hover: Boolean,
    correct: Boolean,
    moreStyle: Object,
    answers: { type: Array, default: () => [] },
  },
  computed: {
    atLeastOneAnswerApproved() {
      if (!this.canChooseCorrectAns) return true
      return !!this.answers?.find(a => a.correct)
    },
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border: 2px solid;
  border-color: var(--yellow);
}
</style>
