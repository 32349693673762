import baseModule from './baseModule'
import axios from 'axios'
class category extends baseModule('category') {}
const vuexData = new category()
console.log(vuexData)
vuexData.actions['category/updateIndex'] = async (
  context,
  { newCategories, oldCategories }
) => {
  try {
    //make a object of all the categories and their ids
    const oldCategoriesIds = {}
    oldCategories.forEach(category => {
      oldCategoriesIds[category._id] = category
    })
    const categoriesToUpdate = newCategories.filter(
      category => oldCategoriesIds[category._id].index !== category.index
    )
    console.log(categoriesToUpdate)
    context.commit('categories/set', newCategories)

    await axios.put('/category/indexes', categoriesToUpdate)
  } catch (e) {
    console.log(e)
  }
}
export default { ...vuexData }
