<template>
  <!-- ans media -->
  <v-row>
    <v-col cols="12" class="center-all">
      <v-card min-width="500" max-width="600" height="400">
        <ViewVideo
          v-if="form.openMedia.format === 'youtube'"
          :src="form.openMedia.src"
          class="mx-6 border-radius"
          width="600"
          height="400"
          type="youtube"
          :moreStyle="{ margin: '0 !important' }"
        />
        <v-img
          v-else-if="format(form.openMedia.format) === 'image'"
          min-width="500"
          height="400"
          :src="src"
        />
        <ViewVideo
          v-else-if="format(form.openMedia.format) === 'video'"
          :src="src"
          width="500"
          height="400"
        />
      </v-card>
    </v-col>
    <v-col class="center-all">
      <validation-provider rules="required">
        <input v-show="false" type="text" v-model="form.openMedia.src" />
        <!-- <BtnStyle @click="openMediaDialog = true" :disabled="openMediaDialog" /> -->
        <SelectUpload
          :format="parserData(form.openMedia).format"
          :src="parserData(form.openMedia).src"
          @openModel="openMediaDialog = true"
          @save="saveMedia"
        >
          <template v-slot:button="{ attrs, on }">
            <BtnStyle
              class="mx-2"
              :disabled="openMediaDialog"
              v-bind="attrs"
              v-on="on"
            />
            <!-- :borderColor="btn.borderColor" -->
            <!-- :btnText="btn.text" -->
          </template>
        </SelectUpload>

        <UploadFileCloud
          v-if="openMediaDialog"
          v-model="openMediaDialog"
          @save="saveMedia"
        />
      </validation-provider>
    </v-col>
  </v-row>
</template>
<script>
import BtnStyle from '../buttons/BtnStyle.vue'
import UploadFileCloud from '../UploadFileCloud.vue'
import ViewVideo from '../ViewVideo.vue'
import SelectUpload from '@/components/buttons/SelectUpload.vue'

export default {
  name: 'AnsMedia',
  components: {
    ViewVideo,
    BtnStyle,
    UploadFileCloud,
    SelectUpload,
  },
  props: {
    value: { type: Object, default: () => ({}) },
  },
  data() {
    return {
      openMediaDialog: false,
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
    src() {
      const media = this.form?.openMedia
      if (!media?.src) return ''
      return `https://res.cloudinary.com/${this.cloudName}/${this.format(
        media.format
      )}/upload/${media.src}`
    },
  },
  methods: {
    saveMedia(value) {
      console.log(value)
      this.form.openMedia = { ...this.form.openMedia, ...value }
      if (!this.isNew) {
        this.$emit('submit')
      }
    },
    parserData(data) {
      return {
        src: data?.src ?? '',
        format: data?.format ?? '',
      }
    },
    format(format) {
      console.log(format)
      return format.includes('mp3') || format.includes('mp4')
        ? 'video'
        : 'image'
    },
  },
}
</script>
<style lang="scss"></style>
