<template>
  <iframe
    v-if="type === 'youtube'"
    ref="reference"
    :width="width"
    :height="height"
    :src="src"
    frameborder="0"
    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen
    :style="myStyle"
  >
  </iframe>
  <video
    v-else
    name="media"
    :width="width"
    :height="height"
    controls
    controlsList="nodownload"
  >
    <slot />
    <source :src="src" type="video/ogg" />
    <source :src="src" type="video/mp4" />
    Your browser does not support the video tag.
  </video>
</template>
<script>
export default {
  name: 'ViewVideo',
  props: {
    type: { type: String, default: '' },
    src: String,
    width: { type: String, default: '140px' },
    height: { type: String, default: '79px' },
    moreStyle: { type: Object, default: () => ({}) },
  },
  computed: {
    myStyle() {
      return {
        '--height': this.height,
        '--width': this.width,
        ...this.moreStyle,
      }
    },
  },
}
</script>
<style lang="scss">
::v-deep video {
  width: var(--width);
  height: var(--height);
}
</style>
