import axios from 'axios'
import router from '../../router/index'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'

export default {
  state: {
    name: null,
    role: null,
    uid: null,
    userDetails: {},
    isLoggedIn: false,
    userDetailsDialog: false,
    userAssets: [],
  },
  getters: {
    userAssets: state => state.userAssets,
    userDetails: state => state.userDetails,
    userDetailsDialog: state => state.userDetailsDialog,
    name: state => state.name,
    isAuth: state => state.isLoggedIn,
    uid: state => state.uid,
    role: state => state.role,
    r0: state => state.role === 0,
    r1: state => state.role === 1,
    r2: state => state.role === 2,
    isAdmin: state => state.role < 2,
  },
  mutations: {
    'auth/login': (state, user) => {
      state.isLoggedIn = true
      state.userDetails = user
      state.name = user.name
      state.role = user.role
      state.uid = user._id
      state.userAssets = user.assets || []
    },
    clearData: state => {
      state.isLoggedIn = false
      state.name = null
      state.role = null
      state.uid = null
      state.userAssets = []
    },
    'auth/userDetailsDialog': (state, payload) =>
      (state.userDetailsDialog = payload),
    'auth/setUserDetails': (state, payload) => (state.userDetails = payload),
    'auth/userAssets/set': (state, payload) => (state.userAssets = payload),
  },
  actions: {
    'auth/register': async (context, payload) => {
      try {
        let { data } = await axios.post('/auth/register', payload)
        context.commit('auth/login', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
        throw new Error(e.response.data)
      }
    },
    'auth/login': async (context, payload) => {
      try {
        let { data } = await axios.post('/auth/login', payload)
        if (data.role === 0 || data.role === 1) {
          context.commit('admin/login', data)
        }
        context.commit('auth/login', data)
      } catch (e) {
        console.log(e)
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
        throw new Error(e.response.data)
      }
    },
    'auth/checkLogin': async context => {
      try {
        let { data } = await axios.post('/auth/checkLogin')
        if (data.role === 0 || data.role === 1) {
          context.commit('admin/login', data)
        }
        context.commit('auth/login', data)
      } catch (e) {
        throw new Error()
      }
    },
    'auth/logout': async context => {
      context.commit('clearData')
      await axios.post('/auth/logout')
      router.push({ name: 'Login' })
    },
    'auth/restorePassword': async (context, payload) => {
      const { data } = await axios.post('/auth/restore-password', payload)
      return data
    },
    'auth/restoreByEmail': async (context, payload) => {
      const { data } = await axios.post('/auth/forget-password', payload)
      return data
    },
  },
}
