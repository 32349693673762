<template>
  <StyleDialog
    v-model="openModal"
    :title="$t('setting.importFromXlHelper')"
    :maxWidth="550"
    :fillHeight="true"
  >
    <template slot="content">
      <v-container class="px-10">
        <ul>
          <li style="white-space: pre" v-for="(item, i) in items" :key="i">
            {{ item }}
          </li>
        </ul>
      </v-container>
    </template>
    <template slot="actions">
      <v-row class="pb-6 ma-0">
        <v-col cols="6" class="center-all">
          <v-btn
            elevation="12"
            class="download-btn"
            @click="downloadXls"
            rounded
          >
            <span class="px-2">
              {{ $t('generics.downloadTemplate') }}
            </span>
            <MyIconImage name="export-cloud" type="svg" maxWidth="23" />
          </v-btn>
        </v-col>
        <v-col cols="6" class="justify-end">
          <v-btn
            elevation="12"
            @click="$emit('openUploadFileOpen')"
            class="upload-btn"
            rounded
          >
            <span class="px-2">
              {{ $t('generics.uploadFile') }}
            </span>
            <MyIconImage name="upload" maxWidth="23" />
          </v-btn>
        </v-col> </v-row
    ></template>
  </StyleDialog>
</template>
<script>
import MyIconImage from '../MyIconImage.vue'
import StyleDialog from '../StyleDialog.vue'

export default {
  name: 'xlsImport',
  components: { StyleDialog, MyIconImage },
  props: { value: Boolean },
  data() {
    return {
      items: [
        'ביבוא מאקסל ניתן להעלות שאלות טריויה וסקר בלבד',
        'יש להכניס את השאלות לאקסל כפי \n הדוגמאות בשאלות הראשונות',
        'אין צורך למלא את כל התשובות',
        'חובה לציין זמן וניקוד',
        'אין לבצע שום שנוי בתבנית הקובץ \n ע”מ שהקובץ יטען בהצלחה',
        'לאחר טעינת הקובץ השאלות יכנסו לרשימת המסכים \n וניתן יהיה לערוך אותם ו/או להוסיף מדיה והגדרות נוספות',
        'אין להסיר עמודות מתבנית הקובץ כלל-גם אם אתם \n משאירים אתם ריקות, \n וכן אין לשנות כלל את שורת הכותרת העליונה',
      ],
      xlsFileName: 'תבנית להכנסת שאלות חויה בקליק 037737970.xlsx',
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    downloadXls() {
      window.open(`/${this.xlsFileName}`, '_blank')
    },
  },
}
</script>
<style lang="scss" scoped>
.upload-btn {
  border: 2px solid var(--pink);
}
.download-btn {
  border: 2px solid var(--green);
}
</style>
