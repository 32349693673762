<template>
  <v-btn
    :class="className"
    :style="styles"
    outlined
    rounded
    v-bind="$attrs"
    v-on="$listeners"
  >
    <span class="me-2 font-weight-regular">
      {{ $t(btnText) }}
    </span>
    <my-icon-image v-if="iconName" :name="iconName" :maxWidth="iconWidth" />
  </v-btn>
</template>
<script>
import MyIconImage from '../MyIconImage.vue'

export default {
  name: 'BtnStyle',
  components: { MyIconImage },
  props: {
    borderColor: { type: String, default: 'azure' },
    borderSize: { type: String, default: '2px' },
    backgroundColor: { type: String, default: 'white' },
    btnText: { type: String, default: 'generics.mediaUpload' },
    iconWidth: { type: String, default: '23' },
    iconName: { type: String, default: 'upload' },
    className: { type: String, default: '' },
    moreStyles: { type: Object, default: () => ({}) },
  },
  computed: {
    styles() {
      return {
        // '--backgroundColor': `var(--${this.backgroundColor})`,
        // '--borderColor': `var(--${this.borderColor})`,
        // '--borderSize': this.borderSize,
        background: `var(--${this.backgroundColor}) !important`,
        border: `${this.borderSize} solid`,
        borderColor: `var(--${this.borderColor}) !important`,
        justifyContent: `space-between !important`,
        ...this.moreStyles,
      }
    },
  },
}
</script>
