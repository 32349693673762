import axios from 'axios'

export default {
  state: {
    setting: {
      cloudName: '',
      uploadPreset: '',
      questionsTypes: [],
      repositoryAssets: [],
      host: '',
      baseUrl: '',
    },
    loading: {
      active: false,
      value: null,
      messages: '',
    },
  },
  getters: {
    uploadPreset: state => state.setting.uploadPreset,
    cloudName: state => state.setting.cloudName,
    questionsTypes: state => state.setting.questionsTypes,
    repositoryAssets: state => state.setting.repositoryAssets,
    host: state => state.setting.host,
    baseUrl: state => state.setting.baseUrl,
    loading: state => state.loading,
  },
  mutations: {
    'setting/setSetting': (state, payload) => (state.setting = payload),
    'loading/active': (state, payload) => {
      if (payload) {
        state.loading.active = payload
      } else {
        state.loading.active = payload
        state.loading.value = null
        state.loading.messages = ''
      }
    },
    'loading/setValue': (state, payload) => (state.loading.value = payload),
    'loading/setMessages': (state, payload) =>
      (state.loading.messages = payload),

    //store one metaData
    'repositoryAssets/store': (state, payload) => {
      state.setting.repositoryAssets.push(payload)
    },
    //destroys one metaData
    'repositoryAssets/destroy': (state, id) =>
      (state.setting.repositoryAssets = state.setting.repositoryAssets.filter(
        item => {
          return item._id !== id
        }
      )),
    //updates one metaData
    'repositoryAssets/update': (state, payload) => {
      state.setting.repositoryAssets = state.setting.repositoryAssets.map(
        item => {
          if (item._id === payload._id) {
            return payload
          }
          return item
        }
      )
    },
  },
  actions: {
    'setting/index': async context => {
      const { data } = await axios.get('/setting')
      context.commit('setting/setSetting', data)
      return data
    },
  },
}
