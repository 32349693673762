<template>
  <v-row no-gutters class="mt-0 ms-14">
    <v-col cols="12" class="buttons-div">
      <v-btn
        outlined
        rounded
        class="import-btn mx-1"
        @click="showQuestionHandler = true"
        elevation="12"
      >
        <my-icon-image class="me-2" name="folder" maxWidth="20" />
        <span>
          {{ $t('questions.addingContentRepository') }}
        </span>
      </v-btn>
      <v-btn
        elevation="12"
        outlined
        rounded
        class="xls-btn"
        @click="xlsHelperOpen = true"
      >
        <my-icon-image class="me-2" name="xlsx" maxWidth="20" />
        <span>
          {{ $t('setting.importFromXl') }}
        </span>
      </v-btn>
    </v-col>
    <v-col cols="12" class="pt-2">
      <v-card elevation="2" class="">
        <v-card-actions class="mx-4 align-center">
          <div class="center-all-col ms-5">
            <span class="text-h6">{{ questionLength }}</span>
            <span class="my-text-small">{{ $tc('generics.slide', 2) }}</span>
          </div>
          <v-text-field v-model="search" class="px-6" dense hide-details />
          <my-icon-image class="me-2" name="search" maxWidth="30" />
        </v-card-actions>
      </v-card>
    </v-col>
    <xls-helper-handler
      v-if="xlsHelperOpen"
      v-model="xlsHelperOpen"
      @handleUpload="importFile"
      @openUploadFileOpen="uploadFileOpen = true"
    />
    <upload-file
      v-if="uploadFileOpen"
      v-model="uploadFileOpen"
      @handleUpload="importFile"
    />
    <import-questions-handler
      :title="$t('questions.setting')"
      v-model="showQuestionHandler"
      v-if="showQuestionHandler"
    />
  </v-row>
</template>
<script>
import UploadFile from '@/components/UploadFile.vue'
import ImportQuestionsHandler from '@/components/handler/ImportQuestionsHandler.vue'
import XlsHelperHandler from '@/components/handler/XlsHelperHandler.vue'
import MyIconImage from '@/components/MyIconImage.vue'

export default {
  name: 'QuestionAction',
  components: {
    UploadFile,
    ImportQuestionsHandler,
    MyIconImage,
    XlsHelperHandler,
  },
  data() {
    return {
      type: '',
      form: {},
      showQuestionHandler: false,
      uploadFileOpen: false,
      xlsHelperOpen: false,
    }
  },
  computed: {
    search: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    questionLength() {
      return this.$store.getters.game?.questions?.length
    },
    game() {
      return this.$store.getters.game
    },
  },
  methods: {
    async importFile(file) {
      this.$store.commit('loading/active', true)
      this.xlsHelperOpen = false
      const isSuccess = await this.$store.dispatch('upload/xlsx', {
        file,
        gameId: this.game._id,
      })
      this.$emit('setIndex')
      if (!isSuccess) this.xlsHelperOpen = true
      this.$store.commit('loading/active', false)
    },
  },
  mounted() {},
}
</script>
<style lang="scss" scoped>
::v-deep .v-card {
  border-radius: 50px;
}
.xls-btn {
  border: 3px solid var(--green);
}
.import-btn {
  border: 3px solid var(--pink);
}
.my-text-small {
  font-size: 0.65rem;
}
.buttons-div {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}
@media (max-width: 1450px) {
  .buttons-div {
    justify-content: center;
  }
}
</style>
