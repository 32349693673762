import axios from 'axios'
export default {
  state: {
    backups: [],
    backupsByGameId: [],
    'backups/filtered': [],
    backup: null,
  },
  getters: {
    backups: state => state.backups,
    backupsByGameId: state => state.backupsByGameId,
    backup: state => state.backup,
    'backups/filtered': state => state['backups/filtered'],
  },
  mutations: {
    'backups/set': (state, payload) => {
      state.backups = payload
      state.filtered = [...state.backups]
    },
    'backupsByGameId/set': (state, payload) => {
      state.backupsByGameId = payload
    },
    'backup/set': (state, payload) => (state.backup = payload),
    'backups/filter': (state, { key, val }) => {
      state['backups/filtered'] = !val
        ? [...state.backups]
        : state['backups'].filter(f => f[key] === val)
    },
    'backups/add': (state, payload) => state.backups.push(payload),
    'backups/destroy': (state, id) =>
      (state.backups = state.backups.filter(item => {
        return item._id !== id
      })),
    'backups/update': (state, payload) => {
      state.backups = state.backups.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    'backups/index': async context => {
      try {
        const { data } = await axios.get('/backup')
        context.commit('backups/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'backup/show': async (context, id) => {
      try {
        let { data } = await axios.get('/backup/' + id)
        context.commit('backup/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'backups/show-by-gameId': async (context, id) => {
      try {
        let { data } = await axios.get('/backup/by-gameId/' + id)
        context.commit('backupsByGameId/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'backup/store': async (context, payload) => {
      try {
        const formData = new FormData()
        for (var key in payload) {
          formData.append(key, payload[key])
        }
        let { data } = await axios.post('/backup', formData)
        context.commit('backups/add', data)
      } catch (e) {
        console.log(e)
      }
    },
    'backup/destroy': async (context, id) => {
      try {
        await axios.delete('/backup/' + id)
        context.commit('backups/destroy', id)
      } catch (e) {
        console.log(e)
      }
    },
    'backup/update': async (context, payload) => {
      try {
        const formData = new FormData()
        for (var key in payload) {
          formData.append(key, payload[key])
        }
        let { data } = await axios.put('/backup/' + payload._id, formData)
        context.commit('backups/update', data)
      } catch (e) {
        console.log(e)
      }
    },
  },
}
