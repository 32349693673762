<template>
  <v-row class="text-center" dir="ltr">
    <v-col>
      <v-list class="scrollbar overflow-x-hidden" max-height="50vh">
        <template>
          <v-list-item dir="rtl">
            <v-list-item-content>
              <v-list-item-subtitle class="text-start auto-new-line">
                {{ `${$t('setting.ansIsNumber')}:` }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-radio-group
                  v-model="form.setting.ansIsNumber"
                  @change="save({ showTost: false })"
                >
                  <v-row>
                    <v-col>
                      <v-radio
                        :value="true"
                        key="isNumber"
                        :label="$t('generics.numbers')"
                      >
                      </v-radio>
                    </v-col>
                    <v-col>
                      <v-radio
                        :value="false"
                        key="isWord"
                        :label="$t('generics.words')"
                      />
                    </v-col>
                  </v-row>
                </v-radio-group>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider />
        </template>
        <template v-for="(name, index) in soundList">
          <!-- <v-subheader :key="index + name" v-text="''" /> -->
          <v-list-item :key="name + index">
            <v-list-item-content dir="rtl">
              <v-list-item-title
                class="auto-new-line"
                v-text="$t(`setting.${name}`)"
              />
              <v-list-item-subtitle class="pt-3">
                <MyCarousel
                  type="audios"
                  v-model="form.setting[name]"
                  :title="getSoundName(form.setting[name])"
                  :defaultFor="name"
                />
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider
            v-if="index !== soundList.length - 1"
            :key="'divider' + name"
          />
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>
<script>
import MyCarousel from '../MyCarousel.vue'
import swalMixins from '@/mixins/swal'

export default {
  name: 'AdvancedSettings',
  components: { MyCarousel },
  props: { value: Object },
  mixins: [swalMixins],
  computed: {
    game() {
      return this.$store.getters.game
    },
  },
  data() {
    return {
      isFirstRender: true,
      form: {
        setting: {
          ansIsNumber: true,
          winnersMediaSound: {
            src: '',
            format: '',
          },
          winnersListMediaSound: {
            src: '',
            format: '',
          },
          genericMediaSound: {
            src: '',
            format: '',
          },
          timerMediaSound: {
            src: '',
            format: '',
          },
          inShowAnsMediaSound: {
            src: '',
            format: '',
          },
          showQuestionMediaSound: {
            src: '',
            format: '',
          },
          playersConnectingMediaSound: {
            src: '',
            format: '',
          },
        },
      },
      soundList: [
        'winnersMediaSound',
        'winnersListMediaSound',
        'genericMediaSound',
        'timerMediaSound',
        'inShowAnsMediaSound',
        'showQuestionMediaSound',
        'playersConnectingMediaSound',
      ],
    }
  },
  methods: {
    save({ showTost = true } = {}) {
      this.$save(this, 'game/update', this.form)
      if (!showTost) return
      this.successToast({
        title: this.$t('generics.changesSaved'),
      })
    },
    getSoundName(asset) {
      return asset?.src?.split('assets/')[1]?.toString() || ''
    },
  },
  mounted() {
    this.form = {
      ...this.game,
      setting: { ...this.form.setting, ...this.game.setting },
    }
  },
  watch: {
    form: {
      deep: true,
      handler() {
        if (this.isFirstRender) return (this.isFirstRender = false)
        this.save()
      },
    },
  },
}
</script>
<style lang="scss"></style>
