<template>
  <div>
    <my-dialog :title="''" v-model="openModal" :fillHeight="false">
      <template v-slot:content>
        <downloads-loader
          v-if="!isLoading && gameToWeb"
          :game="gameToWeb"
          @success="success"
          @setAssetsLength="setAssetsLength"
        />
        <loading-page v-if="isLoading" />
      </template>
      <template v-slot:actions>
        <v-btn
          :disabled="disabled || isLoading"
          color="primary"
          v-text="'הורד'"
          @click="downloadFolder"
        />
      </template>
    </my-dialog>
  </div>
</template>
<script>
import DownloadsLoader from './DownloadsLoader.vue'
import MyDialog from '@/components/MyDialog.vue'
import downloadMixins from '@/mixins/downloadGame'
import LoadingPage from '../LoadingPage.vue'

export default {
  name: 'download-handler',
  components: { MyDialog, DownloadsLoader, LoadingPage },
  props: { value: Boolean, gameId: String },
  mixins: [downloadMixins],
  data() {
    return {
      assetsLength: 0,
      assetsSuccess: [],
      isLoading: false,
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    jsonData() {
      const game = this.gameToJSON
      game?.questions.forEach(q => {
        q.question?.answers.forEach(a => delete a.ansMedia)
      })
      delete game.assets
      return game
    },
    gameToJSON() {
      return this.$store.getters.gameToJSON
    },
    gameToWeb() {
      return this.$store.getters.gameToWeb
    },
    disabled() {
      return this.assetsLength !== this.assetsSuccess.length
    },
  },
  methods: {
    async loadData() {
      await this.$store.dispatch('game/web-export', this.gameId)
      await this.$store.dispatch('game/local-export', this.gameId)
    },
    clearData() {
      this.$store.commit('gameToJSON/unset')
      this.$store.commit('gameToWeb/unset')
    },
    success(asset) {
      this.assetsSuccess.push(asset)
    },
    setAssetsLength(num) {
      this.assetsLength = num
      if (num === 0) {
        this.downloadFolder()
      }
    },
    async downloadFolder() {
      this.isLoading = true
      this.$store.commit('loading/active', true)
      this.$store.commit(
        'loading/setMessages',
        'נא לא לסגור את החלון עד שהמשחק מסיים לרדת'
      )
      if (this.assetsSuccess.length) {
        for (let index = 0; index < this.assetsSuccess.length; index++) {
          const element = this.assetsSuccess[index]
          this.assets.file(element.name, element.data, {
            binary: true,
          })
        }
      }
      this.zip.file('data.json', JSON.stringify(this.jsonData))

      await this.downloadZip(this.gameToJSON.name)
      this.$store.commit('loading/active', false)
      this.openModal = false
    },
  },
  async created() {
    this.isLoading = true
    this.clearData()
    await this.loadData()
    if (!this.gameToJSON) {
      this.openModal = false
    }
    this.isLoading = false
  },
}
</script>
