<template>
  <div v-click-outside="onClickOutside" @click="active = true">
    <v-card
      width="99%"
      rounded="xl"
      style="position: relative"
      class="card-warper"
    >
      <p
        v-if="isNew && question"
        class="font-weight-bold d-flex justify-start"
        style="position: absolute; top: -10px; right: -10px"
      >
        <v-btn rounded @click="$emit('clearQuestion')" icon color="pink">
          <v-icon>mdi-alpha-x-circle-outline</v-icon>
        </v-btn>
      </p>
      <form @submit.prevent="submit" v-if="question">
        <v-container>
          <v-list class="slides-list overflow-x-hidden scrollbar">
            <v-row class="ms-1" dense>
              <v-col cols="8">
                <validation-provider v-if="!hideQuestion" rules="required">
                  <v-textarea
                    v-model="form.question.que"
                    class="font-weight-1"
                    auto-grow
                    rows="1"
                    solo
                    :label="
                      type !== 'media'
                        ? $t('games.que')
                        : $t('questions.mediaTitle')
                    "
                    hide-details
                  />
                </validation-provider>
              </v-col>
              <v-col cols="4" class="d-flex justify-center">
                <v-btn
                  v-if="!hideSettings"
                  outlined
                  rounded
                  class="setting-btn"
                  @click="openSettings = true"
                >
                  <MyIconImage name="setting" max-width="30" class="me-2" />
                  {{ $t('questions.settingText') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-list>
          <v-row dense no-gutters v-if="viewAnswers">
            <v-col dir="ltr" style="position: relative">
              <v-list
                subheader
                class="scrollbar slides-list overflow-x-hidden"
                :height="form.setting.type === 'ans_images' ? '32vh' : '28vh'"
              >
                <v-row class="mx-1" dense no-gutters dir="rtl">
                  <template v-if="form.setting.type !== 'ans_images'">
                    <Answer
                      v-for="(answer, i) in form.question.answers"
                      :key="i"
                      :answer="answer"
                      :answers="form.question.answers"
                      :answersLength="form.question.answers.length"
                      :answerIndex="i"
                      :ans="answer.ans"
                      :correct="answer.correct"
                      :canChooseCorrectAns="viewCorrectAns"
                      @remove="removeAns(i)"
                    />
                  </template>
                  <template v-else>
                    <answer-media
                      v-for="(answer, i) in form.question.answers"
                      :key="i + answer.ans"
                      cols="auto"
                      :answers="form.question.answers"
                      :answersLength="form.question.answers.length"
                      :answerIndex="i"
                      :answer="answer"
                      :canChooseCorrectAns="viewCorrectAns"
                      @add="addAns"
                      @remove="removeAns(i)"
                      @setAns="setAns"
                    />
                  </template>
                </v-row>
              </v-list>
              <p
                class="red--text center-all errors pb-0 mb-0"
                v-for="(error, i) in errors"
                :key="i"
              >
                {{ error }}
              </p>
              <p
                v-if="form.question.answers.length < 6"
                class="add-ans font-weight-bold"
              >
                <v-btn rounded @click="addAns">
                  <MyIconImage name="plus" class="ms-2" />
                  <span>
                    {{ $t('games.addAns') }}
                  </span>
                </v-btn>
              </p>
            </v-col>
          </v-row>
          <v-divider v-if="!hideDividerUnderQuestion" />
          <div v-if="type == 'subject'" class="text-area-card-parent">
            <v-textarea
              v-model="form.question.que"
              solo
              name="que"
              placeholder="יש לכתוב כאן את הטקסט"
              height="400"
              class="text-area-card"
            ></v-textarea>
          </div>
          <v-row class="mx-2 mt-10">
            <v-col v-if="viewTimeForQue">
              <v-slider
                v-model="form.question.timeForQue"
                dense
                thumb-label="always"
                :label="$t('games.timeForQue')"
                max="120"
                min="0"
                :thumb-size="40"
                color="blueLogo"
                track-fill-color="blueLogo"
                track-color="blueLogo"
                ><template v-slot:thumb-label="{ value }">
                  <span class="yellow--text text-body-1">
                    {{ value }}
                  </span>
                </template>
              </v-slider>
            </v-col>
            <v-col lg="6" md="12" v-if="viewScore">
              <v-slider
                v-model="form.question.scoreForQue"
                dense
                thumb-label="always"
                :label="$tc('games.score')"
                max="50"
                min="1"
                :thumb-size="40"
                color="blueLogo"
                track-fill-color="blueLogo"
                track-color="blueLogo"
              >
                <template v-slot:thumb-label="{ value }">
                  <span class="yellow--text text-body-1">
                    {{ value }}
                  </span>
                  <input type="text" />
                  <!-- <v-text-field :value="value"></v-text-field> -->
                </template>
              </v-slider>
            </v-col>
          </v-row>
          <v-divider v-if="viewQuestionUploadMedia" />
          <QuestionUploadMedia
            v-model="form"
            v-if="viewQuestionUploadMedia"
            @save="submit"
          />
          <!-- ans media -->
          <AnsMedia v-if="mediaAndMovie" v-model="form" />
        </v-container>
      </form>
    </v-card>
    <question-setting
      v-if="openSettings"
      v-model="form.setting"
      :openSettings="openSettings"
      @onClose="closeSetting"
      @save="saveSetting"
      :type="type"
      :invalid="invalid"
      :isNew="isNew"
    />
  </div>
</template>
<script>
import MyIconImage from '@/components/MyIconImage.vue'
import _ from 'lodash'
import Answer from '@/components/answers/Answer.vue'
import AnswerMedia from '@/components/answers/AnswerMedia'
import QuestionUploadMedia from './QuestionUploadMedia.vue'
// import CloudBtnStyle from '@/components/buttons/CloudBtnStyle.vue'
import swalMixins from '@/mixins/swal'
import QuestionSetting from '@/components/settingHandler/SettingIndex.vue'
// import ViewVideo from '../ViewVideo.vue'
import AnsMedia from './AnsMedia.vue'

export default {
  name: 'Question',
  components: {
    Answer,
    AnswerMedia,
    MyIconImage,
    QuestionUploadMedia,
    // CloudBtnStyle,
    QuestionSetting,
    // ViewVideo,
    AnsMedia,
  },
  props: {
    question: Object,
    invalid: { type: Boolean, default: false },
    isNew: { type: Boolean, default: false },
  },
  mixins: [swalMixins],
  data() {
    return {
      openSettings: false,
      errors: [],
      form: {
        question: {
          que: '',
          scoreForQue: 3,
          timeForQue: 15,
          answers: [{}],
        },
        openMedia: { src: '', format: '' },
        endMedia: { src: '', format: '' },
        backgroundMedia: { src: '', format: '' },
        setting: {
          type: 'trivia',
          allowChangeVote: false,
          slideStartVoting: false,
          playAfterClicking: false,
          showInLoop: false,
        },
      },
      type: 'trivia',
      active: false,
      isFirstRender: true,
    }
  },
  computed: {
    hideSettings() {
      const types = ['subject']
      return types.includes(this.type)
    },
    hideQuestion() {
      const types = ['subject']
      return types.includes(this.type)
    },
    hideDividerUnderQuestion() {
      const types = ['subject', 'media', 'movie']
      return types.includes(this.type)
    },
    viewCorrectAns() {
      const types = ['media', 'trivia', 'ans_images']
      return types.includes(this.type)
    },
    viewAnswers() {
      const types = ['survey', 'trivia', 'ans_images']
      return types.includes(this.type)
    },
    viewScore() {
      const types = ['trivia', 'ans_images']
      return types.includes(this.type)
    },
    viewQuestionUploadMedia() {
      const types = ['trivia', 'ans_images', 'survey']
      return types.includes(this.type)
    },
    mediaAndMovie() {
      const types = ['media', 'movie']
      return types.includes(this.type)
    },

    viewTimeForQue() {
      const types = ['trivia', 'survey', 'ans_images']
      return types.includes(this.type)
    },
    game() {
      return this.$store.getters.game
    },
  },
  methods: {
    saveSetting(setting) {
      this.form['setting'] = setting
      // this.saveAndUpdate();
      // this.$emit('closeSettingModel')
    },
    closeSetting() {
      this.openSettings = false
      if (!this.isNew) {
        this.submit()
      }
    },
    onClickOutside() {
      console.log('click outside', this.active)
      if (!this.active || !this.question) return
      this.active = false
      this.isFirstRender = true
      this.submit()
    },
    validate() {
      this.errors = []
      // user has to fill open media OR end media OR question (media or text)
      if (
        !this.form?.openMedia?.src?.length &&
        !this.form?.endMedia?.src?.length &&
        !this.form?.backgroundMedia?.src?.length &&
        !this.form?.question?.que?.length
      ) {
        this.errors.push('חייב למלא מדיה פתיחה או מדיה סיום או שאלה')
        return false
      }
      //if this slide type enable to fill answers, validate answers is filled
      if (this.viewAnswers) {
        let answers = this.form.question.answers
        // if (this.form.question.que || this.form.src) {
        // if user fill question, he has to fill answers
        if (!answers.length) this.errors.push('חייב למלא תשובות')
        const emptyAns = answers.filter(answer => !answer.ans)
        if (emptyAns.length) this.errors.push('לא להשאיר תשובות ריקות')
        if (
          this.viewCorrectAns &&
          !answers.filter(answer => answer.correct).length
        )
          this.errors.push('יש לסמן לפחות תשובה אחת כנכונה')
      }
      return !this.errors.length
    },
    async submit() {
      this.validate()
      if (this.invalid) return
      try {
        if (!this.viewAnswers) {
          this.form.question.answers = []
        }
        const data = {
          ...this.form,
          question: {
            ...this.form.question,
          },
          type: this.type,
          game: this.game._id,
        }
        const action = this.isNew ? 'store' : 'update'
        const question = await this.$store.dispatch(`question/${action}`, data)
        this.form = await { ...this.form, ..._.cloneDeep(question) }
        if (this.isNew) {
          await this.$emit('selectQuestion', question, false)
        }
        this.successToast({
          title: this.$t('generics.changesSaved'),
        })
      } catch (e) {
        this.timeoutSwal({
          title: this.$t('questions.saveError'),
          icon: 'error',
        })
        console.log(e)
      }
    },
    addAns() {
      const ans = {
        ans: '',
      }
      if (this.form.type === 'ans_images') {
        ans['ansMedia'] = {
          format: '',
          src: '',
        }
      }
      if (this.form.type !== 'survey') {
        ans['correct'] = false
      }
      this.form.question.answers.push(ans)
    },
    removeAns(index) {
      if (index > -1) {
        this.form.question.answers.splice(index, 1)
      }
    },
    setAns(ans) {
      if (ans.index > -1) this.form.question.answers[ans.index] = ans
    },
  },
  mounted() {
    this.form = {
      type: this.question?.setting?.type,
      ...this.form,
      ..._.cloneDeep(this.question),
    }
  },
  watch: {
    'form.setting.type'() {
      this.type = this.form.setting.type
      if (this.type === 'trivia') this.addAns()
    },
    form: {
      deep: true,
      handler() {
        if (!this.isFirstRender) {
          console.log('form changed')
          this.active = true
        }
        this.isFirstRender = false
      },
    },

    invalid() {
      if (!this.invalid && this.errors.length) {
        this.validate()
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.card-warper {
  min-height: 70vh;
}
.setting-btn {
  border: 3px solid var(--pink);
  margin: auto;
  height: 45px !important;
  padding: 0 12px !important;
}
@media (max-width: 1200px) {
  .setting-btn {
    padding: 0 2px !important;
    ::v-deep .my-icon-image {
      margin: 0 !important;
    }
  }
}
::v-deep .v-input__control {
  border-radius: 40px !important;
}
.add-ans {
  position: absolute;
  bottom: 0;
  left: 0;
  border-radius: 40px;
  background: white;
  border: 2px solid var(--green);
  justify-content: space-between !important;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  padding: 6px !important;
  padding-right: 16px !important;
  padding-left: 16px !important;
}
.text-area-card {
  width: 600px;
  margin: auto;
  margin-top: auto;
  font-weight: 300;
  font-size: 22px;
  line-height: 27px;
}
.text-area-card-parent {
  height: 63vh;
  display: grid;
  place-items: center;
}
</style>
<style lang="scss">
.text-area-card textarea {
  padding: 10px 10px !important;
}
</style>
