<template>
  <v-card max-width="1200" class="container mt-16">
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="11"> </v-col>
        <v-col cols="1" class="center-all">
          <v-icon
            color="primary"
            @click="addDialog = true"
            v-text="'mdi-plus'"
          />
        </v-col>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
            @click="openCreateDialog('image')"
            outlined
            color="primary"
            class="center-all-col"
            block
          >
            <v-icon color="primary" v-text="'mdi-plus'" />
            <div>הוסף תמונה</div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="2" v-for="image in images" :key="image.url">
          <v-icon color="success" @click="openEditDialog(image)">
            mdi-pencil
          </v-icon>
          <v-icon color="error" @click="remove(image)"> mdi-delete </v-icon>
          <span>
            {{ image.name }}
          </span>
          <v-img :src="image.url" width="200" height="150" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
            @click="openCreateDialog('video')"
            outlined
            color="primary"
            class="center-all-col"
            block
          >
            <v-icon color="primary" v-text="'mdi-plus'" />
            <div>הוסף וידיאו</div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3" v-for="video in videos" :key="video.url">
          <v-icon color="success" @click="openEditDialog(video)">
            mdi-pencil
          </v-icon>
          <v-icon color="error" @click="remove(video)"> mdi-delete </v-icon>
          <span>
            {{ video.name }}
          </span>
          <PlayVideo :src="video.url" />
        </v-col>
      </v-row>
    </v-card-text>
    <v-card-text>
      <v-row>
        <v-col>
          <v-btn
            @click="openCreateDialog('audio')"
            outlined
            color="primary"
            class="center-all-col"
            block
          >
            <v-icon color="primary" v-text="'mdi-plus'" />
            <div>הוסף שמע</div>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          v-for="audio in audios"
          :key="audio.url"
          class="d-flex flex-column"
        >
          <div>
            <v-icon color="success" @click="openEditDialog(audio)">
              mdi-pencil
            </v-icon>
            <v-icon color="error" @click="remove(audio)"> mdi-delete </v-icon>
            <span>
              {{ audio.name }}
            </span>
          </div>
          <PlayAudio :src="audio.url" />
        </v-col>
      </v-row>
    </v-card-text>
    <UploadFileCloud
      v-if="addDialog"
      v-model="addDialog"
      @save="create"
      :onlyImage="uploadType === 'image'"
      :onlySound="uploadType === 'audio'"
      :onlyVideo="uploadType === 'video'"
      :folder="`repositories/${uploadType}s`"
    />
    <StyleDialog v-if="editDialog" v-model="editDialog">
      <template slot="content">
        <v-container class="px-16">
          <v-row>
            <v-col cols="12">
              <v-text-field v-model="chosen.name" />
            </v-col>
            <v-col>
              <v-select
                :items="defaultList"
                item-text="text"
                item-value="value"
                v-model="chosen.defaultFor"
              />
            </v-col>
          </v-row>
        </v-container>
      </template>
      <template slot="actions">
        <v-btn @click="update(chosen)">שמור</v-btn>
      </template>
    </StyleDialog>
  </v-card>
</template>
<script>
import PlayAudio from '@/components/PlayAudio.vue'
import PlayVideo from '@/components/PlayVideo.vue'
import StyleDialog from '@/components/StyleDialog.vue'
import UploadFileCloud from '@/components/UploadFileCloud.vue'
export default {
  name: 'RepositoryManager',
  components: {
    PlayAudio,
    PlayVideo,
    UploadFileCloud,
    StyleDialog,
  },
  computed: {
    images() {
      return this.$store.getters.imagesRepositories
    },
    videos() {
      return this.$store.getters.videosRepositories
    },
    audios() {
      return this.$store.getters.audiosRepositories
    },
  },
  data() {
    return {
      addDialog: false,
      editDialog: false,
      uploadType: '',
      chosen: {},
      defaultList: [
        { text: this.$t('setting.gameMedia'), value: 'gameMedia' },
        // { text: this.$t('setting.logo'), value: 'logo' },
        {
          text: this.$t('setting.slidBackgroundMedia'),
          value: 'slidBackgroundMedia',
        },
        { text: this.$t('setting.triviaMedia'), value: 'triviaMedia' },
        {
          text: this.$t('setting.winnersListMedia'),
          value: 'winnersListMedia',
        },
        { text: this.$t('setting.winnersMedia'), value: 'winnersMedia' },
        {
          text: this.$t('setting.winnersMediaSound'),
          value: 'winnersMediaSound',
        },
        {
          text: this.$t('setting.winnersListMediaSound'),
          value: 'winnersListMediaSound',
        },
        {
          text: this.$t('setting.genericMediaSound'),
          value: 'genericMediaSound',
        },
        { text: this.$t('setting.timerMediaSound'), value: 'timerMediaSound' },
        {
          text: this.$t('setting.inShowAnsMediaSound'),
          value: 'inShowAnsMediaSound',
        },
        {
          text: this.$t('setting.showQuestionMediaSound'),
          value: 'showQuestionMediaSound',
        },
        {
          text: this.$t('setting.playersConnectingMediaSound'),
          value: 'playersConnectingMediaSound',
        },
        { text: this.$t('ללא'), value: '' },
      ],
    }
  },
  methods: {
    openCreateDialog(type) {
      this.uploadType = type
      this.addDialog = true
    },
    openEditDialog(item) {
      this.chosen = item
      this.editDialog = true
    },
    create(item) {
      this.$store.dispatch('metaData/store', { asset: item })
    },
    update(item) {
      this.$store.dispatch('metaData/update', item)
      this.editDialog = false
    },
  },
}
</script>
<style lang="scss"></style>
