const subjectQuestion = {
  type: 'subject',
  setting: {
    type: 'subject',
    allowChangeVote: false,
    slideStartVoting: true,
    playAfterClicking: false,
    exitGame: false,
    correctlyAnsweredBefore: false,
    firstClicker: false,
    answerIsSequenceClicks: false,
    skipAfterClick: false,
    fullscreen: false,
    scoringReduction: {
      active: false,
      seconds: '',
      score: '',
    },
    automaticSkip: {
      active: false,
      seconds: '',
    },
    slidBackgroundMedia: {
      src: '',
      format: '',
    },
  },
  question: {
    que: '',
    scoreForQue: 3,
    timeForQue: 15,
  },
  openMedia: {
    src: '',
    format: '',
  },
  endMedia: {
    src: '',
    format: '',
  },
  backgroundMedia: {
    src: '',
    format: '',
  },
}
export default subjectQuestion
