<template>
  <div>
    <div>
      <span class="center-all">
        {{ $t(title) }}
      </span>
      <v-carousel
        v-if="type.includes('mage')"
        v-model="itemIndex"
        @change="save"
        hide-delimiters
        height="auto"
        class="center-all"
        style="position: relative"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            icon
            style="position: absolute; top: 0; left: 0"
            v-bind="attrs"
            v-on="on"
          >
            <MyIconImage
              v-if="!isLoading"
              name="rightArrow"
              :moreStyle="{ background: 'primary', zIndex: 10 }"
              :maxWidth="30"
            />
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            icon
            style="position: absolute; top: 0; right: 0"
            v-bind="attrs"
            v-on="on"
          >
            <MyIconImage
              v-if="!isLoading"
              name="leafArrow"
              :moreStyle="{ background: 'primary', zIndex: 10 }"
              :maxWidth="30"
            />
          </v-btn>
        </template>
        <v-carousel-item
          class="my-carousel"
          v-for="item in items"
          :key="item.url"
        >
          <v-sheet height="100%">
            <v-row class="fill-height" align="center" justify="center">
              <v-img
                v-if="item.format !== 'mp4'"
                :src="item.url"
                width="200px"
                height="200px"
                aspect-ratio="1"
                @load="setLoading"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <div height="100px" width="100px">
                      <MyIconImage maxWidth="100" name="logo-anim" type="gif" />
                    </div>
                  </v-row>
                </template>
              </v-img>
              <ViewVideo v-else :src="item.url" width="200px" height="200px" />
            </v-row>
          </v-sheet>
        </v-carousel-item>
        <MyIconImage
          v-if="isLoading"
          maxWidth="100"
          name="logo-anim"
          type="gif"
        />
      </v-carousel>
      <v-carousel
        v-else
        v-model="itemIndex"
        @change="save"
        hide-delimiters
        height="auto"
        class="center-all"
        style="width: 100%"
      >
        <template v-slot:prev="{ on, attrs }">
          <v-btn
            icon
            style="position: absolute; top: 0; left: 10px"
            v-bind="attrs"
            v-on="on"
          >
            <MyIconImage
              name="rightArrow"
              :moreStyle="{ background: 'primary', zIndex: 10 }"
              :maxWidth="30"
            />
          </v-btn>
        </template>
        <template v-slot:next="{ on, attrs }">
          <v-btn
            icon
            style="position: absolute; top: 0; right: 10px"
            v-bind="attrs"
            v-on="on"
          >
            <MyIconImage
              name="leafArrow"
              :moreStyle="{ background: 'primary', zIndex: 10 }"
              :maxWidth="30"
            />
          </v-btn>
        </template>
        <v-carousel-item v-for="item in items" :key="item.url">
          <v-sheet height="100%">
            <span>
              <play-audio
                @load="setLoading"
                :src="item.url"
                v-show="!isLoading"
                maxWidth="10vw"
              />
            </span>
          </v-sheet>
        </v-carousel-item>
        <MyIconImage
          v-if="isLoading"
          maxWidth="100"
          name="logo-anim"
          type="gif"
        />
      </v-carousel>
      <p class="center-all" style="position: relative" v-if="!isLoading">
        <span
          @click="openGallery = true"
          class="mouse-pointer"
          style="position: absolute; top: -25px; border-radius: 6px"
          v-if="type.includes('mage')"
        >
          <MyIconImage
            type="svg"
            name="openSelector"
            :maxWidth="25"
            :moreStyle="{ background: 'primary', zIndex: 10 }"
          />
        </span>
        <v-btn
          v-else
          @click="openGallery = true"
          class="cloud-btn"
          :style="{ 'border-color': `var(--green)` }"
          outlined
          rounded
          small
        >
          <span class="me-2 font-weight-regular">
            {{ $t('audios.selectFromRepository') }}
          </span>
        </v-btn>
      </p>
    </div>
    <AssetsRepositories
      v-if="openGallery"
      v-model="openGallery"
      :type="type"
      @setCurrentItem="selectFromRepository"
      :currentItem="itemIndex"
    />
  </div>
</template>
<script>
import MyIconImage from './MyIconImage.vue'
import AssetsRepositories from './AssetsRepositories.vue'
import PlayAudio from './PlayAudio.vue'
import ViewVideo from './ViewVideo.vue'

export default {
  name: 'MyCarousel',
  props: {
    value: { type: Object, default: () => ({}) },
    type: { type: String, default: 'images' },
    title: { type: String, default: '' },
    defaultFor: { type: String, default: '' },
  },
  components: {
    PlayAudio,
    MyIconImage,
    AssetsRepositories,
    ViewVideo,
  },
  computed: {
    items() {
      return this.$store.getters[this.type] || []
    },
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  data() {
    return {
      itemIndex: 0,
      isLoading: true,
      openGallery: false,
    }
  },
  methods: {
    setLoading() {
      this.isLoading = false
    },
    selectFromRepository(itemIndex) {
      this.itemIndex = itemIndex
      this.save(itemIndex)
    },
    save(index) {
      this.form = { ...this.form, ...this.items[index] }
    },
  },
  async mounted() {
    let currentIndex = 0
    currentIndex = await this.items.findIndex(i => i.src === this.form.src)
    if (currentIndex === -1 && this.defaultFor) {
      currentIndex = await this.items.findIndex(
        i => i.defaultFor === this.defaultFor
      )
      if (currentIndex !== -1) {
        this.save(currentIndex)
      } else {
        currentIndex = 0
      }
    }

    this.itemIndex = currentIndex
    await this.setLoading()
  },
}
</script>
<style lang="scss" scoped>
::v-deep .my-carousel {
  padding: 10px;
  border-radius: 10px;
  background-color: var(--gray);
}
.cloud-btn {
  background: white;
  border: 3px solid;
  justify-content: space-between !important;
}
</style>
