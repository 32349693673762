<template>
  <div>
    <GameNavbar />
    <validation-observer ref="observer" v-if="game" v-slot="{ invalid }">
      <form @submit.prevent="submit">
        <v-row dense no-gutters>
          <v-col cols="3">
            <v-flex d-flex child-flex class="fill-height">
              <v-card class="mx-auto" elevation="4">
                <SidBarGame v-model="game.setting" />
              </v-card>
            </v-flex>
          </v-col>
          <v-col>
            <v-container
              class="mx-0 px-0"
              style="max-width: 83vw; margin-top: 2vh"
            >
              <v-row>
                <v-col cols="5">
                  <QuestionAction v-model="search" />
                </v-col>
                <v-col cols="7">
                  <QuestionTypes
                    @selectQuestion="question => selectQuestion(question, true)"
                    :invalid="invalid"
                  />
                </v-col>
              </v-row>
              <v-row class="mt-0">
                <v-col cols="5">
                  <QuestionList
                    @selectQuestion="
                      question => selectQuestion(question, false)
                    "
                    @clearQuestion="clearQuestion"
                    :queId="queId"
                    :search="search"
                    :invalid="invalid"
                  />
                </v-col>
                <v-col cols="7">
                  <Question
                    :question="form"
                    :key="queId"
                    :isNew="isNew"
                    :invalid="invalid"
                    @selectQuestion="selectQuestion"
                    @clearQuestion="() => selectQuestion(null, true)"
                  />
                  <GameAction />
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </form>
    </validation-observer>
  </div>
</template>
<script>
import _ from 'lodash'
import SidBarGame from '@/components/game/SidBarGame.vue'
import QuestionTypes from '@/components/question/QuestionTypes.vue'
import Question from '@/components/question/Question.vue'
import QuestionList from '@/components/question/QuestionList.vue'
import QuestionAction from '@/components/question/QuestionAction.vue'
import GameAction from '@/components/game/GameAction.vue'
import GameNavbar from '@/components/game/GameNavbar.vue'

export default {
  name: 'Game',
  components: {
    QuestionAction,
    GameAction,
    SidBarGame,
    QuestionTypes,
    Question,
    QuestionList,
    GameNavbar,
  },
  data() {
    return {
      form: null,
      isNew: false,
      search: '',
      upload: false,
    }
  },
  computed: {
    game() {
      return this.$store.getters.game
    },
    //   game:{
    //     get() {
    //     return this.$store.getters.game
    //   }
    // set(v){
    //   this.$store.commit.
    // }
    // },
    question() {
      return this.$store.getters.question
    },
    queId() {
      return this.form && this.form._id
    },
  },
  methods: {
    setIsNew(v) {
      this.isNew = v
      this.form = this.question
    },
    selectQuestion(question, isNew) {
      this.isNew = isNew
      this.form = question ? _.cloneDeep(question) : null
    },
    clearData() {
      this.$store.commit('game/clearData')
      this.$store.commit('questions/clearData')
    },
    clearQuestion() {
      this.form = null
    },
  },
  async beforeMount() {
    try {
      this.$store.commit('loading/active', true)
      this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
      await this.$store.dispatch('game/show', this.$route.params.id)
      if (!this.game) {
        this.$router.push({ name: 'UserDashboard' })
      }
      this.$store.commit('loading/active', false)
    } catch (e) {
      console.log(e)
    }
  },
  destroyed() {
    this.clearData()
  },
}
</script>
<style lang="scss" scoped>
::v-deep .col-3 {
  max-width: 17%;
}
::v-deep .v-card.mx-auto {
  box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 0%), 0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 4px 5px 0px rgb(0 0 0 / 12%) !important;
}
</style>
