<template>
  <div dir="ltr" class="">
    <v-list
      subheader
      two-line
      class="scrollbar overflow-x-hidden"
      height="75vh"
      v-if="questions.length"
    >
      <draggable
        class="list-group pl-0"
        v-model="questions"
        tag="ul"
        v-bind="dragOptions"
        @start="drag = true"
        @end="drag = true"
      >
        <transition-group
          type="transition"
          :name="!drag ? 'flip-list' : null"
          class="list-transition-group"
        >
          <question-line
            v-for="(question, i) in questionsFilter"
            :key="question._id"
            :disabled="invalid"
            :que="question"
            :index="i"
            :isChosen="checkChosen(question)"
            :isLastOne="i === questionsFilter.length - 1"
            :clear="clearCurrentQuestion"
            @clear="clearCurrentQuestion"
            @selectQuestion="que => $emit('selectQuestion', que)"
          />
        </transition-group>
      </draggable>
    </v-list>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import QuestionLine from '@/components/question/QuestionLine.vue'
export default {
  name: 'QuestionList',
  components: {
    draggable,
    QuestionLine,
  },
  props: {
    queId: String,
    search: String,
    invalid: { type: Boolean, default: false },
  },
  data() {
    return {
      drag: true,
    }
  },
  computed: {
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    game() {
      return this.$store.getters.game
    },

    questions: {
      get() {
        if (!this.game) return []
        return this.$store.getters.game.questions
      },
      async set(newValue) {
        await this.$store.dispatch('game/question/update', {
          ...this.game,
          questions: [...newValue],
        })
        return await this.$store.getters.game.questions
      },
    },
    questionsFilter() {
      return this.$store.getters.questionsFilter(this.search)
    },
  },
  methods: {
    checkChosen(question) {
      return this.queId === question._id
    },
    openSettingModel() {
      this.showSettings = true
    },
    closeSettingModel() {
      this.showSettings = false
    },
    async duplicate(question) {
      await this.$store.dispatch('question/replicate', question._id)
    },
    clearCurrentQuestion() {
      this.$emit('clearQuestion')
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-divider--inset:not(.v-divider--vertical) {
  max-width: 83%;
}
::v-deep .scrollbar {
  margin-right: 20px;
}
</style>
