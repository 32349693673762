<template>
  <div class="px-10" v-if="!loading">
    <div class="center-all my-4">
      <h3>{{ $t('route.QuestionRepository') }}</h3>
    </div>
    <!-- <v-container> -->
    <v-data-table
      v-model="selected"
      :show-select="fromImportQuestionsPage"
      :headers="headers"
      :items="questionsFilter"
      :search="search"
      :loading="loading"
      :items-per-page="fromImportQuestionsPage ? 5 : 15"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      :show-expand="isAdmin && !fromImportQuestionsPage"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 250] }"
    >
      <template v-slot:top>
        <v-row class="mx-3">
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              icon
              :label="$t('generics.search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="searchByCategories"
              auto-select-first
              chips
              clearable
              deletable-chips
              multiple
              small-chips
              item-text="name"
              :label="$t('generics.searchByCategories')"
              :items="categories"
            />
          </v-col>
          <v-col cols="1" v-if="isAdmin">
            <tooltip-icon
              :tooltipText="$t('categories.editLabelsTooltip')"
              :icon="'mdi-pencil'"
              :fab="true"
              color="primary"
              @action="editCategories = true"
            />
            <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pa-3">
            <v-col cols="1">
              <div class="d-flex justify-start info--text ma-1">
                {{ $t('answers.answers') }}:
              </div>
            </v-col>
            <v-col
              cols="1"
              v-for="(ans, i) in item.question.answers"
              :key="ans._id"
              class="d-flex"
            >
              {{ i + 1 }}.
              <div
                :class="` ${
                  ans['correct'] ? 'success' : 'error'
                }--text answers`"
              >
                {{ ans['ans'] }}
              </div>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.categories="{ item }">
        <v-chip-group column>
          <v-chip v-for="category in item.categories" :key="category._id">
            {{ category.name }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>
      <template v-slot:item.actions="{ item }">
        <tooltip-icon
          v-if="isAdmin || equals(item)"
          :tooltipText="$t('questions.editQuestionTooltip')"
          :icon="'mdi-pencil'"
          fab
          @action="editItem(item)"
        />

        <tooltip-icon
          v-if="isAdmin"
          :tooltipText="$t('questions.deleteQuestionTooltip')"
          :icon="'mdi-delete'"
          color="red"
          :fab="true"
          validator
          @action="disabled => deleteItem(item, disabled)"
        />
      </template>
    </v-data-table>
    <question-category-handler
      v-if="editDialog"
      v-model="editDialog"
      :question="current"
      :isRepository="!equals(current)"
    />
    <categories-handler
      v-if="editCategories"
      v-model="editCategories"
      @loadData="loadDataAdmin"
    />
    <!-- </v-container> -->
  </div>
  <div v-else>
    <MyIconImage maxWidth="500" name="logo-anim" type="gif" />
  </div>
</template>

<script>
import { intersection } from 'lodash'

import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import QuestionCategoryHandler from '../handler/QuestionCategoryHandler.vue'
import CategoriesHandler from '@/components/handler/CategoriesHandler.vue'
import MyIconImage from '../MyIconImage.vue'

export default {
  components: {
    TooltipIcon,
    QuestionCategoryHandler,
    CategoriesHandler,
    MyIconImage,
  },
  name: 'QuestionRepositoryTable',
  mixins: [SwalMixins, GenericMixins],
  props: {
    fromImportQuestionsPage: { type: Boolean, default: false },
  },
  data() {
    return {
      selected: [],
      expanded: [],
      singleExpand: true,
      loading: false,
      current: null,
      search: '',
      editDialog: false,
      editCategories: false,
      searchByCategories: [],
    }
  },
  computed: {
    headers() {
      const adminHeaders =
        !this.fromImportQuestionsPage && this.isAdmin
          ? [
              {
                text: this.$t('questions.createByUser'),
                value: 'createByUser.fullname',
              },
              { text: this.$t('generics.createdAt'), value: 'createdAt' },
            ]
          : []
      return [
        { text: this.$t('questions.questions'), value: 'question.que' },
        { text: this.$t('questions.categories'), value: 'categories' },
        ...adminHeaders,
        {
          text: this.$t('generics.action'),
          value: 'actions',
          width: '10%',
        },
      ]
    },
    categories() {
      return this.$store.getters.categories
    },
    questionRepositories() {
      return this.$store.getters.questionRepositories
    },
    userQuestions() {
      return this.$store.getters.userQuestions
    },
    questionsFilter() {
      const questions = this.fromImportQuestionsPage
        ? this.questionRepositories.concat(this.userQuestions)
        : this.questionRepositories
      return questions.filter(q => {
        if (
          this.searchByCategories.length &&
          !intersection(
            q.categories?.map(c => c.name),
            [...this.searchByCategories]
          ).length
        ) {
          return false
        }
        if (this.search) {
          return q.question.que.includes(this.search)
        }
        return true
      })
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    uid() {
      return this.$store.getters.uid
    },
  },
  methods: {
    equals(que) {
      const queUser = que.use?._id ?? que.user
      return this.uid === queUser
    },
    async loadData() {
      this.loading = true
      if (!this.userQuestions.length) {
        await this.$store.dispatch('question/showMany')
      }
      if (!this.questionRepositories.length) {
        await this.$store.dispatch('questionRepositories/index')
      }
      if (!this.categories.length) {
        await this.$store.dispatch('category/index')
      }
      this.loading = false
    },

    editItem(item) {
      this.current = item
      this.editDialog = true
    },
    async deleteItem(item, disabled) {
      let { isConfirmed } = await this.createSwal({
        icon: 'warning',
        title: `${this.$t('generics.delete')}? \n ${item.question.que}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch('questionRepository/destroy', item._id)
        await this.$store.dispatch(
          'question/displayRegularUser/repository',
          item.OriginalQuestion
        )
        this.timeoutSwal({ icon: 'success', title: '' })
      }
      disabled()
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    await this.loadData()
  },
  watch: {
    selected() {
      this.$emit('onSelect', this.selected)
    },
  },
}
</script>
<style lang="scss" scoped></style>
