<template>
  <div class="my-icon-image d-flex justify-center align-center">
    <v-img :style="style" :src="src" />
  </div>
</template>
<script>
export default {
  name: 'MyIconImage',
  props: {
    name: String,
    type: { type: String, default: 'png' },
    maxWidth: { type: [Number, String], default: 15 },
    moreStyle: { type: Object, default: () => ({}) },
  },
  computed: {
    src() {
      // return `@/assets/icons/${this.name}.${this.type}`
      return require(`@/assets/icons/${this.name}.${this.type}`)
    },
    style() {
      return { 'max-width': `${this.maxWidth}px`, ...this.moreStyle }
    },
  },
}
</script>
