<template>
  <div class="d-flex pt-2 justify-end me-10">
    <v-btn
      v-if="isAdmin"
      elevation="12"
      class="me-2 my-btn game-link-btn"
      rounded
      dark
      @click="showLink = true"
      >{{ $t('generics.showLink') }}</v-btn
    >
    <v-btn
      elevation="12"
      @click="demoGame"
      class="me-2 my-btn game-preview-btn"
      rounded
      dark
    >
      <MyIconImage name="preview" />
      <span class="ps-2" ma>
        {{ $t('games.gamePreview') }}
      </span>
    </v-btn>
    <v-btn
      elevation="12"
      class="me-16 my-btn close-game-btn"
      rounded
      dark
      @click="exportFile"
    >
      <MyIconImage name="export" type="svg" />
      <span class="ps-2" ma>
        {{
          isAdmin || !phonesExported
            ? $t('games.closeGame')
            : $t('generics.showLink')
        }}
      </span>
    </v-btn>
    <show-link v-if="showLink" v-model="showLink" :link="link" />
    <out-to-pay-handler
      v-if="showOutToPay"
      v-model="showOutToPay"
      @download="showDownloadHandler = true"
    />
    <download-handler
      v-if="showDownloadHandler"
      v-model="showDownloadHandler"
      :gameId="game._id"
    />
  </div>
</template>
<script>
import MyIconImage from '@/components/MyIconImage.vue'
import OutToPayHandler from '@/components/handler/OutToPayHandler/OutToPayHandler.vue'
import DownloadHandler from '@/components/download/DownloadHandler.vue'
import ShowLink from '@/components/ShowLink.vue'
import GenericMixins from '@/mixins/generic'

export default {
  name: 'GameAction',
  components: {
    MyIconImage,
    OutToPayHandler,
    DownloadHandler,
    ShowLink,
  },
  mixins: [GenericMixins],
  computed: {
    game() {
      return this.$store.getters.game
    },
    link() {
      return `https://game.clicker.co.il?userId=1212&game=https://app.clicker.co.il/game/web-export/${this.game?._id}`
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    phonesExported() {
      return this.exportApproved && this.game?.setting?.limit?.type === 'phones'
    },
    clickersExported() {
      return (
        this.exportApproved && this.game?.setting?.limit?.type === 'clickers'
      )
    },
    user() {
      return this.$store.getters.userDetails
    },
    exportApproved() {
      const payList = this.user.paymentList
      const result = payList.find(
        l =>
          l.gameId === this.game?._id && this.timeIsBefore(new Date(l.expireAt))
      )
      if (result) return true
      return false
    },
  },
  data() {
    return {
      showDownloadHandler: false,
      showOutToPay: false,
      showLink: false,
    }
  },
  methods: {
    async demoGame() {
      window.open(this.link + '&demo=true', '_blank')
    },
    async exportFile() {
      if (this.isAdmin || this.clickersExported) {
        this.showDownloadHandler = true
      } else if (this.phonesExported) {
        this.showLink = true
      } else {
        this.showOutToPay = true
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border: 3px solid;
  background-color: var(--blueLogo) !important;
}
::v-deep .game-link-btn {
  border-color: var(--yellow) !important;
}
::v-deep .close-game-btn {
  border-color: var(--green) !important;
}
::v-deep .game-preview-btn {
  border-color: var(--azure) !important;
}
</style>
