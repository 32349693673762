<template>
  <v-hover v-slot="{ hover }">
    <v-col
      cols="6"
      :class="answerIndex % 2 === 0 ? 'pe-1' : 'ps-1'"
      class="mt-2 font-weight-1"
    >
      <validation-provider rules="required" style="position: relative">
        <v-textarea
          auto-grow
          rows="1"
          row-height="15"
          class="font-weight-1"
          solo
          :label="$tc('answers.answer')"
          v-model="form.ans"
          dense
          hide-details
          shaped
        >
          <template v-slot:prepend-inner>
            <span class="green--text text-h6 mx-1 mt-n1 font-weight-3">{{
              answerIndex + 1
            }}</span>
          </template>
          <template v-slot:append>
            <div
              v-if="answersLength > 2"
              class="d-flex justify-center align-center mouse-pointer"
              @click="removeAns()"
            >
              <v-img style="max-width: 30px" :src="`/assets/icons/trash.png`" />
            </div>
          </template>
        </v-textarea>
      </validation-provider>

      <ApprovedAnsBtn
        :canChooseCorrectAns="canChooseCorrectAns"
        :hover="hover"
        :correct="form.correct"
        :answers="answers"
        @click="setCorrectAns(!form.correct)"
      />
    </v-col>
  </v-hover>
</template>

<script>
import ApprovedAnsBtn from '@/components/answers/ApprovedAnsBtn.vue'

export default {
  name: 'Answer',
  components: { ApprovedAnsBtn },
  props: {
    answersLength: Number,
    answerIndex: Number,
    canChooseCorrectAns: { type: Boolean, default: true },
    answer: { type: Object, default: () => ({}) },
    answers: { type: Array, default: () => [] },
  },
  computed: {
    form: {
      get() {
        return this.answer
      },
      set(v) {
        this.$emit('input', v)
      },
    },
  },
  methods: {
    removeAns() {
      this.$emit('remove', this.answerIndex)
    },
    setCorrectAns(isCorrect) {
      this.form.correct = isCorrect
    },
  },
}
</script>

<style lang="scss" scoped>
.my-label {
  position: absolute;
  right: 40px;
}
::v-deep
  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
  > .v-input__control
  > .v-input__slot {
  border-radius: 40px;
  height: unset !important;
  padding: 10px !important;
}
</style>
