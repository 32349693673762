import _ from 'lodash'

export const GlobalFunction = {
  install(Vue) {
    Vue.prototype.$lazyFunction = _.debounce(function (_this, fun) {
      fun(_this)
    }, 2000)
    Vue.prototype.$save = (_this, action = '', payload) => {
      _this.$lazyFunction(_this, () => _this.$store.dispatch(action, payload))
    }
  },
}
