<template>
  <audio
    @canplaythrough="$emit('load')"
    controls
    :style="{ maxWidth }"
    v-on="$listeners"
    v-bind="$attrs"
    controlsList="nodownload"
  >
    <source preload="auto" :src="src" type="audio/mpeg" />
    <!-- type="audio/ogg"
      type="audio/mpeg"
      type="audio/wav" -->
    <!-- Your browser does not support the audio element. -->
    הדפדפן שלך אינו תומך ברכיב האודיו
  </audio>
</template>
<script>
export default {
  name: 'play-audio',
  props: {
    src: String,
    maxWidth: { type: String, default: '236px' },
  },
}
</script>
