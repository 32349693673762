<template>
  <div>
    <v-row>
      <v-col v-for="t in types" :key="t.text">
        <v-hover v-slot="{ hover }">
          <v-card
            class="card-type center-all-col"
            :elevation="hover ? 16 : 6"
            @click="select(t.type)"
            :disabled="invalid"
          >
            <div style="position: absolute; top: -9px; right: -9px">
              <MyIconImage
                name="plus"
                type="svg"
                :moreStyle="{ width: '1.7vw', maxWidth: 'none' }"
              />
            </div>
            <div>
              <MyIconImage
                :name="t.type"
                type="png"
                :moreStyle="{ width: '2.7vw', maxWidth: 'none' }"
              />
            </div>
            <v-card-text
              class="my-font-size font-weight-3 pa-0 pt-4 center-all"
            >
              <span v-show="hover"> {{ t.text }} </span>
            </v-card-text>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { typesArray } from '@/components/types/helper'
import MyIconImage from '@/components/MyIconImage.vue'
import {
  mediaStructure,
  surveyStructure,
  triviaStructure,
  ans_imageStructure,
  subjectStructure,
  selectType,
} from '@/data/data structure/index.js'
export default {
  name: 'QuestionTypes',
  components: {
    MyIconImage,
  },
  props: { invalid: { type: Boolean, default: false } },
  data() {
    return {
      types: typesArray,
      structures: [
        subjectStructure,
        mediaStructure,
        surveyStructure,
        triviaStructure,
        ans_imageStructure,
      ],
    }
  },
  methods: {
    select(type) {
      const question = selectType(type)
      this.$emit('selectQuestion', { ...question, _id: Date() })
    },
  },
}
</script>
<style lang="scss" scoped>
::v-deep .v-sheet.v-card.card-type {
  border-radius: 20px !important;
  position: relative;
  height: 100px;
  width: 100px;
}
::v-deep .my-font-size {
  font-size: 0.7rem;
}
</style>
