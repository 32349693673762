<template>
  <div class="computer-warper">
    <div
      @mouseover="hovered = hoverColor"
      @mouseout="hovered = false"
      class="computer"
      :class="hovered || ''"
      @click="click"
    >
      <span>{{ title }}</span>
      <div class="circles-container">
        <div
          v-for="circleColor in circleColors"
          :key="circleColor"
          class="circle"
          :class="circleColor"
          :style="{
            backgroundColor: circleBackgroundColor(circleColor),
          }"
        />
      </div>
    </div>
    <div class="screen-base">
      <my-icon-image max-width="50" name="screenBase" />
    </div>
    <div :class="`action-icon-${iconName}`">
      <my-icon-image :max-width="iconSize" :name="iconName" />
    </div>
  </div>
</template>

<script>
import MyIconImage from '../../MyIconImage.vue'

export default {
  name: 'ComputerOption',
  components: {
    MyIconImage,
  },
  props: {
    click: Function,
    title: String,
    iconName: String,
    hoverColor: String,
    iconSize: {
      type: Number,
      default: 100,
    },
  },
  data() {
    return {
      circleColors: ['azure', 'yellow', 'pink', 'green'],
      hovered: false,
    }
  },
  methods: {
    circleBackgroundColor(circleColor) {
      return this.hovered === circleColor ? '#222b45 !important' : ''
    },
  },
}
</script>

<style scoped>
/* The styles related to computer-warper and its children are moved here */
.computer-warper {
  position: relative;
  width: 210px;
  height: 135px;
  display: grid;
  border-radius: 15px;
  place-items: center;
  border: 1px solid black !important;
}

.computer {
  background-color: #222b45;
  color: #ffd23f;
  height: 125px;
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding: 10px;
  border-radius: 15px;
  font-size: 20px;
  cursor: pointer;
}

.circles-container {
  display: flex;
  gap: 15px;
}

.circle {
  width: 15px;
  height: 15px;
  border-radius: 50%;
}

.screen-base {
  position: absolute;
  bottom: -32px;
}

.action-icon-clicker {
  position: absolute;
  left: -32px;
  bottom: -57px;
}
.action-icon-phone {
  position: absolute;
  left: -17px;
  bottom: -44px;
}
.action-icon-downloadIcon {
  position: absolute;
  left: -55px;
  bottom: -80px;
}

.hovered {
  background-color: #222b45 !important;
}
</style>
