<template>
  <div class="px-10">
    <div class="center-all my-4">
      <h3>{{ $t('route.AllQuestions') }}</h3>
    </div>
    <v-overlay :value="loading" color="transparent">
      <v-progress-circular
        color="primary"
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
    <v-data-table
      v-model="selected"
      :show-select="fromImportQuestionsPage"
      :headers="headers"
      :items="questions"
      :loading="loading"
      :single-expand="singleExpand"
      :expanded.sync="expanded"
      item-key="_id"
      :show-expand="isAdmin"
      class="elevation-1"
      :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 250] }"
      :server-items-length="questionsCount"
      @update:page="page => addData({ page })"
      @update:items-per-page="perPage => addData({ perPage })"
      @update:sort-by="sortBy = $event"
      @update:sort-desc="sortDesc = $event"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-row class="mx-3">
          <v-col>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              icon
              :label="$t('generics.search')"
              single-line
              hide-details
            />
          </v-col>
          <v-col>
            <v-autocomplete
              v-model="searchByCategories"
              auto-select-first
              chips
              clearable
              deletable-chips
              multiple
              small-chips
              item-text="name"
              :label="$t('generics.searchByCategories')"
              :items="categories"
              item-value="name"
            />
          </v-col>
          <v-col cols="1">
            <tooltip-icon
              :tooltipText="$t('categories.editLabelsTooltip')"
              :icon="'mdi-pencil'"
              :fab="true"
              color="primary"
              @action="editCategories = true"
            />
            <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row class="pa-3">
            <v-col cols="1">
              <div class="d-flex justify-start info--text ma-1">
                {{ $t('answers.answers') }}:
              </div>
            </v-col>
            <v-col
              cols="1"
              v-for="(ans, i) in item.question.answers"
              :key="ans._id"
              class="d-flex"
            >
              {{ i + 1 }}.
              <div
                :class="` ${
                  ans['correct'] ? 'success' : 'error'
                }--text answers`"
              >
                {{ ans['ans'] }}
              </div>
            </v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.categories="{ item }">
        <v-chip-group column>
          <v-chip v-for="category in item.categories" :key="category._id">
            {{ category.name }}
          </v-chip>
        </v-chip-group>
      </template>
      <template v-slot:item.user="{ item }">
        {{ getEmailUser(item.user) }}
      </template>
      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>
      <template v-slot:item.displayRegularUser="{ item }">
        <div
          :class="item.displayRegularUser ? 'success' : 'error'"
          class="center-all rounded-xl"
        >
          {{
            item.displayRegularUser
              ? $t('questions.isInRepository')
              : $t('questions.isNotInRepository')
          }}
        </div>
      </template>
      <template v-slot:item.actions="{ item }">
        <tooltip-icon
          icon="mdi-eye"
          :color="item.displayRegularUser ? 'green' : 'red'"
          :tooltipText="$t(`questions.showAndDoNotShowToRegularUserTooltip`)"
          validator
          @action="disabled => saveShow(item, disabled)"
        />

        <tooltip-icon
          :tooltipText="$t('questions.editQuestionTooltip')"
          :icon="'mdi-pencil'"
          :fab="true"
          @action="editItem(item)"
        />

        <tooltip-icon
          :tooltipText="$t('questions.deleteQuestionTooltip')"
          :icon="'mdi-delete'"
          color="red"
          :fab="true"
          validator
          @action="disabled => deleteItem(item, disabled)"
        />
      </template>
    </v-data-table>
    <question-category-handler
      v-if="editDialog"
      v-model="editDialog"
      :question="current"
    />
    <categories-handler
      v-if="editCategories"
      v-model="editCategories"
      @loadData="loadDataAdmin"
    />
  </div>
</template>

<script>
import { debounce } from 'lodash'

import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import QuestionCategoryHandler from '../handler/QuestionCategoryHandler.vue'
import CategoriesHandler from '@/components/handler/CategoriesHandler.vue'

export default {
  components: {
    TooltipIcon,
    QuestionCategoryHandler,
    CategoriesHandler,
  },
  name: 'AllQuestions',
  mixins: [SwalMixins, GenericMixins],
  props: {
    fromImportQuestionsPage: { type: Boolean, default: false },
  },
  data() {
    return {
      page: 1,
      perPage: 25,
      selected: [],
      expanded: [],
      singleExpand: true,
      loading: false,
      current: null,
      search: '',
      editDialog: false,
      editCategories: false,
      searchByCategories: [],
      sortBy: 'createdAt',
      sortDesc: true,
      headers: [
        {
          text: this.$t('questions.questions'),
          value: 'question.que',
          sortable: true,
        },
        {
          text: this.$t('questions.categories'),
          value: 'categories',
          sortable: false,
        },
        {
          text: this.$t('questions.createByUser'),
          value: 'user.fullname',
          sortable: true,
        },
        {
          text: this.$t('generics.createdAt'),
          value: 'createdAt',
          sortable: true,
        },
        {
          text: this.$t('questions.isInRepository'),
          value: 'displayRegularUser',
          sort: this.sortInRepository,
          sortable: false,
        },
        {
          text: this.$t('generics.action'),
          value: 'actions',
          width: '10%',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    questions() {
      return this.$store.getters.questions
    },
    questionsCount() {
      return this.$store.getters.questionCount
    },
    categories() {
      return this.$store.getters.categories
    },

    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
  methods: {
    sortInRepository(a, b) {
      if (a) {
        return 1
      }
      if (b) {
        return -1
      }
      return 0
    },
    async saveShow(item, disabled) {
      try {
        if (!item.displayRegularUser) {
          await this.$store.dispatch('question/displayRegularUser', {
            ...item,
            displayRegularUser: true,
          })
          item['OriginalGame'] = item.game
          item['createByUser'] = item.user
          item['OriginalQuestion'] = item._id
          delete item['_id']
          await this.$store.dispatch('questionRepository/store', item)
        } else {
          await this.$store.dispatch('question/displayRegularUser', {
            ...item,
            displayRegularUser: false,
          })
          await this.$store.dispatch(
            'questionRepository/destroy/question',
            item._id
          )
        }
      } catch (e) {
        console.log(e)
      }
      disabled()
    },

    async addData({ perPage, page } = {}) {
      this.loading = true
      this.page = page || this.page
      this.perPage = perPage
      await this.$store.dispatch('question/index', {
        page: this.page,
        perPage: this.perPage,
        search: this.search,
        categories: this.searchByCategories,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      })
      this.loading = false
    },
    async loadData() {
      this.loading = true
      await this.$store.dispatch('category/index')
      await this.addData()
      this.loading = false
    },
    editItem(item) {
      this.current = item
      this.editDialog = true
    },
    async deleteItem(item, disabled) {
      let { isConfirmed } = await this.createSwal({
        icon: 'warning',
        title: `${this.$t('generics.delete')}? \n ${item.question.que}`,
      })
      if (isConfirmed) {
        await this.$store.dispatch('question/only/destroy', item._id)
        this.timeoutSwal({ icon: 'success', title: '' })
      }
      disabled()
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    this.loadData()
    this.debouncedAddData = debounce(this.addData, 1000)
  },
  watch: {
    selected() {
      this.$emit('onSelect', this.selected)
    },
    search() {
      this.debouncedAddData()
    },
    searchByCategories() {
      this.debouncedAddData()
    },
    sortBy() {
      this.addData()
    },
    sortDesc() {
      this.addData()
    },
  },
}
</script>
