import { render, staticRenderFns } from "./SurveySetting.vue?vue&type=template&id=fd4b389e&scoped=true"
import script from "./SurveySetting.vue?vue&type=script&lang=js"
export * from "./SurveySetting.vue?vue&type=script&lang=js"
import style0 from "./SurveySetting.vue?vue&type=style&index=0&id=fd4b389e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd4b389e",
  null
  
)

export default component.exports