import genericMixins from '@/mixins/generic'
import swalMixins from '@/mixins/swal'
import axios from 'axios'
export default {
  state: {
    questionRepositories: [],
    'questionRepositories/filtered': [],
    questionRepository: null,
  },
  getters: {
    questionRepositories: state => state.questionRepositories,
    questionRepository: state => state.questionRepository,
    'questionRepositories/filtered': state =>
      state['questionRepositories/filtered'],
  },
  mutations: {
    'questionRepositories/set': (state, payload) => {
      state.questionRepositories = payload
      state.filtered = [...state.questionRepositories]
    },
    'questionRepository/set': (state, payload) =>
      (state.questionRepository = payload),
    'questionRepositories/filter': (state, { key, val }) => {
      state['questionRepositories/filtered'] = !val
        ? [...state.questionRepositories]
        : state['questionRepositories'].filter(f => f[key] === val)
    },
    'questionRepositories/store': (state, payload) =>
      state.questionRepositories.push(payload),
    'questionRepositories/destroy': (state, id) =>
      (state.questionRepositories = state.questionRepositories.filter(item => {
        return item._id !== id
      })),
    'questionRepositories/update': (state, payload) => {
      state.questionRepositories = state.questionRepositories.map(item => {
        if (item._id === payload._id) {
          return payload
        }
        return item
      })
    },
  },
  actions: {
    'questionRepositories/index': async context => {
      try {
        const { data } = await axios.get('/questionRepository')
        context.commit('questionRepositories/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'questionRepository/show': async (context, id) => {
      try {
        let { data } = await axios.get('/questionRepository/' + id)
        context.commit('questionRepository/set', data)
      } catch (e) {
        console.log(e)
      }
    },
    'questionRepository/store': async (context, payload) => {
      try {
        let { data } = await axios.post('/questionRepository', payload)
        context.commit('questionRepositories/store', data)
      } catch (e) {
        console.log(e)
      }
    },
    'questionRepository/destroy': async (context, id) => {
      try {
        await axios.delete('/questionRepository/' + id)
        context.commit('questionRepositories/destroy', id)
      } catch (e) {
        console.log(e)
      }
    },
    'questionRepository/destroy/question': async (context, id) => {
      try {
        const repositoriesQuestion = context.getters.questionRepositories.find(
          q => q.OriginalQuestion === id
        )
        await axios.delete('/questionRepository/' + repositoriesQuestion._id)
        context.commit('questionRepositories/destroy', repositoriesQuestion._id)
      } catch (e) {
        console.log(e)
      }
    },
    'questionRepository/update': async (context, payload) => {
      try {
        let { data } = await axios.put(
          '/questionRepository/' + payload._id,
          payload
        )
        context.commit('questionRepositories/update', data)
      } catch (e) {
        console.log(e)
      }
    },
    //updates question labels by its id
    'questionRepository/update/category': async (context, payload) => {
      try {
        console.log(payload)
        const { data } = await axios.put(
          '/questionRepository/' + payload._id,
          payload
        )
        console.log(data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
