<template>
  <v-row class="mx-2 center-all">
    <v-col cols="12" class="center-all mt-2"> שילוב קטעי מדיה בשאלה </v-col>
    <v-col class="mx-6 center-all buttons-row">
      <div v-for="btn in buttons" :key="btn.text">
        <SelectUpload
          v-if="!parserData(form[btn.name]).src"
          :format="parserData(form[btn.name]).format"
          :src="parserData(form[btn.name]).src"
          :btnName="btn.name"
          @save="v => saveMedia(v, btn.name)"
          @openModel="openModel(btn.name)"
          :noYoutube="
            btn.name == 'backgroundMedia' &&
            ['trivia', 'survey'].includes(form.type)
          "
        >
          <template v-slot:button="{ attrs, on }">
            <BtnStyle
              v-if="!parserData(form[btn.name]).src"
              class="mx-2"
              :disabled="openMediaDialog"
              :borderColor="btn.borderColor"
              :btnText="btn.text"
              v-bind="attrs"
              v-on="on"
            />
          </template>
        </SelectUpload>
        <div v-else style="position: relative">
          <ViewVideo
            v-if="parserData(form[btn.name]).format === 'youtube'"
            :src="form[btn.name].src"
            class="mx-6 border-radius"
          />
          <ViewVideo
            class="mx-6 border-radius"
            v-else-if="parserData(form[btn.name]).format === 'mp4'"
            :src="createSrc(form.openMedia)"
          />
          <play-audio-cloudinary
            :publicId="form[btn.name].src"
            class="mx-6 border-radius"
            v-else-if="parserData(form[btn.name]).format === 'mp3'"
          />
          <v-card v-else class="mx-6 border-radius">
            <v-img
              class="border-radius"
              :src="`https://res.cloudinary.com/${cloudName}/image/upload/${
                form[btn.name].src
              }`"
              width="140px"
              height="79px"
              aspect-ratio="1"
            >
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <MyIconImage maxWidth="140" name="logo-anim" type="gif" />
                </v-row>
              </template>
            </v-img>
          </v-card>
          <div class="trash d-flex justify-center align-center">
            <v-btn fab x-small class="trash-btn" @click="removeMedia(btn.name)">
              <MyIconImage
                name="trash"
                :maxWidth="13"
                :moreStyle="{ zIndex: 10 }"
              />
            </v-btn>
          </div>
        </div>
      </div>
    </v-col>
    <UploadFileCloud
      v-if="openMediaDialog"
      v-model="openMediaDialog"
      @save="v => this.saveMedia(v, mediaName)"
      onlyImage
      onlyVideo
    />
  </v-row>
</template>
<script>
import SelectUpload from '@/components/buttons/SelectUpload.vue'
import MyIconImage from '@/components/MyIconImage.vue'
import BtnStyle from '../buttons/BtnStyle.vue'
import PlayAudioCloudinary from '../PlayAudioCloudinary.vue'
import UploadFileCloud from '../UploadFileCloud.vue'
import ViewVideo from '../ViewVideo.vue'

export default {
  name: 'QuestionUploadMedia',
  components: {
    MyIconImage,
    SelectUpload,
    PlayAudioCloudinary,
    ViewVideo,
    BtnStyle,
    UploadFileCloud,
  },
  props: { value: Object },
  data() {
    return {
      openMediaDialog: false,
      mediaName: '',
      buttons: [
        {
          name: 'openMedia',
          text: !this.mediaAndMovie
            ? this.$t('games.openMedia')
            : this.$t('games.media'),
          save: v => this.saveMedia(v, 'openMedia'),
          borderColor: 'pink',
        },
        {
          name: 'backgroundMedia',
          text: this.$t('games.backgroundMedia'),
          save: v => this.saveMedia(v, 'backgroundMedia'),
          borderColor: 'green',
        },
        {
          name: 'endMedia',
          text: this.$t('games.endMedia'),
          save: v => this.saveMedia(v, 'endMedia'),
          borderColor: 'yellow',
        },
      ],
    }
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    cloudName() {
      return this.$store.getters.cloudName
    },
  },
  methods: {
    saveMedia(value, name) {
      this.form[name] = { ...this.form[name], ...value }
      this.$emit('save')
    },
    openModel(name) {
      this.mediaName = name
      this.openMediaDialog = true
    },
    removeMedia(name) {
      for (const key in this.form[name]) {
        if (key !== '_id') {
          this.form[name][key] = ''
        }
      }
      this.$emit('save')
    },
    createError(e) {
      console.log(e)
    },
    parserData(data) {
      return {
        src: data?.src ?? '',
        format: data?.format ?? '',
      }
    },
    format(format) {
      return format.includes('mp3') || format.includes('mp4')
        ? 'video'
        : 'image'
    },
    createSrc(media) {
      return `https://res.cloudinary.com/${this.cloudName}/${this.format(
        media.format
      )}/upload/${media.src}`
    },
  },
}
</script>
<style lang="scss" scoped>
@media (max-width: 1150px) {
  .buttons-row ::v-deep .v-btn {
    padding: 0 5px !important;
  }
}
.open-media-btn {
  border: 3px solid var(--pink);
}
.background-media-btn {
  border: 3px solid var(--green);
}
.end-media-btn {
  border: 3px solid var(--yellow);
}
::v-deep .v-sheet.v-list {
  box-shadow: none;
  background: none;
}
::v-deep .v-menu__content {
  box-shadow: none;
  background: none;
}
.trash {
  position: absolute;
  width: 100%;
  bottom: -10px;
}
.trash-btn {
  width: 23px;
  height: 23px;
}
.border-radius,
::v-deep.border-radius {
  border-radius: 20px;
}
</style>
