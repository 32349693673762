import Cloudinary from '@/helpers/cloudinary'

export default {
  install(Vue, { uploadPreset = '', cloudName = '' }) {
    const cloud = new Cloudinary({ uploadPreset, cloudName })
    const formats = ['mp3', 'mp4', 'jpg', 'jpeg', 'png']

    Vue.prototype.$cloudUpload = ({
      file,
      folder,
      formatsAllowed = formats,
      errorHandler,
      ...payload
    }) => {
      return cloud.upload({
        file,
        folder,
        formatsAllowed,
        errorHandler,
        ...payload,
      })
    }
  },
}
