import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'
import he from 'vuetify/lib/locale/he'

Vue.use(Vuetify)

const vuetify = new Vuetify({
  rtl: true,
  lang: {
    locales: { he },
    current: 'he',
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
        lightGrey: '#b8b7b7',
        // new color from figma
        gray: '#D9D9D9',
        blueLogo: '#212B46',
        pink: '#ED4266',
        green: '#0DAC69',
        yellow: '#FFD140',
        azure: '#168AAF',
        blue: '#1877F2',
      },
    },
  },
})

/**
 *
 * @param {Object} breakpointList object containing breakpoint information, example: {xs: 20,sm: 30,md: 40,lg: 60,xl: 80}
 * @returns
 */
export const parameterSizeScreenBreakpointByKey = breakpointList => {
  let point = 0
  const breakpointName = vuetify.framework.breakpoint.name
  const optionsName = ['xs', 'sm', 'md', 'lg', 'xl', 'xl']
  switch (breakpointName) {
    case 'xs':
      point = breakpointList['xs']
      break
    case 'sm':
      point = breakpointList['sm']
      break
    case 'md':
      point = breakpointList['md']
      break
    case 'lg':
      point = breakpointList['lg']
      break
    case 'xl':
      point = breakpointList['xl']
      break
    default:
      point = breakpointList['xl']
      break
  }
  if (point) return point
  for (let index = 0; index < optionsName.length; index++) {
    const element = breakpointList[optionsName[index]]
    if (element) {
      point = element
      break
    }
  }
  return point
}

export default vuetify
