<template>
  <v-row justify="start">
    <div class="ml-2">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            @click="showSettingHandler = true"
            outlined
            color="green"
            v-bind="attrs"
            v-on="on"
          >
            <v-icon class="ml-1">mdi-cog-outline</v-icon>
            {{ $t('setting.setting') }}
          </v-btn>
        </template>
        <span>{{ $t('setting.setting') }}</span>
      </v-tooltip>
    </div>
    <div class="ml-2">
      <tooltip-btn
        @action="uploadFileOpen = true"
        :tooltipText="$t('setting.clickHereToExpotFromXl')"
        :btnText="$t('setting.importFromXl')"
        outlined
        color="green"
      />
    </div>
    <div class="ml-2">
      <tooltip-btn
        @action="demoGame"
        :tooltipText="
          $t('setting.clickHereTo', { prop: $t('setting.demoGame') })
        "
        outlined
        :btnText="$t('setting.demoGame')"
        color="green"
      />
    </div>
    <div class="ml-2">
      <tooltip-btn
        v-if="isAdmin || clickersExported"
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('setting.export')"
        textClass="white--text"
        color="green"
        @action="exportFile"
      />
      <tooltip-btn
        v-else-if="phonesExported"
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('generics.showLink')"
        textClass="white--text"
        color="green"
        @action="showLink = true"
      />
      <tooltip-btn
        v-else
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('generics.toPay')"
        textClass="white--text"
        color="green"
        @action="showOutToPay = true"
      />
    </div>
    <div>
      <tooltip-btn
        v-if="isAdmin"
        :tooltipText="$t('setting.clickHereTo', { prop: $t('setting.export') })"
        :btnText="$t('generics.showLink')"
        textClass="white--text"
        color="green"
        @action="showLink = true"
      />
    </div>
    <show-link v-if="showLink" v-model="showLink" :link="link" />
    <setting-handler v-if="showSettingHandler" v-model="showSettingHandler" />
    <upload-file
      v-if="uploadFileOpen"
      v-model="uploadFileOpen"
      @handleUpload="importFile"
    />
    <out-to-pay-handler v-if="showOutToPay" v-model="showOutToPay" />
    <download-handler
      v-if="showDownloadHandler"
      v-model="showDownloadHandler"
      :gameId="game._id"
    />
  </v-row>
</template>

<script>
import UploadFile from './UploadFile.vue'
import SettingHandler from './handler/SettingHandler.vue'
import TooltipBtn from './buttons/TooltipBtn.vue'
import SwalMixins from '@/mixins/swal'
import OutToPayHandler from './handler/OutToPayHandler/OutToPayHandler.vue'
import GenericMixins from '@/mixins/generic'
import ShowLink from './ShowLink.vue'
import DownloadHandler from './download/DownloadHandler.vue'

export default {
  components: {
    UploadFile,
    SettingHandler,
    TooltipBtn,
    OutToPayHandler,
    ShowLink,
    DownloadHandler,
  },
  name: 'Setting',
  mixins: [SwalMixins, GenericMixins],

  data() {
    return {
      showSettingHandler: false,
      showDownloadHandler: false,
      showOutToPay: false,
      showLink: false,
      uploadFileOpen: false,
    }
  },
  computed: {
    host() {
      return this.$store.getters.host
    },
    game() {
      return this.$store.getters.game
    },
    user() {
      return this.$store.getters.userDetails
    },
    isAdmin() {
      return this.$store.getters.isAdmin
    },
    link() {
      const sourceUrl = `https://game.clicker.co.il?`
      //TODO: nissim id for demo game
      const hostId = 1212
      const host = 'https://app.clicker.co.il'
      // if (this.clickersExported) {
      return (
        '' +
        sourceUrl +
        'userId=' +
        hostId +
        '&' +
        'game=' +
        host +
        '/game/web-export/' +
        this.game?._id
      )
      // }
      // return this.$store.getters.isAdmin;
    },
    exportApproved() {
      const payList = this.user.paymentList
      const result = payList.find(
        l =>
          l.gameId === this.game?._id && this.timeIsBefore(new Date(l.expireAt))
      )
      if (result) {
        return true
      }
      return false
    },
    phonesExported() {
      return this.exportApproved && this.game?.setting?.limit?.type === 'phones'
    },
    clickersExported() {
      return (
        this.exportApproved && this.game?.setting?.limit?.type === 'clickers'
      )
    },
  },
  methods: {
    async demoGame() {
      window.open(this.link + '&demo=true', '_blank')
    },
    async importFile(file) {
      await this.$store.dispatch('upload/xlsx', {
        file,
        gameId: this.game._id,
      })
      this.$emit('setIndex')
    },
    async exportFile() {
      this.showDownloadHandler = true
    },
  },
  async beforeMount() {
    if (this.$route.params.isNew) {
      await this.$store.dispatch('game/show', this.$route.params.id)
      this.showSettingHandler = true
    }
  },
}
</script>
