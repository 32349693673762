<template>
  <v-tooltip top>
    <template v-slot:activator="{ on }">
      <v-btn
        @click="action"
        v-on="on"
        v-bind="$attrs"
        icon
        dark
        :color="color"
        :large="size === 'large'"
        :small="size === 'small'"
        :disabled="disabled"
      >
        <img
          v-if="src"
          style="max-width: 20px"
          :src="`/assets/icons/${src}.png`"
        />
        <v-icon dark v-else> {{ icon }}</v-icon>
      </v-btn>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
</template>

<script>
export default {
  name: 'TooltipIcon',
  props: {
    color: { type: String, default: 'green' },
    tooltipText: { type: String, default: '' },
    src: { type: String, default: '' },
    icon: { type: String, default: '' },
    size: { type: String, default: 'small' },
    validator: { type: Boolean, default: false },
  },
  data() {
    return {
      disabled: false,
    }
  },
  methods: {
    action() {
      if (this.validator) {
        this.disabled = true
      }
      this.$emit('action', () => (this.disabled = false))
    },
  },
}
</script>
