import axios from 'axios'
import swalMixins from '../../mixins/swal'
import genericMixins from '../../mixins/generic'
const filterVideoOrAudio = (array, type) => {
  return array?.filter(r => r.type === 'video' && r.format === type) || []
}
export default {
  state: {
    imagesRepositories: [],
    videosRepositories: [],
    audiosRepositories: [],
    images: [],
    videos: [],
    audios: [],
    raws: [],
  },
  getters: {
    imagesRepositories: (state, getters) =>
      getters.repositoryAssets.filter(r => r.type === 'image') || [],
    videosRepositories: (state, getters) =>
      filterVideoOrAudio(getters.repositoryAssets, 'mp4') || [],
    audiosRepositories: (state, getters) =>
      filterVideoOrAudio(getters.repositoryAssets, 'mp3') || [],
    myImages: (state, getters) =>
      getters.userAssets?.filter(a => a.type === 'image') || [],
    myVideos: (state, getters) =>
      filterVideoOrAudio(getters.userAssets, 'mp4') || [],
    myAudios: (state, getters) =>
      filterVideoOrAudio(getters.userAssets, 'mp3') || [],
    images: (state, getters) => [
      ...getters.imagesRepositories,
      ...getters.myImages,
    ],
    videos: (state, getters) => [
      ...getters.videosRepositories,
      ...getters.myVideos,
    ],
    audios: (state, getters) => [
      ...getters.audiosRepositories,
      ...getters.myAudios,
    ],
    raws: state => state.raws,
    videosAndImages: (state, getters) => [...getters.videos, ...getters.images],
    videosAndImagesAndAudios: (state, getters) => [
      ...getters.videos,
      ...getters.images,
      ...getters.audios,
    ],
    imagesAndAudios: (state, getters) => [...getters.images, ...getters.audios],
    // cloudinaryById: (state) => (id) => state.cloudinarys.find((u) => u._id === id),
    // cloudinaryFiltered: (state) => state.filtered,
  },
  mutations: {
    //sets all cloudinarys
    'repositories/set': (state, payload) => {
      state.imagesRepositories = payload.images
      state.videosRepositories = payload.videos
      state.audiosRepositories = payload.audios
    },
    'images/set': (state, payload) => {
      state.images = payload
    },
    'videos/set': (state, payload) => {
      state.videos = payload
    },
    'audios/set': (state, payload) => {
      state.audios = payload
    },
    'raws/set': (state, payload) => {
      state.raw = payload
    },
    'images/store': (state, payload) => {
      state.images.push(payload)
    },
    'videos/store': (state, payload) => {
      state.videos.push(payload)
    },
    'audios/store': (state, payload) => {
      state.audios.push(payload)
    },
    'raws/store': (state, payload) => {
      state.raw.push(payload)
    },
  },
  actions: {
    //fetch all cloudinarys
    'cloudinary/repositories': async context => {
      try {
        const { data } = await axios.get(`/cloudinary/repositories`)
        context.commit('repositories/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch all cloudinarys
    'cloudinary/images': async context => {
      try {
        const { data } = await axios.get(`/cloudinary?type=image`)
        context.commit('images/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch all cloudinarys
    'cloudinary/videos': async context => {
      try {
        const { data } = await axios.get(`/cloudinary?type=video`)
        context.commit(
          'audios/set',
          data?.filter(item => item.format === 'mp3')
        )
        context.commit(
          'videos/set',
          data?.filter(item => item.format === 'mp4')
        )
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
    //fetch all cloudinarys
    'cloudinary/raw': async context => {
      try {
        const { data } = await axios.get(`/cloudinary?type=raw`)
        context.commit('raw/set', data)
      } catch (e) {
        swalMixins.methods.timeoutSwal({
          icon: 'error',
          title: genericMixins.methods.extractClapErrorHe(e),
        })
      }
    },
  },
}
