<template>
  <StyleDialog v-model="isOpen" :title="$t('setting.' + type)">
    <template slot="content">
      <AnsImageSetting v-if="type === 'ans_images'" v-model="form" />
      <MediaSetting v-if="type === 'media'" v-model="form" />
      <SurveySetting v-if="type === 'survey'" v-model="form" />
      <TextSetting v-if="type === 'text'" v-model="form" />
      <TriviaSetting v-if="type === 'trivia'" v-model="form" />
    </template>
  </StyleDialog>
</template>
<script>
import StyleDialog from '../StyleDialog.vue'
import AnsImageSetting from './AnsImageSetting.vue'
import MediaSetting from './MediaSetting.vue'
import SurveySetting from './SurveySetting.vue'
import TextSetting from './TextSetting.vue'
import TriviaSetting from './TriviaSetting.vue'

export default {
  name: 'QuestionSetting',
  components: {
    StyleDialog,
    AnsImageSetting,
    MediaSetting,
    SurveySetting,
    TextSetting,
    TriviaSetting,
  },
  props: {
    value: Object,
    openSettings: Boolean,
    invalid: Boolean,
    type: String,
    isNew: Boolean,
  },
  computed: {
    form: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    isOpen: {
      get() {
        return this.openSettings
      },
      set() {
        if (!this.invalid || this.isNew) {
          this.$emit('onClose')
        }
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.my-btn {
  border: 2px solid;
  background-color: var(--blueLogo) !important;
}
::v-deep .cancel-btn {
  border-color: var(--pink) !important;
}
::v-deep .save-btn {
  border-color: var(--green) !important;
}
</style>
