<template>
  <StyleDialog
    v-model="openModal"
    :title="$t('setting.gameResults')"
    :maxWidth="700"
    max-height="700"
  >
    <template slot="content">
      <v-container class="text-center">
        <v-data-table :items="gameResults" :headers="headers" v-if="!loading">
          <template v-slot:item.actions="{ item }">
            <v-icon color="success" small @click="downloadXls(item)">
              mdi-download
            </v-icon>
          </template>
        </v-data-table>
        <v-progress-circular
          v-else
          style="margin: 20% auto"
          :size="70"
          color="primary"
          indeterminate
        />
      </v-container>
    </template>
    <template slot="actions"> </template>
  </StyleDialog>
</template>
<script>
import StyleDialog from '../StyleDialog.vue'
import ExportExcel from '@/helpers/downloadXls.js'
import GenericMixins from '@/mixins/generic'

export default {
  name: 'GameResults',
  components: { StyleDialog },
  props: { value: Boolean },
  mixins: [GenericMixins],

  data() {
    return {
      loading: false,
      headers: [
        { text: this.$t('games.name'), value: 'gameId.name' },
        { text: this.$t('generics.createdAt'), value: 'createdAt' },
        {
          text: this.$t('generics.action'),
          value: 'actions',
          sortable: false,
          align: 'center',
          width: '10%',
        },
      ],
    }
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    gameResults() {
      return this.$store.getters.backupsByGameId.map(b => ({
        ...b,
        createdAt: this.formatTime(b.createdAt),
      }))
    },
    game() {
      return this.$store.getters.game
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.$store.dispatch('backups/show-by-gameId', this.game._id)
      this.loading = false
    },
    async downloadXls(item) {
      const ee = new ExportExcel(item, this.game.name)
      await ee.createXlsGameResult()
    },
  },
  async created() {
    await this.loadData()
  },
}
</script>
<style lang="scss" scoped></style>
