export const typesArray = [
  {
    text: 'שאלה',
    type: 'trivia',
    icon: 'mdi-head-question-outline',
  },
  {
    text: 'סקר',
    type: 'survey',
  },
  // {
  //   text: 'דיון',
  //   type: 'discussion',
  //   // description:
  //   //   'שאלת דיון: כאן ניתן להזין טקסט  או תמונה לדיון. המשתתפים שמעונינים להביע דעתם בע"פ יוכלו להצביע והמנחה יוכל להזמינם לומר את דעתם. במסך זה אין צבירת נקודות. (אין אפשרות של תשובות, ואפשרות של מדיה אחת)',
  //   // icon: "mdi-frequently-asked-questions",
  // },
  // {
  //   text: "מסך מעבר",
  //   type: "subject",
  //   description:
  //     "מסך מעבר: כאן ניתן לכתוב טקסט (כגון, כותרת נושא, הערות וכדו') או להעלות מדיה (שלא קשורה לשאלות) בין השאלות. (אין אפשרות של תשובות, ואפשרות של מדיה אחת)",
  //   icon: "mdi-transfer",
  // },
  // {
  //   text: "תמונה גדולה",
  //   type: "big_image",
  //   description: "",
  //   icon: "mdi-image",
  // },
  {
    text: 'תשובות כתמונה',
    type: 'ans_images',
  },
  {
    text: 'טקסט',
    type: 'subject',
  },
  {
    text: 'מדיה',
    type: 'media',
  },
]
export const findType = type => typesArray.find(t => t.type === type)
