<template>
  <span
    :style="style"
    class="pointer mouse-pointer"
    v-on="$listeners"
    v-bind="$attrs"
  ></span>
</template>
<script>
export default {
  name: 'CustomCheckbox',
  props: {
    value: { type: [Boolean, Number, String], default: false },
    isCircle: { type: Boolean, default: true },
    width: { type: Number, default: 15 },
    height: { type: Number, default: 15 },
    backgroundColor: { type: String, default: 'var(--pink)' },
    boxShadow: { type: Boolean, default: false },
    moreStyle: { type: Object, default: () => ({}) },
  },
  computed: {
    val: {
      get() {
        return this.value
      },
      set(v) {
        this.$emit('input', v)
      },
    },
    style() {
      return {
        display: 'flex',
        width: this.width + 'px',
        height: this.height + 'px',
        border: '1px solid #dededede',
        borderRadius: this.isCircle ? '100px' : '2px',
        boxShadow: this.boxShadow
          ? `0px 0px 0px ${this.isCircle ? '3px' : '2px'} #fff inset`
          : '',
        backgroundColor: this.value ? this.backgroundColor : '#fff',
        ...this.moreStyle,
      }
    },
  },
}
</script>
