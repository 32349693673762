<template>
  <v-dialog
    v-model="openModal"
    max-width="1100"
    :fullscreen="fullscreen"
    persistent
  >
    <v-card v-show="!isLoading">
      <v-app-bar flat color="white">
        <v-toolbar flat max-height="100">
          <v-card-title class="primary--text" v-text="title" />
          <v-spacer />
          <slot name="toolbar" />
          <v-spacer />
          <v-btn icon @click="openModal = false" color="primary">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-app-bar>
      <v-sheet
        class="overflow-y-auto ma-auto"
        :max-height="maxHeight"
        :min-height="minHeight"
      >
        <v-container :fill-height="fillHeight" fluid>
          <slot name="content" />
        </v-container>
      </v-sheet>
      <v-card-actions class="d-flex justify-center">
        <slot name="actions" />
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  name: 'MyDialog',
  data: () => ({
    loading: false,
  }),
  props: {
    isLoading: { type: Boolean, default: false },
    fullscreen: { type: Boolean, default: false },
    value: Boolean,
    title: String,
    maxHeight: { type: Number, default: 600 },
    minHeight: { type: Number, default: 500 },
    fillHeight: { type: Boolean, default: true },
  },
  computed: {
    openModal: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      },
    },
  },
}
</script>
