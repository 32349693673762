<template>
  <!-- <div class="home"> -->
  <v-container class="d-fle justify-center align-center" fill-height fluid>
    <v-row class="justify-center">
      <v-col cols="12">
        <MyIconImage type="svg" name="eyes" maxWidth="140" />
      </v-col>
      <v-col cols="11" md="6">
        <v-card class="my-card pb-10">
          <form @keyup.enter="submit" v-if="!loading">
            <v-row justify="center">
              <v-col md="4" cols="4" class="d-flex justify-end align-center">
                <BtnStyle
                  :disabled="!registerMod"
                  @click="toggleRegister"
                  iconName=""
                  btnText="generics.login"
                  borderColor="green"
                  backgroundColor="blueLogo"
                  color="white"
                  borderSize="4px"
                  :moreStyles="btnBaseStyle"
                />
              </v-col>
              <v-col md="4" cols="4" class="d-flex justify-start align-center">
                <BtnStyle
                  :disabled="registerMod"
                  @click="toggleRegister"
                  iconName=""
                  btnText="generics.register"
                  borderColor="yellow"
                  backgroundColor="blueLogo"
                  color="white"
                  borderSize="4px"
                  :moreStyles="btnBaseStyle"
                />
              </v-col>
            </v-row>
            <v-row justify="center" v-if="registerMod">
              <v-col md="8" cols="10">
                <v-text-field
                  v-model="fullname"
                  label="שם מלא"
                  :rules="[rules.nameRequired, rules.nameLength]"
                  required
                  solo
                />
              </v-col>
            </v-row>
            <v-row justify="center" v-if="registerMod">
              <v-col md="8" cols="10">
                <v-text-field
                  v-model="phone"
                  label="פלאפון"
                  :rules="[rules.phoneRequired, rules.validPhone]"
                  required
                  solo
                />
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col md="8" cols="10">
                <v-text-field
                  v-model="email"
                  label="כתובת אימייל"
                  :rules="[rules.emailRequired, rules.validEmail]"
                  required
                  solo
                />
              </v-col>
            </v-row>

            <v-row justify="center">
              <v-col md="8" cols="10">
                <v-text-field
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  label="סיסמה"
                  :type="show ? 'text' : 'password'"
                  :rules="[rules.passRequired, rules.min]"
                  @click:append="show = !show"
                  minLength="6"
                  required
                  solo
                  hide-details="auto"
                />
              </v-col>
            </v-row>
            <v-row justify="center" no-gutters>
              <v-col md="8" cols="10">
                <v-btn
                  plain
                  :to="{ name: 'RestorePassword', query: { email } }"
                  text
                >
                  <a> שכחת את הסיסמה? </a>
                </v-btn>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-col class="d-flex justify-center" md="8" cols="10">
                <v-btn
                  @click="submit"
                  color="pink"
                  class="rounded-xl login-btn"
                  block
                  :disabled="isValidForm"
                  v-text="registerMod ? 'הרשמה' : 'התחברות'"
                />
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col
                @click="googleLogin"
                class="d-flex d-flex justify-center align-center"
              >
                <google-btn />
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <MyIconImage
                  name="remoteControl"
                  type="svg"
                  maxWidth="400"
                  style="
                    position: absolute;
                    right: -25%;
                    top: 20%;
                    pointer-events: none;
                  "
                />
              </v-col>
            </v-row>
          </form>
          <div class="progress" v-else>
            <v-progress-circular
              :size="120"
              :width="7"
              color="primary"
              indeterminate
            />
          </div>
          <v-alert
            v-if="isAlertMessage"
            outlined
            type="error"
            class="d-flex justify-center mt-5"
            >{{ error }}
          </v-alert>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
  <!-- </div> -->
</template>

<script>
import GenericMixins from '@/mixins/generic'
import SwalMixins from '@/mixins/swal'
import GoogleBtn from '@/components/buttons/GoogleBtn.vue'
import MyIconImage from '@/components/MyIconImage.vue'
import BtnStyle from '@/components/buttons/BtnStyle.vue'
export default {
  components: { GoogleBtn, MyIconImage, BtnStyle },
  name: 'Login',
  mixins: [GenericMixins, SwalMixins],
  data: () => {
    return {
      btnBaseStyle: {
        'font-size': '2vh',
        'max-width': '200px',
        height: '50px',
        'justify-content': 'center !important',
      },
      customCondition: { width: '400px' },
      show: false,
      isAlertMessage: false,
      loading: false,
      registerMod: false,
      //form
      email: '',
      password: '',
      phone: '',
      fullname: '',
    }
  },
  computed: {
    isValidForm() {
      return !(
        (this.registerMod ? this.fullname.length >= 2 : true) &&
        (this.registerMod ? this.phoneValidation(this.phone) : true) &&
        this.email &&
        this.emailValidation(this.email) &&
        this.password &&
        this.password.length >= 6
      )
    },
    rules() {
      return {
        nameRequired: v => !!v || 'נא להזין שם מלא',
        nameLength: v => v.length >= 2 || 'לפחות 2 אותיות',
        emailRequired: v => !!v || 'נא להזין אימייל',
        phoneRequired: v => !!v || 'נא להזין פלאפון',
        validEmail: v => this.emailValidation(v) || 'אימייל לא תקין',
        validPhone: v => this.phoneValidation(v) || 'פלאפון לא תקין',
        passRequired: v => !!v || 'נא להזין סיסמה',
        min: v => v.length >= 6 || '6 תווים לפחות',
      }
    },
  },
  methods: {
    async submit() {
      this.loading = true
      try {
        if (!this.registerMod) {
          await this.$store.dispatch('auth/login', {
            email: this.email,
            password: this.password,
          })
          if (this.$store.getters.adminLoggedIn)
            this.$router.push({ name: 'AdminDashboard' })
          else this.$router.push({ name: 'UserDashboard' })
        } else {
          await this.$store.dispatch('auth/register', {
            email: this.email,
            phone: this.phone,
            password: this.password,
            fullname: this.fullname,
          })
          await this.$router.push({ name: 'UserDashboard' })
          this.timeoutSwal({
            title: this.$t('auth.registerSuccess'),
            icon: 'success',
            timer: 2500,
          })
          this.registerMod = false
        }
      } catch (e) {
        console.log('No auth')
      }
      this.loading = false
    },
    async googleLogin() {
      window.location.href = '/auth/google'
    },
    toggleRegister() {
      this.registerMod = !this.registerMod
    },
  },
  mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
  },
}
</script>
<style lang="scss" scoped>
.progress {
  margin: auto;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}
::v-deep .v-sheet.v-card.my-card {
  border-radius: 40px !important;
  position: relative;
}
::v-deep .v-btn.v-btn--disabled {
  color: aliceblue;
}
button.v-btn[disabled] {
  opacity: 0.7;
  color: var(--gray) !important;
  background-color: aqua;
}
button.login-btn.v-btn[disabled] {
  background-color: var(--pink) !important;
  color: var(--blueLogo) !important;
}
.v-text-field {
  border-radius: 40px;
}
</style>
