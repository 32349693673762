<template>
  <div>
    <!-- <v-container> -->
    <v-card max-width="1200" class="container mt-16">
      <v-card-title class="d-flex align-center">
        <v-row>
          <v-col cols="11">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              icon
              :label="$t('generics.search')"
              single-line
              hide-details
            ></v-text-field
          ></v-col>
          <v-col cols="1" class="center-all">
            <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          </v-col>
        </v-row>
      </v-card-title>
      <v-overlay :value="loading" color="transparent">
        <v-progress-circular
          color="primary"
          indeterminate
          size="64"
        ></v-progress-circular>
      </v-overlay>
      <v-data-table
        :headers="headers"
        :items="games"
        :loading="loading"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :footer-props="{ 'items-per-page-options': [25, 50, 100, 200, 250] }"
        :server-items-length="gameCount"
        @update:page="page => addGameData({ page })"
        @update:items-per-page="perPage => addGameData({ perPage })"
        @update:sort-by="sortBy = $event"
        @update:sort-desc="sortDesc = $event"
      >
        <template v-slot:item.createdAt="{ item }">
          {{ formatTime(item.createdAt) }}
        </template>
        <template v-slot:item.user="{ item }">
          {{ getEmailUser(item.user) }}
        </template>
        <template v-slot:item.room="{ item }">
          <v-text-field
            :value="item.room"
            @change="(v, e) => saveRoom(v, e, item)"
            type="number"
            min="1011"
            max="5000"
            :rules="[val => (+val  >= minRoomNumber && +val <= maxRoomNumber  || +val === 0 ) || `0 מוחק את החדר. הטווח של החדרים צריך להיות בין ${minRoomNumber} ל${maxRoomNumber}.`],"
          >
          </v-text-field>
        </template>
        <template v-slot:item.actions="{ item }">
          <tooltip-icon
            :tooltipText="$t('games.duplicateGameTooltip')"
            :icon="'mdi-content-duplicate'"
            :fab="true"
            @action="duplicate(item)"
          />
          <tooltip-icon
            :tooltipText="$t('games.editGameTooltip')"
            :icon="'mdi-pencil'"
            :fab="true"
            @action="editItem(item)"
          />
          <tooltip-icon
            :tooltipText="$t('games.deleteGameTooltip')"
            :icon="'mdi-delete'"
            :fab="true"
            color="error"
            @action="deleteItem(item)"
          />
          <tooltip-icon
            :tooltipText="$t('games.transfer')"
            :icon="'mdi-swap-horizontal'"
            :fab="true"
            @action="transferGameOpen = item._id"
          />
        </template>
      </v-data-table>
    </v-card>
    <!-- </v-container> -->
    <select-style v-model="openModel" v-if="openModel" :gameId="gameId" />
    <transfer-game v-model="transferGameOpen" v-if="transferGameOpen" />
  </div>
</template>

<script>
import { debounce } from 'lodash'
import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import SelectStyle from '@/components/handler/SelectStyle.vue'
import TransferGame from '@/components/dialogs/TransferGame.vue'
export default {
  components: { TooltipIcon, SelectStyle, TransferGame },
  name: 'gamesTable',
  mixins: [SwalMixins, GenericMixins],

  data() {
    return {
      transferGameOpen: false,
      page: 1,
      perPage: 25,
      sortBy: 'createdAt',
      openModel: false,
      gameId: null,
      sortDesc: true,
      loading: false,
      headers: [
        { text: this.$t('auth.email'), value: 'user', sortable: true },
        { text: this.$t('games.name'), value: 'name', sortable: true },
        { text: this.$t('ID'), value: '_id', sortable: false },
        {
          text: this.$t('questions.sum'),
          value: 'questions.length',
          sortable: false,
        },
        {
          text: this.$t('generics.createdAt'),
          value: 'createdAt',
          sortable: true,
        },
        { text: this.$t('generics.room'), value: 'room' },
        {
          text: this.$t('generics.action'),
          value: 'actions',
          width: '20%',
          sortable: false,
        }, // Disable sorting for this header
      ],
      chosen: null,
      search: '',
      addDialog: false,
      editDialog: false,
      maxRoomNumber: 5000,
      minRoomNumber: 1011,
    }
  },
  computed: {
    gameCount() {
      return this.$store.getters.gameCount
    },
    games() {
      return this.$store.getters.games
    },
    users() {
      return this.$store.getters.users
    },
  },
  methods: {
    async addGameData({ perPage, page } = {}) {
      this.loading = true
      this.page = page || this.page
      this.perPage = perPage
      await this.$store.dispatch('game/index', {
        page: this.page,
        perPage: this.perPage,
        search: this.search,
        sortBy: this.sortBy,
        sortDesc: this.sortDesc,
      })
      this.loading = false
    },
    async saveRoom(v, e, item) {
      if (+v >= this.minRoomNumber && +v <= this.maxRoomNumber) {
        let { isConfirmed } = await this.createSwal({
          icon: 'warning',
          title: `לשנות את החדר?`,
        })
        if (isConfirmed) {
          item['setting']
            ? (item.setting['limit'] = { type: 'phones' })
            : (item['setting'] = { limit: { type: 'phones' } })
          await this.$store.dispatch('game/update', {
            ...item,
            room: v,
          })
        }
      }
      if (+v === 0) {
        let { isConfirmed } = await this.createSwal({
          icon: 'warning',
          title: `למחוק את החדר?`,
        })
        if (isConfirmed) {
          item.setting.limit = null
          await this.$store.dispatch('game/update', {
            ...item,
            room: null,
          })
        }
      }
    },
    getEmailUser(id) {
      const user = this.$store.getters.userById(id)
      return user?.email && user.email
    },
    async loadData() {
      this.loading = true
      await this.addGameData()
      await this.$store.dispatch('user/index')
      this.loading = false
    },
    editItem(item) {
      this.gameId = item._id
      this.openModel = true
    },
    async duplicate(game) {
      await this.$store.dispatch('game/replicate', game._id)
    },
    async deleteItem(item) {
      let { isConfirmed } = await this.createSwal({
        icon: 'warning',
        title: `${this.$t('games.delete')}?`,
      })
      if (isConfirmed) {
        await this.$store.dispatch('game/destroy', item._id)
      }
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    await this.loadData()
    this.debouncedAddData = debounce(this.addGameData, 1000)
  },
  watch: {
    search() {
      this.debouncedAddData()
    },
    sortBy() {
      this.addGameData()
    },
    sortDesc() {
      this.addGameData()
    },
  },
}
</script>
