<template>
  <v-card max-width="1200" class="container mt-16">
    <v-card-title class="d-flex align-center">
      <v-row>
        <v-col cols="11">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            icon
            :label="$t('generics.search')"
            single-line
            hide-details
          ></v-text-field
        ></v-col>
        <v-col cols="1">
          <v-icon color="primary" @click="loadData" v-text="'mdi-refresh'" />
          <v-icon color="primary" @click="createGame" v-text="'mdi-plus'" />
        </v-col>
      </v-row>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="games"
      :search="search"
      :loading="loading"
      :items-per-page="10"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:item.createdAt="{ item }">
        {{ formatTime(item.createdAt) }}
      </template>

      <template v-slot:item.actions="{ item }">
        <!-- <v-list-item-action> -->
        <tooltip-icon
          v-if="!item.room"
          :tooltipText="$t('games.duplicateGameTooltip')"
          :icon="'mdi-content-duplicate'"
          :fab="true"
          @action="duplicate(item)"
        />
        <tooltip-icon
          :tooltipText="$t('games.editGameTooltip')"
          :icon="'mdi-pencil'"
          :fab="true"
          @action="editItem(item)"
        />
        <!-- <v-icon small @click="deleteItem(item)">mdi-delete</v-icon> -->
      </template>
    </v-data-table>
    <select-style v-model="openModel" v-if="openModel" :gameId="gameId" />
  </v-card>
</template>

<script>
import SwalMixins from '@/mixins/swal'
import GenericMixins from '@/mixins/generic'
import TooltipIcon from '@/components/buttons/TooltipIcon.vue'
import SelectStyle from '@/components/handler/SelectStyle.vue'
export default {
  components: { TooltipIcon, SelectStyle },
  name: 'gamesTable',
  mixins: [SwalMixins, GenericMixins],
  data() {
    return {
      sortBy: 'createdAt',
      openModel: false,
      gameId: null,
      sortDesc: true,
      loading: false,
      headers: [
        { text: this.$t('games.name'), value: 'name' },
        { text: this.$t('questions.sum'), value: 'questions.length' },
        { text: this.$t('generics.createdAt'), value: 'createdAt' },
        { text: this.$t('generics.action'), value: 'actions', width: '10%' },
      ],
      chosen: null,
      search: '',
    }
  },
  computed: {
    games() {
      return this.$store.getters.games
    },
    isAdmin() {
      return this.$store.getters.adminLoggedIn
    },
  },
  methods: {
    async loadData() {
      this.loading = true
      await this.$store.dispatch('game/showMany')
      this.loading = false
    },
    editItem(item) {
      this.gameId = item._id
      if (this.isAdmin) {
        this.openModel = true
      } else {
        this.$router.push({ name: 'NewStyle', params: { id: item._id } })
      }
    },
    async duplicate(game) {
      await this.$store.dispatch('game/replicate', game._id)
    },
    // async deleteItem(item) {
    //   let { isConfirmed } = await this.createSwal({
    //     icon: "warning",
    //     title: `${this.$t("games.delete")}?`,
    //   });
    //   if (isConfirmed) {
    //     await this.$store.dispatch("game/destroy", item._id);
    //   }
    // },
    async createGame() {
      try {
        const { isConfirmed, value } = await this.createInputSwaldata({
          title: this.$t('games.name'),
        })
        if (!isConfirmed) return
        await this.$store.dispatch('game/store', { name: value })
        this.$router.push({
          name: 'NewStyle',
          params: { isNew: true, id: this.$store.getters.game._id },
        })
      } catch (e) {
        console.log(e)
      }
    },
  },
  async mounted() {
    this.$store.commit('title/set', this.$t(`route.${this.$route.name}`))
    await this.loadData()
  },
}
</script>
