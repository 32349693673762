<template>
  <div class="dialog-card">
    <h1 class="blueLogo--text mt-12" style="font-size: 40px">
      בחרו את התוכנית שמתאימה לכם
    </h1>
    <span class="pink--text" style="font-size: 18px"
      >איזה כיף שבחרתם בחויה בקליק לתוכנית שלכם!</span
    >
    <div>
      <div class="d-flex mt-8" style="gap: 25px">
        <div
          v-for="card in paymentCards.slice(0, 4)"
          :key="card.number"
          class="white rounded-lg d-flex flex-column justify-end align-items-center elevation-5 rounded-xl"
          style="height: 150px; width: 110px; cursor: pointer"
          @click="card.click()"
        >
          <div class="mb-n2">
            <my-icon-image maxWidth="90" :name="card.image" />
          </div>
          <div
            style="height: 90px; line-height: 1"
            class="blueLogo rounded-xl d-flex flex-column justify-center align-center hover-white-card"
          >
            <span
              class="yellow--text"
              style="font-size: 12px; font-weight: bold"
              >{{ card.title }}</span
            >
            <span
              class="pink--text bold"
              style="font-size: 25px; font-weight: bold"
              >{{ card.number }}</span
            ><span
              class="yellow--text"
              style="font-size: 12px; font-weight: bold"
              >{{ card.text }}</span
            >
          </div>
        </div>
      </div>
      <div class="d-flex mt-8 justify-center" style="gap: 25px">
        <div
          v-for="card in paymentCards.slice(4, 7)"
          :key="card.number"
          class="white rounded-lg d-flex flex-column justify-end align-items-center elevation-5 rounded-xl"
          style="height: 150px; width: 110px; cursor: pointer"
          @click="card.click()"
        >
          <div :class="card.image === 'questionMark' ? 'mb-1' : 'mb-n2'">
            <my-icon-image
              :maxWidth="card.image === 'questionMark' ? '30' : '90'"
              :name="card.image"
            />
          </div>
          <div
            style="height: 90px; line-height: 1; text-align: center"
            class="blueLogo rounded-xl d-flex flex-column justify-center align-center hover-white-card"
          >
            <span
              class="yellow--text"
              style="font-size: 12px; font-weight: bold"
              >{{ card.title }}</span
            >
            <span
              class="pink--text bold"
              style="font-size: 25px; font-weight: bold"
              :style="card.number === 'מותאם  אישית' ? 'font-size: 20px' : ''"
              >{{ card.number }}</span
            ><span
              class="yellow--text"
              style="font-size: 12px; font-weight: bold; white-space: pre-line"
              >{{ card.text }}</span
            >
          </div>
        </div>
      </div>
    </div>
    <a href="https://clicker.co.il/cellphones/#MECHIR" target="_blank">
      <v-btn
        color="yellow"
        class="blueLogo--text rounded-md custom-rounded-btn"
      >
        למחירון המלא
      </v-btn></a
    >
  </div>
</template>

<script>
import MyIconImage from '../../MyIconImage.vue'

export default {
  name: 'CardPaymentComponent',
  components: {
    MyIconImage,
  },
  props: {
    paymentCards: Array,
  },
}
</script>

<style scoped>
.hover-white-card {
  transition: all 0.1s ease-in-out;
}

.hover-white-card:hover {
  background-color: white !important;
}
.dialog-card {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.custom-rounded-btn {
  width: 300px;
  height: 50px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: bold;
  margin-top: 50px;
}
.hover-white-card {
  transition: all 0.1s ease-in-out;
}

.hover-white-card:hover {
  background-color: white !important;
}
</style>
