<template>
  <div>
    <div
      class="d-flex align-center"
      style="position: absolute; left: 20%"
      v-if="!fromImportQuestionsPage && isAdmin"
    >
      {{ $t('route.AllQuestions') }}
      <v-switch v-model="isRepositoryMode" class="mx-3" />
      {{ $t('route.QuestionRepository') }}
    </div>
    <question-repository-table
      v-if="isRepositoryMode || fromImportQuestionsPage"
      :fromImportQuestionsPage="fromImportQuestionsPage"
      @onSelect="s => $emit('onSelect', s)"
    />
    <all-questions-table v-else-if="isAdmin" />
  </div>
</template>

<script>
import AllQuestionsTable from '@/components/tables/AllQuestionsTable.vue'
import QuestionRepositoryTable from '@/components/tables/QuestionRepositoryTable.vue'

export default {
  components: {
    AllQuestionsTable,
    QuestionRepositoryTable,
  },
  name: 'AllQuestions',
  props: {
    fromImportQuestionsPage: { type: Boolean, default: false },
  },
  data() {
    return {
      isRepositoryMode: true,
    }
  },
  computed: {
    isAdmin() {
      return this.$store.getters.isAdmin
    },
  },
}
</script>
