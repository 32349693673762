const mediaQuestion = {
  type: 'media',
  setting: {
    type: 'media',
    showInLoop: false,
    fullscreen: false,
    skipAfterClick: false,
    automaticSkip: {
      active: false,
      seconds: '',
    },
  },
  openMedia: {
    src: '',
    format: '',
  },
  question: {
    que: '',
    answers: [],
  },
}
export default mediaQuestion
